import React from 'react';
import styles from './Teachers.module.scss'
import { useReferralLink } from '../../../Hooks/useReferralLink'
import LazyLoad from 'react-lazyload';

const Teachers = () => {
    return (
        <LazyLoad height={400}>
            <section className={styles.teachersContainer}>
                <div className={styles.text}>
                    <h2>With Escribli, teachers will:</h2>
                    <a href={useReferralLink()}>Start free trial</a>
                </div>
                <div className={styles.cardContainer}>
                    <div className={`${styles.card}  ${styles.goDown} `}>
                        <div className={styles.cardBody}>
                            <h3>Develop Better Communicators</h3>
                            <p>Foster effective communication, a life skill that can be applied in every aspect of students’ lives.</p>
                        </div>
                    </div>
                    <div className={`${styles.card} ${styles.purple}`}>
                        <div className={styles.cardBody}>
                            <h3>Create Lifelong Learners </h3>
                            <p>Spark students’ imaginations while creating a genuine love of learning.</p>
                        </div>
                    </div>
                    <div className={`${styles.card} ${styles.pink}`}>
                        <div className={styles.cardBody}>
                            <h3>Build Citizenship</h3>
                            <p>Establish respect and appreciation for the importance of being a contributing member of a community.</p>
                        </div>
                    </div>
                    <div className={`${styles.card} ${styles.goUp} ${styles.green}`}>
                        <div className={styles.cardBody}>
                            <h3>Establish Work Ethic</h3>
                            <p>Instill values based on the ideals of discipline, hard work, and the desire to do the best job possible.</p>
                        </div>
                    </div>
                </div>
            </section>
        </LazyLoad>
    )
}

export default Teachers;