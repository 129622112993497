import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { getFromApi } from '../../API/generalCalls';
import { setStageRedux, setBookDetailsRedux, setSummaryDetailsRedux, setUserDetailsRedux } from '../../Redux/Actions';

import styles from './Bookstore.module.scss';
import Checkout from './Sections/Checkout/Checkout';
import Main from './Sections/Main/Main';
import Spreadshirt from './Sections/Spreadshirt/Spreadshirt';


const Bookstore = () => {

  const location = useLocation()
  const parsed = queryString.parse(location.search);
  const match = useRouteMatch()
  const history = useHistory()
  const code = parsed.code
  const [ showLoader, setShowLoader ] = useState(false)
  const [ showError, setShowError ] = useState(false)
  const stage = useSelector(state => state.storeData.stage);
  const bookDetails = useSelector(state => state.storeData.bookDetails);
  const userDetails = useSelector(state => state.storeData.userDetails);
  const dispatch = useDispatch();

  const getBookDetails = async (bookCode) =>{
    setShowLoader(true)
    dispatch(setBookDetailsRedux( await getFromApi(`store/search_activity?code=${bookCode}`)))
  } 

  const setStage = (num) => {
    dispatch(setStageRedux(num))
  }

  const setBookDetails = (obj) => {
    dispatch(setBookDetailsRedux(obj))
  }

  const setSummaryDetails = (obj) => {
    dispatch(setSummaryDetailsRedux(obj))
  }

  const setUserDetails = (obj) => {
    dispatch(setUserDetailsRedux(obj))
  }

  useEffect(()=>{
  

    if(code){ // call API if code included in URL
      setShowLoader(true)
      getBookDetails(code)
    }

  },[])

  useEffect(()=>{ // act upon receipt of API response 

    if(bookDetails?.bookName&&stage===0){ // success
      setTimeout(()=>{
        setStage(1)
        history.push(`/store/checkout`)
        setShowLoader(false)
      },500)

    } else if(bookDetails.message){ // error
      setShowLoader(false)
      setShowError(true)
    }

  },[bookDetails])


  return (
    <div className={styles.container}>

      <Switch>

        <Route path={`${match.path}/products`} 
            component={() => 
              <Spreadshirt /> 
            } 
          /> 

        <Route path={`${match.path}/checkout`} 
            component={() => 
              <Checkout
                setSummaryDetails={setSummaryDetails}
                setStage={setStage}
                showLoader={showLoader}
                bookDetails={bookDetails}
                code={ code }/> 
            } 
          /> 


          <Route path={[`${match.path}`]}
            component={() =>
              <Main
                setUserDetails={setUserDetails}
                setSummaryDetails={setSummaryDetails}
                setStage={setStage}
                setBookDetails={setBookDetails}
                showError={showError}
                showLoader={showLoader}
                getBookDetails={getBookDetails}
                code={code} />
              
              } 
          />

        
      </Switch>

    </div>
  )

}

export default Bookstore;