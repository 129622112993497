import React from 'react';
import { ButtonNext, ButtonBack } from 'pure-react-carousel';
import styles from './CarouselBtn.module.scss'
import leftArrow from '../../../Assets/Icons/leftArrow.svg'
import leftBlueArrow from '../../../Assets/Icons/leftBlueArrow.svg'
import rightArrow from '../../../Assets/Icons/rightArrow.svg'

const CarouselBtn = ({ next, back, white }) => {

    return (
        next ? <ButtonNext className={`${styles.carouselBtn} ${styles.next}`} id={'reasonNext'}><img src={rightArrow} alt='right Arrow' /></ButtonNext> : white ? <ButtonBack className={`${styles.carouselBtn} ${styles.next}`} ><img src={leftBlueArrow} alt='left Arrow' /></ButtonBack> : <ButtonBack className={`${styles.carouselBtn} ${styles.back}`} ><img src={leftArrow} alt='left Arrow' /></ButtonBack>
    )
}

export default CarouselBtn;