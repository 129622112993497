import React from 'react';
import styles from './WritingEngagement.module.scss'
import engagement from './../../../../Assets/Parents/engagement.png'
import engagementMobile from './../../../../Assets/Parents/engagementMobile.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useWindowSize } from '../../../../Hooks/UseWindowSize';
import { Link } from 'react-router-dom';

const Engagement = () => {

  const size = useWindowSize();

  return (
    <article className={styles.container}>
      <div className={styles.textContainer}>
        <h2>Boomers increase your child’s engagement</h2>
        <p>Your child will be engaged in writing with our Boomers! The more your child writes, the more Boomer Bucks they earn and the more customizable their Boomer can become.</p>
        <Link to="/auth/parent/signup" className={styles.blueBtn}>Sign up for our free Family app</Link>

      </div>
      <LazyLoadImage className={styles.engagementImage} src={size.width >= 1095 ? engagement : engagementMobile} alt='decoration image' effect="blur" />
    </article>
  );
}

export default Engagement;