import { createStore, applyMiddleware, compose } from 'redux';
// import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
 
import rootReducer from './Reducers/rootReducer';
 
// const persistConfig = {
//   key: 'newstatic',
//   storage,
 
// }

const middleware = [thunk]

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware),    
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
);

// export  let persistor = persistStore(store)