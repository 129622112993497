import React, { useState } from 'react';
import styles from './FeatureCard.module.scss'
import Tooltip from '../../../Structural/Tooltip/Tooltip';

const FeatureCard = ({ icon, text, tooltipText }) => {
    const [show, setShow] = useState(false);

    return (
        <div className={styles.featureCard} onMouseEnter={() => { setShow(true) }} onMouseLeave={() => { setShow(false) }}>
            <div className={styles.cardBody}>

                <div>
                    <img src={icon} alt='' />
                </div>
                <p>
                    {text}
                </p>
            </div>

            <Tooltip title={text} tooltipText={tooltipText} show={show} />
        </div>
    );
}

export default FeatureCard;