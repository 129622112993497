import React, { useState } from 'react';
import HeroPng from '../../../Assets/HomePage/heroPng.png'
import LogoHero from '../../../Assets/HomePage/logoHero.png'
import styles from './Hero.module.scss'
import { useReferralLink } from '../../../Hooks/useReferralLink'
import ReactGA from 'react-ga';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import logo from '../../../Assets/Icons/whiteLogo.svg'

const Hero = () => {

    const [ payload, setPayload ] = useState({email:'', firstName:'', lastName:''})
    const { email, firstName, lastName } = payload;

    return (
        <section className={styles.hero}>
            <div className={styles.heroTitle}>
                {/* <h1>Escribli</h1> */}
                <img className={styles.logo} src={logo} alt="logo" />
                <h2>Online writing tools for the classroom</h2>
                

                <div className={styles.inputsDiv}>
                    <input onChange={(e)=> setPayload(({...payload, firstName:e.target.value}))} placeholder="First Name" type="text" name="name"/>
                    <input onChange={(e)=> setPayload(({...payload, lastName:e.target.value}))} placeholder="Last Name" type="text" name="lastName"/>
                    <input onChange={(e)=> setPayload(({...payload, email:e.target.value}))} placeholder="Email" type="email" name="email"/>
                    <Link to={`/auth/teacher/signup?${email&&`email=${email}`}${firstName&&`&firstName=${firstName}`}${lastName&&`&lastName=${lastName}`}`}> <span onClick={() => {ReactGA.event({ category: 'Hero', action: 'Click on sign up hero btn' })}}>Start free trial!</span></Link>
                </div>
            </div>


        </section>
    );
}

export default Hero;