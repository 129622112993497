import React from 'react';
import Person from './Person'
import styles from './About.module.scss';

//Photo Imports


import MariaPhoto from '../../Assets/AboutUs/Maria.png'
import HaynesPhoto from '../../Assets/AboutUs/Ken.png'
import SergiuPhoto from '../../Assets/AboutUs/Sergiu.png'
import DanielPhoto from '../../Assets/AboutUs/Daniel.png'
import RichardPhoto from '../../Assets/AboutUs/Richard.png'
import SarahPhoto from '../../Assets/AboutUs/Sarah.png'

import {Helmet} from 'react-helmet'

const About = () => {
    return (
        <section >

            <Helmet>
                <title>About Us | Escribli Writing Software for the Classroom </title>
                <meta name="description" content="Our Escribli team is committed to delivering software that improves student writing and learning while making it easier for teachers and parents." />
                <meta name="keywords" content="Escribli company, Escribli about us, about Escribli, Escribli in the news" />
            </Helmet>

            <div className={styles.blueBoardBg}>
                <div className={styles.titleContainer}>
                    <h1 className={styles.textWhite}>Meet our team</h1>
                    <p>Escribli consists of a small and dedicated team committed to supporting teachers' efforts to deliver engaging and impactful learning experiences to their students both within and beyond the classroom.</p>
                </div>
            </div>
            <div className={styles.whiteWave}>

                <div className={styles.fContainer}>
                    <Person name='Loli López-Montenegro' position='CEO' imgUrl={MariaPhoto} />
                    <Person name='Ken Haynes' position='Co-founder/Director of Education' imgUrl={HaynesPhoto} />
                    <Person name='Sarah von Helfenstein' position='CFO' imgUrl={SarahPhoto} />
                    <Person name='Richard Allington' position='Education Consultant' imgUrl={RichardPhoto} />
                    <Person name='Daniel Rodrigues' position='UI/UX Graphic Designer' imgUrl={DanielPhoto} />
                    <Person name='Sergiu Truta' position='CTO' imgUrl={SergiuPhoto} />
                </div>

            </div>

            <div className={styles.lastWave}>
                {/* <h2>
                    Our board members
                </h2>
                <div className={styles.fContainer}>
                    <Person name='Miguel de Mora' position='Front End Web Developer' imgUrl={MalePhoto} blue lnUrl="https://www.linkedin.com/in/mdemora/" />
                    <Person name='David Sainz' imgUrl={MalePhoto} blue />
                    <Person name='Candance Fletcher' imgUrl={FemalePhoto} blue />
                </div> */}
            </div>

        </section >
    );
}

export default About;