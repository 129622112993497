import React from 'react';
import styles from './TrustCard.module.scss'
import twitter from './../../../../Assets/Icons/twitter.svg'

const TrustCard = ({ text, name, position, img, link }) => {
    return (
        <div className={styles.trustCard}>
            <div className={styles.padding}>
                <a href={link} className={styles.quote}>
                    <div className={styles.twWrapper}>
                        <img src={twitter} alt='twitter logo' />
                    </div>
                </a>
                <p className={styles.bodyText}>
                    {text}
                </p>
                <div className={styles.personalInfo}>
                    <a href={link} className={styles.imgWrapper}>
                        <img src={img} alt='twitter pic' />
                    </a>
                    <div>
                        <h4>{name}</h4>
                        <p>{position}</p>
                        {/* <p>{location}</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrustCard;