import React from 'react';
import { Link } from 'react-router-dom';
import { redirectTeacherApp } from '../../../Helpers/redirectFuncs';
import { useReferralLink } from '../../../Hooks/useReferralLink';

import styles from './BookstoreLoginBtns.module.scss';

const BookstoreLoginBtns = ({ loggedIn, userDetails }) => {

  const referralLink = useReferralLink()


  return (
    <div className={styles.container}>
      {
        loggedIn ?

          <>
            <p>Welcome {userDetails.fullName}</p>
            <div onClick={()=> redirectTeacherApp('classes')  } className={styles.blueBtn}>Go to app</div>
          </>

        :
          <>
            <Link to={'/auth/teacher?app=store'}> <p className={styles.logBtn}>Log in</p> </Link>
            <a href={referralLink} className={styles.blueBtn}>Sign Up</a>
          </>

      }

    </div>
  )

}

export default BookstoreLoginBtns;