
import axios from 'axios';
import ls from 'local-storage'

const host = window.location.host 
const env = host === 'localhost:3011' ? 'dev' : host === 'sand.escribli.com' ? 'stage' : 'prod';

const url = (env, query) => {
  if(env === 'dev'){
    return `http://localhost:3007/api/${query}`
  } else if(env === 'stage') {
    return `https://sandapi.escribli.com/api/${query}` 
  } else if(env === 'prod') {
    return `https://backapi.escribli.com/api/${query}` 
  }
}

export const getFromApi = async (query, action, object, func) => {

  const token = ls('t');
  console.log(url(env, query))
  console.log({headers: { 'Authorization': token || '' } })
  
  try {
    
    const { data } = await axios.get( url(env, query), {headers: { 'Authorization': token || '' } } )
    console.log(data)
    return data;
    
  } catch (error) {
    console.log(error)
  }
}



export const sendToApi = (query, newData, action, details) =>{

  const token = ls('t');
  console.log(url(env, query))
  console.log(newData)
  
  axios.post(url(env, query), newData, {headers: { 'Authorization': token || '' }})

  .then(response => {
    
    console.log(response)

    if(action==='sponsor'||action==='getPrintPiId'){
      details(response.data)
    }

    })
    .catch((error) => {
      console.log(error)
    })
    
}


export const sendToApiReturn = (query, newData, action, details) =>{

  const token = localStorage.getItem('t');
  console.log(url(env, query))
  console.log(newData)

  axios.post(url(env, query), newData, {headers: { 'Authorization': token || '' }})
    
  

  .then(response => {
    
    console.log(response)
    console.log(response.data)
    return response.data

    })
    .catch((error) => {
      console.log(error)
    })
}

