import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import beelogo from './../../../Assets/BeeBanner/beelogo.png'
import JeffKinney from './../../../Assets/HomePage/kinney.png'
import craft from './../../../Assets/HomePage/craft.png'
import writingBeeLogo from './../../../Assets/WritingBee/thewritingbeelogo.png'


import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from "./WritingBeeOnHome.module.scss";
import { Link } from 'react-router-dom';

const WritingBeeOnHome = () => {
    return (
        <div className={styles.writingBeeContainer}>

            <div className={styles.beeLogo}>
                <LazyLoadImage alt='The writing bee' src={beelogo} effect='blur' />
            </div>
            <div className={styles.mobileBeeLogo}>
                <LazyLoadImage alt='The writing bee' src={writingBeeLogo} effect='blur' />
            </div>

            <h1 className={styles.text}>The global writing event that starts with your class!</h1>
            <div className={styles.map} />
            <div className={`${styles.bigImg} ${styles.left}`}>
                <LazyLoadImage alt='Jeff Kinney' src={JeffKinney} effect="blur" />
            </div>
            <div className={`${styles.bigImg} ${styles.right}`}>
                <LazyLoadImage alt='Jerry Craft' src={craft} effect="blur" />
            </div>

            <Link to='/thewritingbee' className={styles.scrollbtn}>
                Learn more
            </Link>

        </div>
    );
}

export default WritingBeeOnHome;