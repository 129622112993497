import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const IntercomScript = () => {
  // let INTERCOM_APP_ID = undefined

  let hosts = {
    localhost: "localhost",
    testing: "sand",
    production: "escribli"
  }
  const location = useLocation()


  useEffect(() => {
    if (location.pathname.includes('schools')) {

      if (window.location.host.includes(hosts.localhost || hosts.testing)) {
        var APP_ID = 'crfx5713';
        (function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = 'https://widget.intercom.io/widget/' + APP_ID; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
            }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); }
          }
        })();
        window.Intercom('boot', {
          app_id: APP_ID
        });
      } else if (window.location.host.includes(hosts.production)) {
        var APP_IDD = 'mhqigo24';
        (function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = 'https://widget.intercom.io/widget/' + APP_IDD; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
            }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); }
          }
        })();
        window.Intercom('boot', {
          app_id: APP_IDD
        });
      }
    } else {
      let menu = document.querySelector('.intercom-lightweight-app');
      if(menu)menu.style.display='none'
    }
    
  }, [hosts.localhost, hosts.production, hosts.testing, location]);






  return (
    <></>
  )
}

export default IntercomScript;


