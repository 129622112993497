import React, { useEffect, useRef, useState } from 'react';
import { CountryDropdown, RegionDropdown  } from 'react-country-region-selector';
import { useDispatch, useSelector } from 'react-redux';
import { checkParams } from '../../../../Helpers/inputFunctions';
import { addAnimation } from '../../../../Helpers/uiFunctions';
import { setUserDetailsRedux } from '../../../../Redux/Actions';

import styles from './checkoutStyles.module.scss';
import OrderDetailBlock from './OrderDetailBlock';
import ShippingOptionsBlock from './ShippingOptionsBlock';

const CheckoutStepTwo = ({ setSummaryDetails, setStage, isOpenClassOrder }) => {

  const [ showError, setShowError ] = useState({vis:false, content:''})
  const detailsRef = useRef();
  const addressRef = useRef();
  const shippingRef = useRef();
  const countryRef = useRef();
  const summaryDetails = useSelector(state => state.storeData.summaryDetails)
  const userDetails = useSelector(state => state.storeData.userDetails)
  const dispatch = useDispatch()
  
  useEffect(()=>{
    // pre-populate address fields if user is logged in and has address stored
    if(userDetails&&!summaryDetails.address.address_1){
      const reduxAddress = {
        address_1:userDetails.address_1 ,
        address_2:userDetails.address_2 ,
        city:userDetails.city ,
        country_name:userDetails.countryName,
        state_name:userDetails.stateName,
        zip:userDetails.zip ,
        firstName:userDetails.firstName,
        lastName:userDetails.lastName ,
        email:userDetails.email ,
      }
      setSummaryDetails({...summaryDetails, address:reduxAddress, storedAddress:true})
    }
  },[])

  const checkCountry = (val) => {
    if(val!=='United States'){
      console.log('Not USA')
      setTimeout(()=>{
        setSummaryDetails({...summaryDetails, address:{...summaryDetails.address, state_name:null}})
      },500)
    }
  }

  const redBorder = (action) => { //give empty input fields a red border
    const inputs = Array.from(document.querySelectorAll("[data-handle='required']"));
    inputs.forEach(i => {
      if(i.value===''||action==='remove'){
        i.classList[action]('errorBorder');
      }
    })
  }

  const checkFunction = () => {
    //check different mandatory fields depending on class order or not
        if(isOpenClassOrder){

          return checkParams({ 
            1: summaryDetails.address.firstName,
            2: summaryDetails.address.lastName,
            3: summaryDetails.address.email,
          })
          
        } else {

          let res = true
          res = checkParams({ 
              1: summaryDetails.address.address_1,
              2: summaryDetails.address.city,
              3: summaryDetails.address.country_name,
              4: summaryDetails.address.zip,
              5: summaryDetails.address.firstName,
              6: summaryDetails.address.lastName,
              7: summaryDetails.address.email,
            })  
          
          if(summaryDetails.address.country_name === 'United States' && !summaryDetails.address.state_name) res = false

          return res
        }

  }
  

  const handleSubmit = () => {


    if (!checkFunction()) {

      setShowError({vis:true, content:'Please complete all fields' });
      addAnimation(detailsRef, 'shakeSlow', 500)
      {!isOpenClassOrder && addAnimation(addressRef, 'shakeSlow', 500)}
      redBorder('add')

      
    } else if (!summaryDetails.shippingOption&&!isOpenClassOrder){ // check if shipping is selected
    
      setShowError({vis:true, content:'Please select a shipping option'});
      addAnimation(shippingRef, 'shakeSlow', 500)

    } else { 


       dispatch(setUserDetailsRedux({
          ...userDetails,
          email:summaryDetails.address.email,
          firstName:summaryDetails.address.firstName,
          lastName:summaryDetails.address.lastName
        })
       )

       setSummaryDetails({
         ...summaryDetails,
         address:{
           telephone:summaryDetails.address.telephone,
           address_1:summaryDetails.address.address_1,
           address_2:summaryDetails.address.address_2,
           city:summaryDetails.address.city,
           zip:summaryDetails.address.zip,
           country_name:summaryDetails.address.country_name,
           state_name:summaryDetails.address.state_name
         }
       })

      setStage(3)
      
    }
  }

  return (
    <div className={styles.checkoutStepTwo}>

      <div className={styles.topBlocks}>
          
        <OrderDetailBlock 
          setSummaryDetails={setSummaryDetails}
          summaryDetails={summaryDetails} 
          setStage={setStage} />


      </div>


      <div className={styles.shippingDetails}>
       {!isOpenClassOrder && <h3>Shipping Information:</h3>}
        <div ref={detailsRef} className={styles.yourDetails}>
          <h5>Your details:</h5>

            <input 
              data-handle='required'
              defaultValue={summaryDetails.address.firstName}
              placeholder={"First Name"} 
              type="text" 
              name="firstName" 
              onChange={(e)=>{setShowError({...showError, vis:false}); redBorder('remove'); setSummaryDetails({...summaryDetails, address:{...summaryDetails.address, firstName:e.target.value}})}}
            />

            <input 
              data-handle='required'
              defaultValue={summaryDetails.address.lastName}
              placeholder={"Last Name"} 
              type="text" 
              name="lastName"
              onChange={(e)=>{setShowError({...showError, vis:false}); redBorder('remove'); setSummaryDetails({...summaryDetails, address:{...summaryDetails.address, lastName:e.target.value}})}}
            />

          <input 
            data-handle='required'
            defaultValue={summaryDetails.address.email}
            placeholder={"Email"} 
            type="email" 
            name="email"
            onChange={(e)=>{setShowError({...showError, vis:false}); redBorder('remove'); setSummaryDetails({...summaryDetails, address:{...summaryDetails.address, email:e.target.value}})}}
            />

          <input 
            defaultValue={summaryDetails.address.telephone}
            placeholder={"Telephone (optional)"} 
            type="tel" 
            name="email"
            onChange={(e)=>{setShowError({...showError, vis:false}); redBorder('remove'); setSummaryDetails({...summaryDetails, address:{...summaryDetails.address, telephone:e.target.value}})}}
          />

        </div>
        {!isOpenClassOrder &&
          <div ref={addressRef} className={styles.addressDetails}>
            <h5>Address:</h5>

            <input 
              data-handle='required'
              defaultValue={summaryDetails.address.address_1}
              placeholder={"Street Address 1"} 
              type="text" 
              name="address_1"
              onChange={(e)=>{setShowError({...showError, vis:false}); redBorder('remove'); setSummaryDetails({...summaryDetails, address:{...summaryDetails.address, address_1:e.target.value}})}}
            />

            <input 
              defaultValue={summaryDetails.address.address_2}
              placeholder={"Street Address 2 (optional)"} 
              type="text" 
              name="address_2"
              onChange={(e)=>{setShowError({...showError, vis:false}); redBorder('remove'); setSummaryDetails({...summaryDetails, address:{...summaryDetails.address, address_2:e.target.value}})}}
            />

            <input 
              data-handle='required'
              defaultValue={summaryDetails.address.city}
              placeholder={"City"} 
              type="text" 
              name="city"
              onChange={(e)=>{setShowError({...showError, vis:false}); redBorder('remove'); setSummaryDetails({...summaryDetails, address:{...summaryDetails.address, city:e.target.value}})}}
            />

            <input 
              data-handle='required'
              defaultValue={summaryDetails.address.zip}
              placeholder={"Zip code"} 
              type="text" 
              name="zip"
              onChange={(e)=>{setShowError({...showError, vis:false}); redBorder('remove'); setSummaryDetails({...summaryDetails, address:{...summaryDetails.address, zip:e.target.value}})}}
            />
                
            <CountryDropdown
              ref={countryRef}
              data-handle='required'
              value={summaryDetails.address.country_name}
              onChange={(val) => {
                setShowError({...showError, vis:false}); 
                redBorder('remove');
                // checkCountry(val)
                setSummaryDetails({...summaryDetails, address:{...summaryDetails.address, country_name:val}});
              }} 
            />

              {
                summaryDetails.address.country_name === 'United States' &&
                <>
                  <RegionDropdown
                    data-handle='required'
                    value={summaryDetails.address.state_name}
                    defaultOptionLabel={"Select state"}
                    country={summaryDetails.address.country_name}
                    onChange={(val) => {
                      console.log(val)
                      setShowError({...showError, vis:false}); 
                      redBorder('remove');
                      setSummaryDetails({...summaryDetails, address:{...summaryDetails.address, state_name:val}});
                    }}
                  />
                </>
              }

          </div>
        }

        { summaryDetails.address.country_name && 
          
          <ShippingOptionsBlock 
            shippingRef={shippingRef}
            showError={showError}
            setShowError={setShowError}
            setSummaryDetails={setSummaryDetails}
            summaryDetails={summaryDetails}
            countryName={summaryDetails.address.country_name} /> 

        }
      </div>
      <div onClick={handleSubmit} className={styles.nextBtn}>Next: Payment 
        {showError.vis && <div className={styles.error}>{showError.content}</div>} 
      </div>

    </div>
  )

}

export default CheckoutStepTwo;