import React from 'react';
import styles from './Pricing.module.scss';
import PricingCard from './PricingCard';

const cardData = [
  {
    type:"Small class",
    allowance: "Up to 5 students",
    monthly:"0",
    yearly: null,
    benefits:["unlimited time"],
    ctaText:"Start for free",
    ctaAction:"signup_teacher"
  },
  {
    type:"Standard",
    allowance: "More than 5 students",
    monthly:"1",
    yearly: "",
    benefits:[
      "Unlimited students",
      "Personalized onboarding support",
      "Professional development, training and ongoing support",
    ],
    ctaText:"Get started",
    ctaAction:"contact"
  }
]

const Pricing = () => {

  return (
    <div className={styles.container}>

      <h1>Ready to start with Escribli?</h1>

      <div className={`${styles.cards}`}>
        { cardData.map((i,idx) => 
          <PricingCard cardData={i} key={idx} blue={idx===1} />
        )}
      </div>

    </div>
  )

}

export default Pricing;