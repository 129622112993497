import React, { useState } from 'react';
import styles from './Pieces.module.scss'
import PieceMobileCard from './Cards/PieceMobileCard'

import flexPic from '../../../Assets/HomePage/flexible.png'
import collabPic from '../../../Assets/HomePage/collaborative.png'
import multiPic from '../../../Assets/HomePage/multi.png'
import communPic from '../../../Assets/HomePage/communicative.png'
import {useReferralLink} from '../../../Hooks/useReferralLink'
import LazyLoad from 'react-lazyload';

const Pieces = ({ mobile }) => {
    const [term, setTerm] = useState('Flexible')
    const url = useReferralLink()
    const selectPic = () => {
        switch (term) {
            case 'Multidisciplinary': return multiPic
            case 'Flexible': return flexPic
            case 'Collaborative': return collabPic
            case 'Communicative': return communPic
            default:
                break;
        }

    }

    if (!mobile) {
        return (
        <LazyLoad height={500}>

            <section className={styles.piecesContainer}>
                <div className={styles.adjustAnimation}>
                    <div>
                        <div className={term === 'Multidisciplinary' ? `${styles.piece} ${styles.active}` : styles.piece} onMouseOver={() => setTerm('Multidisciplinary')}>
                            <h3>Multidisciplinary</h3>
                            <p>Easily integrates with all curriculum and can be customized into any type of assignment.</p>
                        </div>

                        <div className={term === 'Flexible' ? `${styles.piece} ${styles.active}` : styles.piece} onMouseOver={() => setTerm('Flexible')} >
                            <h3>Flexible</h3>
                            <p>Differentiate instruction easily with groups of any size, age, grade, or skill level. Our tools are accessible through phones, tablets, laptops, or other devices.</p>
                        </div>

                        <div className={term === 'Collaborative' ? `${styles.piece} ${styles.active}` : styles.piece} onMouseOver={() => setTerm('Collaborative')}>
                            <h3>Interactive</h3>
                            <p>Students model writing and learn from one another by engaging in side-by-side writing in our breakout writing rooms.</p>
                        </div>

                        <div className={term === 'Communicative' ? `${styles.piece} ${styles.active}` : styles.piece} onMouseOver={() => setTerm('Communicative')}>
                            <h3>Responsive</h3>
                            <p>Give students personalized feedback through multiple channels, including a teacher-student chat and revision support for each writing activity.</p>
                        </div>

                    </div>
                </div>
                <div className={styles.text}>

                    <h2>The missing piece in your class</h2>
                    <div>
                        <p>With Escribli, students and teachers continue to work together as if in the classroom!</p>
                        <a href={url} style={{width:"550px"}}>Start free trial</a>
                    </div>

                    <div className={styles.imgWrapper}>
                        <img src={selectPic()} alt='' />
                    </div>
                    <div className={styles.test}></div>
                </div>
            </section>
        </LazyLoad>

        );

    } else {
        return (
        <LazyLoad height={1000}>

            <section className={styles.piecesMobileContainer}>
                <div className={styles.text}>
                    <h2>The missing piece in your class</h2>
                    <p>With Escribli, students and teachers continue to work together as if in the classroom!</p>
                    <a href={url}>Start free trial</a>
                </div>

                <PieceMobileCard term='Multidisciplinary' img={multiPic} stateTerm={term} setTerm={setTerm} text='Easily integrates with all curriculum and can be customized into any type of assignment.' />
                <PieceMobileCard term='Flexible' img={flexPic} stateTerm={term} setTerm={setTerm} selectPic={selectPic} text='Differentiate instruction easily with groups of any size, age, grade, or skill level. Our tools are accessible through phones, tablets, laptops, or other devices.' />
                <PieceMobileCard term='Interactive' img={collabPic} stateTerm={term} setTerm={setTerm} selectPic={selectPic} text='Students model writing and learn from one another by engaging in side-by-side writing in our breakout writing rooms.' />
                <PieceMobileCard term='Responsive' img={communPic} stateTerm={term} setTerm={setTerm} selectPic={selectPic} text='Give students personalized feedback through multiple channels, including a teacher-student chat and revision support for each writing activity.' />


            </section>
        </LazyLoad>

        )
    }
}

export default Pieces;