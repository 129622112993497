import React from 'react';
import styles from './Trust.module.scss'
import TrustCard from './Cards/TrustCard';
import { Slide, Slider, CarouselProvider } from 'pure-react-carousel';
import CarouselBtn from '../../Structural/CarouselBtn/CarouselBtn';
import tw1 from './../../../Assets/HomePage/tw1.png'
import tw2 from './../../../Assets/HomePage/tw2.png'
import tw3 from './../../../Assets/HomePage/tw3.png'
import { useReferralLink } from '../../../Hooks/useReferralLink'
import LazyLoad from 'react-lazyload';

const Trust = ({ mobile }) => {
    let url = useReferralLink()
    if (!mobile) {
        return (
            <LazyLoad height={100}>
                <section className={styles.trustContainer}>
                    <div className={styles.text}>
                        <h2>Check out why teachers everywhere love using Escribli!</h2>
                        {/* <p>The process repeats each week until the end of the month when the story is completed and ready to be published!!</p> */}
                        <a href={url}>Start free trial</a>
                    </div>
                    <div className={styles.carousel}>
                        <CarouselProvider
                            naturalSlideWidth={580}
                            naturalSlideHeight={480}
                            totalSlides={3}
                            infinite
                        >
                            <div className={styles.sliderButtons}>
                                {/* @todo replace_BW_tweets */}
                                <Slider className={styles.slider}>
                                    <Slide index={0}><TrustCard name='@CblaurenTitus' img={tw1} position='Special Education Teacher' text='Trying out a new resource for ss!! @BoomWriter_ They are loving it! Ss login, respond to my prompt, and I provide feedback in no time! Students earn badges and "boomer bucks" to customize their avatars! We are even going to start creating a classroom book! @CBKutzElem #CBDLP' link='https://twitter.com/CblaurenTitus/status/1243250652907810818' /></Slide>
                                    <Slide index={1}><TrustCard name='@nbutler912' img={tw2} position='Google Certified Educator' text='@BoomWriter_ I used your creative writing (6th Grade) tool during Covid-19 for distance learning. I cannot express how much we enjoyed creating our story about "The Secret Cave Drawings". This tool is amazing and I recommend it for all subject areas. Thank you! @GroveCityMS' link='https://twitter.com/nbutler912/status/1266016730767376385' /></Slide>
                                    <Slide index={2}><TrustCard name='@Kmcalhany232' img={tw3} position='1st grade teacher' text='When writing gets mundane, bring it to life with @BoomWriter_!! This is a great #techtool to engage, promote creativity and work on those writing and reading skills We can’t wait to read and vote for the winning chapter @ecwestwildcats' link='https://twitter.com/Kmcalhany23/status/1214679829448216577' /></Slide>
                                </Slider>

                                <div className={styles.buttons}>
                                    <CarouselBtn last />
                                    <CarouselBtn next />
                                </div>
                            </div>
                        </CarouselProvider>
                    </div>
                </section>
            </LazyLoad>
        );
    } else {
        return (
            <LazyLoad height={100}>
                <section className={styles.trustContainer}>
                    <div className={styles.text}>
                        <h2>Check out why teachers everywhere love using Boomwriter!</h2>
                        <p>The process repeats each week until the end of the month when the story is completed and ready to be published!!</p>
                    </div>
                    <div className={styles.carouselContainer}>
                        <TrustCard name='@CblaurenTitus' img={tw1} position='Special Education Teacher' text='Trying out a new resource for ss!! @BoomWriter_ They are loving it! Ss login, respond to my prompt, and I provide feedback in no time! Students earn badges and "boomer bucks" to customize their avatars! We are even going to start creating a classroom book! @CBKutzElem #CBDLP' />
                    </div>
                </section>
            </LazyLoad>
        )
    }
}

export default Trust;