import React, { useState } from 'react';
import styles from './Journals.module.scss'
import storyBookBoomer from '../../../Assets/Boomers/journal-illustration.png'
import bookBrown from '../../../Assets/Boomers/bookBrown.png'
import journals from '../../../Assets/Boomers/journals.png'
import { Slide, Slider, CarouselProvider } from 'pure-react-carousel';
import CarouselBtn from '../../Structural/CarouselBtn/CarouselBtn';
import Journal1 from './../../../Assets/Tools/Journals1.png'
import Journal2 from './../../../Assets/Tools/Journals2.png'
import {useReferralLink} from './../../../Hooks/useReferralLink'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Journals = () => {
    const [slide, setSlide] = useState(true)
    return (
        <article className={styles.journalsContainer}>
            <div className={styles.groupWriting}>
                <h2>The place where students can write consistently about anything and everything!</h2>
                <div className={styles.imgWrapper}>
                    <LazyLoadImage src={storyBookBoomer} alt='boomer' effect="blur"/>
                </div>
                <a  href={useReferralLink()}> Start free trial</a>
            </div>

            <div className={styles.printJournals}>
                <p>Showcase your journals!</p>
                <div className={styles.imgWrapper}>
                    <LazyLoadImage src={bookBrown} alt='brown Book' effect="blur"/>
                </div>
                <p>Journals can be printed</p>
            </div>

            <div className={styles.flexibleTool}>
                <h3>The perfect tool to develop students’ brain muscle through consistent writing throughout the year.</h3>
                <div className={styles.listCarousel}>
                    <div className={styles.list}>
                        <ul>
                            <li>Great for <strong>Social Emotional Learning, Reading Response</strong> entries, anything!</li>
                            <li>Teachers can <strong>monitor</strong> students’ writing progress in <strong>real-time</strong></li>
                            <li>Activities can have <strong>multiple entries</strong> that can be <strong>pre-scheduled</strong></li>
                            <li>Students can work Independently or collaboratively side-by-side (virtually!)</li>
                            <li>Each student's journal can be published as a real paperback book!</li>
                        </ul>
                        <a  href={useReferralLink()}>Start free trial</a>
                    </div>
                    <div className={styles.carouselProvider}>
                        <CarouselProvider
                            naturalSlideWidth={720}
                            naturalSlideHeight={470}
                            totalSlides={2}
                            infinite
                            dragEnabled={false}
                            touchEnabled={false}
                        >
                            <div className={styles.centerCarousel}>

                                <Slider className={styles.slider}>
                                    <Slide index={0}>
                                        <LazyLoadImage src={Journal1} alt='' effect="blur"/>
                                    </Slide>

                                    <Slide index={1}>
                                        <LazyLoadImage src={Journal2} alt='' effect="blur"/>
                                    </Slide>


                                </Slider>

                                <div className={styles.buttons}>

                                    <div onClick={() => setSlide(!slide)}>
                                        <CarouselBtn last white />
                                    </div>
                                    {slide ? <p>Student’s view</p> : <p>Teachers’ view</p>}
                                    <div onClick={() => setSlide(!slide)}>
                                        <CarouselBtn next />
                                    </div>
                                </div>
                            </div>


                        </CarouselProvider>
                    </div>
                </div>
            </div>

            <div className={styles.teacherCase}>
                <div className={styles.imgWrapper}>
                    <LazyLoadImage src={journals} alt='brown Book' effect="blur"/>
                </div>
                <div className={styles.text}>
                    <h3>Teacher case study</h3>
                    <p>But don’t just take our word for it. Read the case study written by a real teacher and learn how the Escribli Journal transformed her group of students into a community of writers!</p>
                    {/* @todo remove_static_link */}
                    <a href='https://static.boomwriter.com/resources/casestudy-journals' target="_blank" rel="noopener noreferrer">Escribli Case Study</a>
                </div>
            </div>
        </article>
    );
}

export default Journals;