import React from 'react';
import { Link } from 'react-router-dom';

import styles from './PricingCard.module.scss';

const PricingCard = ({ blue, cardData }) => {

  const { type, allowance, monthly, yearly, benefits, ctaText, ctaAction } = cardData

  return (
    <div className={`${styles.container} ${blue&&styles.blue}`}>
      <h4>{type}</h4>
      <h6>{allowance}</h6>
      {
        monthly &&
          <div className={`${styles.priceDiv}`}>
            <h3>{`$${monthly}`}</h3><span>/Month {type === 'Standard' && 'per Student'}</span>
            {yearly &&
              <>
                <h5>{`$${yearly}`}</h5>
                <span>/Yearly</span>
              </>
            }
          </div>
      }
        
        <div className={`${styles.benefitsList}`}>
          <p className={`${styles.listItem}`}>Access to all functionality</p>
          <p className={`${styles.listItem}`}>Unlimited activities</p>
          {
            benefits.map((item, idx) =>
              <p className={`${styles.listItem}`}>{item}</p>
            )
          }
        </div>

        {
          ctaAction == "contact" ? 

            <a className={`${styles.pinkBtn}`} href="mailto:info@escribli.com">{ctaText}</a>

          :

            <Link to={"/auth/teacher/signup"} className={`${styles.pinkBtn}`}>
              {ctaText}
            </Link>

        }

    </div>
  )

}

export default PricingCard;