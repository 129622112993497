import React from 'react';
import HeroPng from '../../../Assets/HomePage/heroPng.png'
import LogoHero from '../../../Assets/HomePage/logoHero.png'
import styles from './Hero.module.scss'
import { useReferralLink } from '../../../Hooks/useReferralLink'
import ReactGA from 'react-ga';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Hero = () => {
    return (
        <section className={styles.hero}>
            <div className={styles.heroTitle}>
                <LazyLoadImage alt='icon' src={LogoHero} effect="blur" />
                <h1>The best writing tools for teachers</h1>
                <h2>Inspire your students to write with a purpose in all subject areas.</h2>
                <a href={useReferralLink()} onClick={() => ReactGA.event({ category: 'Hero', action: 'Click on sign up hero btn' })}>Start free trial</a>
                <p>Recommentded for grades 2-8</p>
            </div>
            <div className={styles.test}>
                {/* <LazyLoad height={650}>
                    <img className={styles.bigScreen} src={HeroPng} alt='girl' />
                </LazyLoad> */}
                <LazyLoadImage alt='icon' src={HeroPng} effect="blur" height={760}/>
                {/* <img className={styles.bigScreen} src={HeroPng} alt='girl' /> */}
            </div>


        </section>
    );
}

export default Hero;