import React, { useState } from 'react';
import styles from './Tools.module.scss'
import journalBoomer from './../../../Assets/Boomers/journal-illustration.png'
import scienceBoomer from './../../../Assets/Boomers/science_lab.png'
import storybookBoomer from './../../../Assets/Boomers/storybook-boomer.png'
import { Slide, Slider, CarouselProvider } from 'pure-react-carousel';
import CarouselBtn from '../../Structural/CarouselBtn/CarouselBtn';
import ToolCard from './Cards/ToolCard';
import LazyLoad from 'react-lazyload';

const Tools = ({ mobile }) => {
    const [slide, setSlide] = useState(0)

    if (!mobile) {
        return (
            <LazyLoad height={100}>
                <section style={{ marginTop: '5em' }}>
                    <h2>Our Teacher’s Toolbox</h2>
                    <div className={styles.toolsContainer}>

                        <ToolCard color='orange' icon={storybookBoomer} title={'Stories'} text={'Students love writing stories, and our Stories tool lets teachers conduct interactive group or individual story writing activities that improve students’ narrative writing abilities and understanding of key fictional elements.'} />
                        <ToolCard color='blue' icon={scienceBoomer} title={'Assignments'} text={'From crafting arguments and composing informational pieces to developing poetry techniques or explaining mathematical thinking, our Assignments tool facilitates the creation of a limitless number of writing activities in any genre or subject.'} />
                        <ToolCard color='orangeBright' icon={journalBoomer} title={'Journals'} text={'Our digital Journals provide students an invaluable and easily accessible place to write consistently. Be it reading responses, SEL, or teacher-selected daily/weekly prompts, journals help students build confidence and fluency in writing.'} />

                    </div>
                </section>
            </LazyLoad>
        );
    } else {
        return (
            <LazyLoad height={100}>
                <section className={styles.toolsContainer}>
                    <h2 className={styles.move}>Our Teacher’s Toolbox</h2>

                    <CarouselProvider
                        naturalSlideWidth={364}
                        naturalSlideHeight={411}
                        totalSlides={3}
                        currentSlide={slide}
                        touchEnabled={false}
                        infinite
                    >
                        <div className={styles.centerCarousel}>

                            <Slider className={styles.slider}>
                                <Slide index={0}>
                                    <ToolCard color='orange' icon={storybookBoomer} title={'Stories'} text={'Students can work independently or as a group to complete an original story that can even be published!'} />
                                </Slide>
                                <Slide index={1}>
                                    <ToolCard color='blue' icon={scienceBoomer} title={'Assignments'} text={'Create assignments specific to any subject area with the ability to upload supporting visuals, links, and documents.'} />
                                </Slide>
                                <Slide index={2} classNameHidden={styles.hidden}>
                                    <ToolCard color='orangeBright' icon={journalBoomer} title={'Journals'} text={'Designed to build daily writing habits, our journals provide a place for students to consistently write about anything.'} />
                                </Slide>

                            </Slider>
                            <div className={styles.carouselControls}>


                                <div className={styles.dots}>
                                    <div className={slide === 0 ? ` ${styles.dot} ${styles.active}` : styles.dot} onClick={(e) => { e.stopPropagation(); setSlide(0) }}></div>
                                    <div className={slide === 1 ? ` ${styles.dot} ${styles.active}` : styles.dot} onClick={(e) => { e.stopPropagation(); setSlide(1) }}></div>
                                    <div className={slide === 2 ? ` ${styles.dot} ${styles.active}` : styles.dot} onClick={(e) => { e.stopPropagation(); setSlide(2) }}></div>
                                </div>

                                <div className={styles.buttons}>

                                    <div onClick={() => slide === 0 ? setSlide(2) : setSlide(slide - 1)} ><CarouselBtn last white /></div>
                                    <div onClick={() => slide === 2 ? setSlide(0) : setSlide(slide + 1)} ><CarouselBtn next /></div>

                                </div>
                            </div>
                        </div>
                    </CarouselProvider>

                </section>
            </LazyLoad>

        )
    }

}

export default Tools;