import React from 'react';
import styles from './Stories.module.scss'
import writer1 from '../../../Assets/Tools/writer1.png'
import writer2 from '../../../Assets/Tools/writer2.png'
import { Slide, Slider, CarouselProvider } from 'pure-react-carousel';
import CarouselBtn from '../../Structural/CarouselBtn/CarouselBtn';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const WritersCarousel = () => {
  return ( 
    <div className={styles.writers}>
                <h3>Some of our story start writers</h3>
                <p>Our curated and expansive collection of story starts are created by an ever-growing assortment of renowned authors and personalities. All accessible in our writing app.</p>
                <div className={styles.carouselProvider}>
                    <CarouselProvider
                        naturalSlideWidth={380}
                        naturalSlideHeight={380}
                        totalSlides={2}
                        visibleSlides={2}
                        infinite
                    >
                        <div className={styles.centerCarousel}>

                            <Slider >
                                <Slide index={0} className={styles.slider}>
                                    <LazyLoadImage src={writer1} alt='' effect="blur"/>
                                </Slide>

                                <Slide index={1} className={styles.slider}>
                                    <LazyLoadImage src={writer2} alt='' effect="blur"/>
                                </Slide>


                            </Slider>


                            {/* <div className={styles.last}><CarouselBtn last /></div>
                            <div className={styles.next}><CarouselBtn next /></div> */}

                        </div>


                    </CarouselProvider>
                </div>
                <div className={styles.mobileCarouselProvider}>
                    <CarouselProvider
                        naturalSlideWidth={315}
                        naturalSlideHeight={315}
                        totalSlides={2}
                        visibleSlides={1}
                        infinite
                    >
                        <div className={styles.centerCarousel}>

                            <Slider >
                                <Slide index={0} className={styles.slider}>
                                    <LazyLoadImage src={writer1} alt='' effect="blur"/>
                                </Slide>

                                <Slide index={1} className={styles.slider}>
                                    <LazyLoadImage src={writer2} alt='' effect="blur"/>
                                </Slide>


                            </Slider>


                        </div>
                        <div className={styles.buttons}>

                            <CarouselBtn last white />
                            <CarouselBtn next />
                        </div>


                    </CarouselProvider>

                </div>
            </div>
   );
}
 
export default WritersCarousel;