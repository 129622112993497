import React from 'react';

import styles from './Benefits.module.scss';
import boomer from '../../../Assets/Avatars/avatar_girl.png'
import girl from '../../../Assets/HomePage/girl_tablet.svg'

const Benefits = () => {

  return (
    <div className={styles.container}>

      <h1 className={styles.header}>Benefits for Teachers and Students</h1>

      <div className={styles.boxes}>
        <div className={`${styles.box} ${styles.blue}`}>
          <div className={`${styles.top} ${styles.blue}`}> <h2>Consistency</h2> </div>
          <div className={`${styles.bottom} ${styles.blue}`}>
            <p>Every teacher knows consistent writing leads to improved writing. Our tools make it easy for teachers to consistently create relevant writing activities in every subject.</p>
          </div>
        </div>
        <div className={`${styles.box} ${styles.green}`}>
          <div className={`${styles.top} ${styles.green}`}> <h2>Interactive</h2> </div>
          <div className={`${styles.bottom} ${styles.green}`}>
            <p>Students model writing and learn from one another by engaging in side-by-side writing in our breakout writing rooms.</p>
          </div>
        </div>
        <div className={`${styles.box} ${styles.orange}`}>
          <div className={`${styles.top} ${styles.orange}`}> <h2>Responsiveness</h2> </div>
          <div className={`${styles.bottom} ${styles.green}`}>
            <p>Teachers can provide students personalized writing feedback and support using our teacher-student chat and revision request features throughout each writing activity.</p>
          </div>
        </div>
      </div>

      <h2 className={styles.bottomText}>We help transform ALL of your students into better writers!</h2>
      <div className={styles.bgCropper}>
        <img className={styles.girl} src={girl} alt="girl"/>
        <img className={styles.boomer} src={boomer} alt="boomer"/>
        <div className={styles.gradient}></div>
      </div>
    </div>
  )

}

export default Benefits;