import React from 'react';
import '../PrivacyPolicy/PrivacyPolicy.css'

const CookiePolicy = () => {
    return (
        <div class="containerNoFlex terms-privacy">
            <h1 class="headingHero">Cookie Policy</h1>
            <p><strong>Effective August 1, 2021</strong></p>
            <p>This cookie policy is an integral part of Escribli's terms of use and the privacy policy of Escribli. Accessing and browsing the site or using the site's services implies acceptance of the terms and conditions set out in our terms of use (and thus the privacy policy and cookie policy). Please read them carefully.</p>
            <p>Thank you for visiting us. We want your experience on the site to be as good as possible, which is why we have written this cookie policy as transparently as possible.</p>

            <h2 class="heading">What Is a Cookie</h2>
            <p>Cookies are files containing small amounts of data that are downloaded to the user's device used when visiting a website. Their main purpose is to recognize you each time you visit Escribli and enables us to improve the quality and usability of our website.</p>
            <p>Cookies are essential for the functioning of the Internet. They cannot damage the user's computer/device and, if activated in the configuration of your browser, they help us to identify and fix possible errors in the functioning of https://escribli.com/.</p>

            <h2 class="heading">Types of Cookies</h2>
            <p>There are different types of cookies. They all work the same way, but they have small differences:</p>

            <ul class="privacyList">
                <li><strong>Session cookies:</strong> session cookies only last for the duration of your visit and are deleted when you close your browser. Their main purpose is to identify the type of device, support the security of the website or its basic functionality. They do not contain any personal data that allows us to identify a person.</li>
                <li><strong>Persistent or permanent cookies:</strong> these are stored on the device's hard drive and our website reads them every time you visit https://escribli.com/. They have an expiry date that determines when cookies stop working. They enable us to identify your actions and preferences, analyse visits and help us understand how users come to our site and improve our services.</li>
                <li><strong>Functionality cookies:</strong> these allow https://escribli.com/ to remember user decisions, such as their login or identification. The information these cookies collect is anonymized (i.e. it does not contain your name, address, or other data).</li>
                <li><strong>Third party cookies:</strong> third party cookies are cookies that are installed by a website that is not the website you are visiting; for example, cookies used by social networks (such as Facebook) or by external content collectors (such as Google Maps). In addition, some advertising companies use these types of files to keep track of your visits to each site where they are advertised.</li>
                <li><strong>Analytical cookies:</strong> these are cookies intended for periodic maintenance and to ensure the best performance and service to the user, collecting data about their activity.</li>
            </ul>

            <h2 class="heading">Use of cookies by https://escribli.com/</h2>
            <p>By visiting https://escribli.com/, you expressly accept that we may use the following types of cookies on your devices. If you disable cookies, your navigation on https://escribli.com/ may not be optimal and some of the tools available on https://escribli.com/ may not function properly.</p>
            <p>This cookie policy only applies to visitors of escribli.com and in no way affects access by students, teachers or parents to their personal profile on our platform or application. Cookies are not active when students, teachers or parents log in to their personal profile. Escribli does not track or use any IP or personal information on the student platform for marketing purposes.</p>
            <h3 class="headingSmall bodyTextColour">1. Our cookies</h3>
            <p>In order to recognize you and provide you with a better service, our site uses its own cookies (small text files that your browser stores). The benefits of this acceptance result in time savings. They can also be used to recognize you between successive visits and thus adapt the content displayed, to obtain information about the date and time of your last visit, to measure some traffic data within the site itself and to estimate the number of visits, so that we can concentrate on the services and promotions and adapt them more effectively.</p>
            <p>No cookie will allow us to contact your phone number, email address or any other means of contact. No cookie can retrieve information from your hard drive or steal personal information.</p>

            <table class="privacyTable">
                <thead>
                    <tr>
                        <th>Cookie</th>
                        <th>Function</th>
                        <th>Expiry Date</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td class="privacyTable-summary"><p>AWSALB</p></td>
                        <td class="privacyTable-moreInfo"><p>Registers which server-cluster is serving the visitor. This is used in context with load balancing, in order to optimize user experience.</p></td>
                        <td class="privacyTable-summary">6 days</td>
                    </tr>
                    <tr>
                        <td class="privacyTable-summary"><p>AWSALBCORS</p></td>
                        <td class="privacyTable-moreInfo"><p>Registers which server-cluster is serving the visitor. This is used in context with load balancing, in order to optimize user experience.</p></td>
                        <td class="privacyTable-summary">6 days</td>
                    </tr>
                    <tr>
                        <td class="privacyTable-summary"><p>_ga</p></td>
                        <td class="privacyTable-moreInfo"><p>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</p></td>
                        <td class="privacyTable-summary">2 years</td>
                    </tr>
                    <tr>
                        <td class="privacyTable-summary"><p>_gat</p></td>
                        <td class="privacyTable-moreInfo"><p>Used by Google Analytics to throttle request rate.</p></td>
                        <td class="privacyTable-summary">1 day</td>
                    </tr>
                    <tr>
                        <td class="privacyTable-summary"><p>_gid</p></td>
                        <td class="privacyTable-moreInfo"><p>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</p></td>
                        <td class="privacyTable-summary">1 day</td>
                    </tr>
                    <tr>
                        <td class="privacyTable-summary"><p>_fbp</p></td>
                        <td class="privacyTable-moreInfo"><p>Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</p></td>
                        <td class="privacyTable-summary">3 months</td>
                    </tr>
                    <tr>
                        <td class="privacyTable-summary"><p>_escribli_session</p></td>
                        <td class="privacyTable-moreInfo"><p>Used by Escribli to store the session of the logged in user. This cookie only identifies the logged in user, no extra data is stored in it.</p></td>
                        <td class="privacyTable-summary">Session</td>
                    </tr>
                    <tr>
                        <td class="privacyTable-summary"><p>_schools_session</p></td>
                        <td class="privacyTable-moreInfo"><p>Used by Escribli to store the session of the logged in user. This cookie only identifies the logged in user, no extra data is stored in it.</p></td>
                        <td class="privacyTable-summary">Session</td>
                    </tr>
                    <tr>
                        <td class="privacyTable-summary"><p>_pin_unauth</p></td>
                        <td class="privacyTable-moreInfo"><p>Used by Facebook to deliver a series of advertisement products regarding Pixel tracking.</p></td>
                        <td class="privacyTable-summary">1 year</td>
                    </tr>

                </tbody>
            </table>

            <h3 class="headingSmall bodyTextColour">2. Third party cookies - Google Analytics Cookies</h3>
            <p>This site uses the Google Analytics service, which is provided by Google, Inc. headquartered at 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, United States ("Google").</p>
            <p>Google Analytics uses cookies to help us analyse how users use the website. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on its servers in the United States. Google will use this information on our behalf for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not link your IP address with any other data held by Google.</p>
            <p>If you wish, you can refuse the processing of data or information by refusing the use of cookies. You can do this by selecting the appropriate settings on your browser. However, if you do so, you may not be able to use the full functionality of this site. By using this site, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>

            <table class="privacyTable">
                <thead>
                    <tr>
                        <th>Cookie</th>
                        <th>Function</th>
                        <th>Owner</th>
                        <th>Expiry Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><p>local_storage_su pport_test</p></td>
                        <td><p>The cookie is used in context with the local-storage function in the browser. This function allows the web site to load faster by pre-loading certain procedures.</p></td>
                        <td>twitter.com</td>
                        <td>Persistent</td>
                    </tr>
                    <tr>
                        <td><p>lang</p></td>
                        <td><p>Remembers the user's selected language version of a website.</p></td>
                        <td>cdn.syndication.twimg.com</td>
                        <td>Session</td>
                    </tr>
                    <tr>
                        <td><p>collect</p></td>
                        <td><p>Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels.</p></td>
                        <td>google-analytics.com</td>
                        <td>Session</td>
                    </tr>
                    <tr>
                        <td><p>personalization_ id</p></td>
                        <td><p>This cookie is set by Twitter - The cookie allows the visitor to share content from the website onto their Twitter profile.</p></td>
                        <td>twitter.com</td>
                        <td>2 years</td>
                    </tr>
                    <tr>
                        <td><p>vuid</p></td>
                        <td><p>Collects data on the user's visits to the website, such as which pages have been read.</p></td>
                        <td>vimeo.com</td>
                        <td>2 years</td>
                    </tr>
                    <tr>
                        <td><p>__widgetsettings</p></td>
                        <td><p>Collects data on user behavior and interaction in order to optimize the website and make advertisement on the website more relevant.</p></td>
                        <td>twitter.com</td>
                        <td>Persistent</td>
                    </tr>
                    <tr>
                        <td><p>ads/ga-audiences</p></td>
                        <td><p>Used by Google AdWords to re-engage visitors that are likely to convert to customers based on the visitor 's online behaviour across websites.</p></td>
                        <td>google.com</td>
                        <td>Session</td>
                    </tr>
                    <tr>
                        <td><p>fr</p></td>
                        <td><p>Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</p></td>
                        <td>facebook.com</td>
                        <td>3 months</td>
                    </tr>
                    <tr>
                        <td><p>i/adsct</p></td>
                        <td><p>The cookie is used by Twitter.com in order to determine the number of visitors accessing the website thro ugh twitter advertisement content.</p></td>
                        <td>t.co</td>
                        <td>Session</td>
                    </tr>
                    <tr>
                        <td><p>i/jot/syndication</p></td>
                        <td><p>The cookie is used by Twitter.com for marketing data and communication</p></td>
                        <td>twitter.com</td>
                        <td>Session</td>
                    </tr>
                    <tr>
                        <td><p>IDE</p></td>
                        <td><p>Used by Google DoubleClick to register and report the website user's actions after viewing or clicking on e of the advertiser's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.</p></td>
                        <td>doubleclick.net</td>
                        <td>1 year</td>
                    </tr>
                    <tr>
                        <td><p>pagead/1p-user-list/#</p></td>
                        <td><p>The cookie is used by Google.com for marketing data and communication</p></td>
                        <td>google.com</td>
                        <td>Session</td>
                    </tr>
                    <tr>
                        <td><p>test_cookie</p></td>
                        <td><p>Used to check if the user's browser supports cookies.</p></td>
                        <td>doubleclick.net</td>
                        <td>1 day</td>
                    </tr>
                    <tr>
                        <td><p>tr</p></td>
                        <td><p>Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</p></td>
                        <td>facebook.com</td>
                        <td>Session</td>
                    </tr>
                    <tr>
                        <td><p>v3</p></td>
                        <td><p>Used by Pinterest to track the usage of services</p></td>
                        <td>pinterest.com</td>
                        <td>Session</td>
                    </tr>
                </tbody>
            </table>

            <h2 class="heading">User settings to block cookies</h2>
            <p>Via https://escribli.com/ and in accordance with current legislation, we provide you with the information that allows you to configure your Internet browser(s) to maintain your privacy and security with respect to cookies. For this reason, we provide you with information and links to the official support sites of major browsers so that you can decide whether to accept the use of cookies.</p>
            <p>You can block cookies using your browser's configuration tools or you can set your browser to warn you when a server wants to store a cookie:</p>

            <ul class="privacyList">
                <li>a{')'}  If you are using Microsoft Internet Explorer, go to Tools &gt; Internet Options &gt; Privacy &gt; Settings. For more information, go to https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</li>
                <li>b{')'}  If you are using Firefox, you can do so from the menu option Menu &gt; Options &gt; Privacy &amp; Security &gt; Cookies and Site data. For more information, go to https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox</li>
                <li>c{')'}   If you are using Chrome, you can do so in the Menu &gt; Settings &gt; Cookies and other site data. For more information, go to https://support.google.com/chrome/answer/95647?hl=en</li>
                <li>If you use Opera, you can configure the browser in the Privacy &amp; Security option. For more information, go to https://help.opera.com/en/latest/web-preferences/</li>
            </ul>
        </div>
    );
}

export default CookiePolicy;