import React from 'react';
import styles from './Bullet.module.scss'

const Bullet = ({ text, icon }) => {
    return (
        <div className={styles.bullet}>
            <img src={icon} alt='' />
            <p>
                {text}
            </p>
        </div>
    );
}

export default Bullet;