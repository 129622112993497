import React, { useState } from 'react';
import styles from './CookieModal.module.scss'
import closeIcon from './../../../Assets/Icons/whiteIcons/closeIcon.svg'
import cookie from './../../../Assets/Icons/cookie.svg'
import { Link } from 'react-router-dom';


const CookieModal = () => {

    const [isVisited, setVisited] = useState(JSON.parse(localStorage.getItem('AcceptedCookies')));

    const handleClick = (bool) => {
        localStorage.setItem('AcceptedCookies', bool)
        setVisited(bool)
    }
    const screenWidth = window.screen.width



    return (
        <div className={isVisited ? styles.hidden : styles.cookieModal}>

            {screenWidth>600&&<img className={styles.cookie} src={cookie} alt={cookie} />}
            <p>This site uses its own cookies and third-party cookies to improve the usability of our site, your access and the personalization of our content. If you continue to visit our site, we understand that you give us your express consent for us to use them. You can get more information about them and their configuration in our cookie policy.</p>
            <p>If you would liko to know more or configure their usem you can do so by clicking here <Link to='/home/cookie-policy'>Cookie Policy</Link></p>
            <button onClick={() => handleClick(true)}>Accept</button>
            <div className={styles.closingBtn} onClick={(e) => handleClick(true)}>
                <img src={closeIcon} alt='close icon' />

            </div>

        </div>
    );
}

export default CookieModal;