import {combineReducers} from 'redux';
import {pricingReducer} from './PricingReducer'
import {storeData} from './storeReducer'

const rootReducer = combineReducers({
    pricing:pricingReducer,
    storeData:storeData

})

export default rootReducer;