import React from 'react';
import styles from './ElementCard.module.scss'
import curvedLine from './../../../../Assets/HomePage/curvedLine.svg'
const ElementCard = ({ num, text, title, img, last, reversed }) => {
    
    return (
        <div className={styles.card}>
            
            <div className={!last?styles.imgWrapper:styles.bookWrapper}>
                <img src={img} alt='' className={last&&styles.books}/>
            </div>

            <div className={styles.decoration}>
                <div className={styles.cardNumber}>{num}</div>
                {last ? <></> : <div><img className={reversed ? `${styles.curvedLine} ${styles.reversed}` : styles.curvedLine} src={curvedLine} alt='' /> </div>}
            </div>
            <div className={styles.text}>
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        </div>
    );
}

export default ElementCard;