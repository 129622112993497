import React from 'react';
import Engagement from './Engagement';
import Writing from './Writing';
import styles from './WritingEngagement.module.scss'

const WritingEngagement = () => {
  return ( 
    <section className={styles.writingEngagement}>

      <Writing/>
      <Engagement/>


    </section>
   );
}
 
export default WritingEngagement;