import React from 'react';
import styles from './HomePage.module.scss'
// COMPONENTS
import Hero from './Sections/Hero';
import RealData from './Sections/RealData';
import Consistency from './Sections/Consistency';
// import Curriculum from './Sections/Curriculum';
import Tools from './Sections/Tools';
import Pieces from './Sections/Pieces';
import Features from './Sections/Features';
import Teachers from './Sections/Teachers'
import Map from './Sections/Map'
import HeroMobile from './Sections/HeroMobile'
// import Elements from './Sections/Elements';
import Trust from './Sections/Trust';
import Awards from './Sections/Awards';

//SEO
import { Helmet } from "react-helmet";
import WritingBeeOnHome from './Sections/WritingBeeOnHome';
import { useWindowSize } from '../../Hooks/UseWindowSize';

const HomePage = ({  }) => {

    const size = useWindowSize()


    return (
        <div className={styles.HomePageWrapper}>
            <Helmet>

                <title>Improve Student Writing | Teachers Parents | Escribli</title>
                <meta name="description" content="Escribli is an online writing platform that provides teachers with powerful tools to inspire and engage their students to become better writers." />
                <meta name="keywords" content="Escribli tools, writing tool, writing tools, distance learning, online writing tools" />

            </Helmet>
            <div className={styles.centerContainer}>
                <div className={styles.HomePageContainer}>
                    {size.width >= 950 ? <Hero /> : <HeroMobile />}
                    <WritingBeeOnHome />
                    <Consistency />
                </div>
            </div>

            {size.width >= 1150 ?
                <div className={styles.whiteWave}>
                    <div className={styles.centerContainer}>
                        <div className={styles.HomePageContainer}>
                            <Pieces />
                            <Tools />
                        </div>
                    </div>
                </div>
                :
                <div className={styles.centerContainer}>
                    <div className={styles.HomePageContainer}>
                        <Pieces mobile />
                        <Tools mobile />
                    </div>
                </div>
            }
            {size.width >= 750 ?
                <div className={styles.blueWave}>
                    <div className={styles.centerContainer}>
                        <div className={styles.HomePageContainer}>
                            <Features />
                            <Teachers />
                        </div>
                    </div>
                </div>
                :
                <div className={styles.centerContainer}>
                    <div className={styles.HomePageContainer}>
                        <Features mobile />
                        <Teachers />
                    </div>
                </div>
            }

            {size.width >= 950 ?
                <div className={styles.downGreyWave}>
                    <div className={styles.centerContainer}>
                        <div className={styles.HomePageContainer}>
                            <Map />
                            <RealData />
                            <Trust />
                        </div>
                    </div>
                </div>
                :
                <div className={styles.centerContainer}>
                    <div className={styles.HomePageContainer}>
                        <Map mobile />
                        <Trust mobile />
                    </div>
                </div>
            }

            <div className={styles.whiteWaveUnder}>
                <div className={styles.centerContainer}>
                    <div className={styles.HomePageContainer}>
                        <Awards />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;