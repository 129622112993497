import React from 'react';
import styles from './Elements.module.scss'
import readingBoomers from './../../../Assets/Boomers/playing-books.png'
import pencil from './../../../Assets/Boomers/pencil.png'
import laptop from './../../../Assets/Boomers/laptop.png'
import book from './../../../Assets/Boomers/book.png'
import voting from './../../../Assets/Boomers/voting.png'
import winnerBoomer from './../../../Assets/Boomers/winner-boomer.png'

import ElementCard from './Cards/ElementCard';
import LazyLoad from 'react-lazyload';

const Elements = () => {
    return (
        <div style={{width:"100%"}}>

        <div className={styles.elementsContainer}>
            {/* <div className={styles.pseudoParent}></div> */}
            <div className={styles.elementsCardContainer}>
                <ElementCard num='1' title='Read' img={readingBoomers} text='Students read a captivating story start that ends in a cliff-hanging moment and serves as a springboard for their imagination!' />
                <ElementCard num='2' reversed title='Write' img={pencil} text='Students write their own version of chapter 2 and can include the introduction of any new characters, events, or plot twists.' />
                <ElementCard num='3' title='Revise' img={laptop} text='Students revise their chapter after receiving personalized feedback from the  teacher before then resubmitting for approval.' />
                <ElementCard num='4' reversed title='Vote' img={voting} text='After reading the anonymous submissions of their peers, students vote for their favorites.' />
                <ElementCard num='5' title='Chapter Winner' img={winnerBoomer} text='When voting ends, a winning submission is identified as the next chapter in the story.' />
                <ElementCard num='6' title='Repeat and Complete' img={book} text='The process repeats until the story is completed and ready to be published as a real paperback book!' last />
            </div>
        </div>
        </div>
    );
}

export default Elements;