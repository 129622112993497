import React from 'react';
import styles from './Journey.module.scss'
import JourneyDecoration from './../../../../Assets/Parents/journeyDecoration.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const Journey = () => {
  return ( 
    <section className={styles.journey}>
      <div className={styles.container}>

        <div className={styles.titleContainer}>
          <h2>Follow your child's learning journey with Escribli</h2>
          <div className={styles.imgWrapper}>
            <LazyLoadImage src={JourneyDecoration} alt="" effect="blur"/>
          </div>
        </div>

        <div className={styles.textContainer}>
          <p>Through Escribli's Consistent Student Improvement Model, your child is able to develop communication skills, write daily in any subject, and even collaborate with peers to create an original story that can be published as a personalized soft-cover book.</p>
          <p>It's the age-old question: <span>What did you do at school today?</span> With our new Families App, you can see for yourself! Keep track of your child's writing activities and follow their work all in one place!</p>        
        </div>
      </div>
    </section>
   );
}
 
export default Journey;