const host = window.location.host
const env = host === 'escribli.com' ?  'prod' : host === 'sand.escribli.com' ? 'stage' : 'dev'


{/* @todo update_URLs */}
export const redirectTeacherApp = (route) => {
  if(env==='dev'){
    window.location=`http://localhost:3000/teacher/${route}`

  } else if (env==='stage'){
    window.location=`https://test.escribli.com/teacher/${route}`}

  else {
    window.location=`https://app.escribli.com/teacher/${route}`
  }
}