import React from 'react';

import { toFixedString } from '../../../../Helpers/uiFunctions';

import styles from './checkoutStyles.module.scss';
import booksImg from '../../../../Assets/Bookstore/icon-books.svg';

const OrderSummaryBlock = ({ summaryDetails, bookDetails, activityType, isOpenClassOrder }) => {

  let shipping = summaryDetails.shippingOption ? summaryDetails.shippingOption.price : 0;

  return (
    <div className={styles.orderSummaryBlock}>
      <div className={styles.title}>
          <img src={booksImg} alt="books"/>
          <h5>{bookDetails.bookName}</h5>
      </div>

      <div className={styles.bookDetails}>
        <div className={styles.row}>
          <p className={styles.item}>Access code:</p>
          <p className={styles.data}>{bookDetails.bookCode}</p>
        </div>
        <div className={styles.row}>
          <p className={styles.item}>Class:</p>
          <p className={styles.data}>{bookDetails.classTitle}</p>
        </div>
        <div className={styles.row}>
          <p className={styles.item}>Teacher:</p>
          <p className={styles.data}>{bookDetails.teacher}</p>
        </div>
        {bookDetails.school &&
          <div className={styles.row}>
            <p className={styles.item}>School:</p>
            <p className={styles.data}>{bookDetails.school}</p>
          </div>
        }
        {
          isOpenClassOrder &&
            <div className={styles.row}>
              <p className={styles.item}>Type:</p>
              <p className={styles.data}>Class Order</p>
            </div>

        }
      </div>

      <div className={styles.orderSummaryLower}>
        <h5>Purchase summary:</h5>

        {
          summaryDetails.creditDiscount > 0 &&

            <div className={`${styles.row} ${styles.short} ${styles.creditDiscount}`}>
              <p className={styles.item}>Credit discount:</p>
              <p className={styles.data}>${summaryDetails.creditDiscount} <span className={styles.bar}></span> </p>
            </div>

        }
        
        <div className={`${styles.row} ${styles.short}`}>
          <p className={styles.item}>{activityType==='story'?'Books':'Journals'}:</p>
          <p className={styles.data}>${summaryDetails.books.toFixed(2)}</p>
        </div>
        <div className={`${styles.row} ${styles.short}`}>
          <p className={styles.item}>Shipping:</p>
          {
            isOpenClassOrder ? 
            <p className={styles.data}>$2.00</p>

            :

            <p className={styles.data}>{summaryDetails.shippingOption ? `$${summaryDetails.shippingOption.price}` : '--'}</p>

          }
        </div>
        <div className={`${styles.row} ${styles.short}`}>
          <p className={styles.item}>Total:</p>
          {
            isOpenClassOrder ? 
            <p className={styles.data}>${summaryDetails.copies>0 ? toFixedString((summaryDetails.books+2), 2) :0}</p>
          :
            <p className={styles.data}>${summaryDetails.copies>0 ? toFixedString((summaryDetails.books+shipping), 2) :0}</p>
          }
        </div>
      </div>

      <p className={styles.subtext}>
        All prices are shown and charged in $USD
      </p>

    </div>
  )

}

export default OrderSummaryBlock;






  //tax stuff removed for now:


  // const [ tax, setTax ] = useState(0)

  // useEffect(()=>{
  //   const unrounded = (summaryDetails.books/100*20);
  //   setTax(toFixedString(unrounded, 2));

  // },[summaryDetails])


  {/* <div className={`${styles.row} ${styles.short}`}>
    <p className={styles.item}>Tax:</p>
    <p className={styles.data}>${tax}</p>
  </div> */}


    {/* <p className={styles.data}>${summaryDetails.books>0 ? toFixedString((summaryDetails.books+5+parseFloat(tax)), 2) :0}</p> */}