import React from 'react';
import styles from './LoginModal.module.scss'
import bmLearning from './../../../Assets/Icons/logoBlue.svg'
import whiteApple from './../../../Assets/Icons/whiteIcons/whiteApple.svg'
import whiteCap from './../../../Assets/Icons/whiteIcons/whiteCap.svg'
import whiteHouse from './../../../Assets/Icons/whiteIcons/whiteHouse.svg'
import closeIcon from './../../../Assets/Icons/whiteIcons/closeIcon.svg'
import homeOf from './../../../Assets/Icons/home_of_bee-wide.svg'
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

const LoginModal = ({ show, setModal, signup }) => {

    window.addEventListener('keydown', (e) => { if (e.keyCode === 27) { setModal(false) } }); //line to press esc and go off modal

    const handleGA = (term) => {
        if (signup) {
            term === 'teacher' && ReactGA.event({ category: 'Auth Modal', action: 'teacher sign up' })
            term === 'student' && ReactGA.event({ category: 'Auth Modal', action: 'student log in' })
            term === 'parent' && ReactGA.event({ category: 'Auth Modal', action: 'parent sign up' })
        } else {
            term === 'teacher' && ReactGA.event({ category: 'Auth Modal', action: 'teacher log in' })
            term === 'student' && ReactGA.event({ category: 'Auth Modal', action: 'student log in' })
            term === 'parent' && ReactGA.event({ category: 'Auth Modal', action: 'parent log in' })
        }
    }

    return (

        <div className={show ? `${styles.modal} ${styles.show}` : styles.modal} onClick={(e) => { e.stopPropagation() }}>
            <div className={`${styles.container} ${styles.loginModal}`} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalHeader}>
                    <h3>{'Get started on'}</h3>
                    <div className={styles.imgWrapper}>
                        <img src={bmLearning} alt='Escribli learning logo'></img>
                    </div>
                </div>
                {/* <img className={styles.homeOf} src={homeOf} alt="home of the writing bee"/> */}
                <div className={styles.optionsContainer}>
                    <div className={styles.option}>
                        <Link to={signup ? '/auth/teacher/signup' : '/auth/teacher'} onClick={() => handleGA('teacher')}>
                            {/* <a href={signup?'/premium':'auth/teacher'}> */}


                            <div className={styles.imgWrapper}>
                                <img src={whiteApple} alt='decoration'></img>
                            </div>
                            <h3>I'm a Teacher</h3>
                        </Link>
                        
                    </div>
                    <div className={styles.option}>
                        <Link to='/auth/student' onClick={() => handleGA('student')}>
                            {/* <a href={'auth/student'}> */}


                            <div className={styles.imgWrapper}>
                                <img src={whiteCap} alt='decoration'></img>
                            </div>
                            <h3>I'm a Student</h3>
                        </Link>
                    </div>
                </div>
                <div className={styles.closingBtn} onClick={(e) => { e.stopPropagation(); setModal(false) }}>
                    <img src={closeIcon} alt='close icon' />
                </div>
            </div>
        </div>

    );
}

export default LoginModal;