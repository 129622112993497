import React from 'react';
import styles from './Awards.module.scss'
import winnerBoomer from './../../../Assets/Boomers/winner-boomer.png'
import aasl from './../../../Assets/certifications/aasl.png'
import edtech from './../../../Assets/certifications/edtech.png'
import google from './../../../Assets/certifications/Google-for-Education-Partner-badge-TRANSPARENT-1.png'
import { LazyLoadComponent } from 'react-lazy-load-image-component';


// import spps from './../../../Assets/certifications/Student-Privacy-Pledge-Sig_logo.png'
const Awards = ({ parents }) => {
    return (
        <LazyLoadComponent>
            <section className={parents ? styles.awardsContainerParents : styles.awardsContainer}>
                <div className={styles.text}>
                    <div>
                        <img src={winnerBoomer} alt='text decoration' />
                    </div>
                    {parents ?
                        <h2>Escribli is an award-winning writing app.</h2>
                        :
                        <h2>Escribli is an award-winning writing app committed to maintaining compliance in all areas involving school and student privacy and safety.</h2>
                    }
                </div>

                {/* @todo update_kidsafe_data */}
                <div className={styles.logoContainer}>
                    <a href="https://www.kidsafeseal.com/certifiedproducts/boomwriter.html" target="_blank" rel="noopener noreferrer">
                        <img border="0" alt="Boomwriter.com is certified by the kidSAFE Seal Program." src="https://www.kidsafeseal.com/sealimage/124387489510662211/boomwriter_large_darktm.png" />
                    </a>
                    <div><img src={aasl} alt='certification logo' /></div>
                    <div><img src={google} alt='certification logo' /></div>
                    <div><img src={edtech} alt='certification logo' /></div>
                </div>
            </section>
        </LazyLoadComponent>
    );
}

export default Awards;