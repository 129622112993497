import React from 'react';
import styles from './ParentCard.module.scss'

const Parents = () => {
  return (
    <section className={styles.parentCard}>
      <h2>Families love Escribli</h2>
      <div className={styles.container}>
        <div className={styles.actualCard}>
          <div className={styles.textContainer}>
            <h3>Ryan Rauch</h3>
            <p>"My nine year old daughter has been enrolled in Escribli for the past few months and we could not be bigger fans. The content and platform are excellent. Since she began, my daughter has become a much better writer.... We recently enrolled our older son in Escribli as well and in only a couple of weeks, we have seen a marked improvement in his writing. We would highly recommend Escribli and believe it is one of the best online writing enhancement resources that we have found."</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Parents;