import React from 'react';
import Haunt from './Sections/Haunt/Haunt';
import Hero from './Sections/Hero/Hero';
import Interactive from './Sections/Interactive/Interactive';
import Journey from './Sections/Journey/Journey';
import Awards from './../HomePage/Sections/Awards'
import WritingEngagement from './Sections/WritingEngagement/WritingEngagement';
import ParentCard from './Sections/ParentCard/ParentCard'
import { Helmet } from 'react-helmet';
import FamilyPlus from './Sections/FamilyPlus/FamilyPlus';
const Parents = () => {

  (function(){
    const beeBanner = document.getElementById('beeBannerClosed');
    if(beeBanner){ setTimeout(()=>{beeBanner.click()},500) }
  }())

  return (
    <main>
      <Helmet>
        <title>Escribli - Families App</title>
        <meta name="description" content="Engage and improve your children writing skills with Escribli's Families App" />
        <meta name="keywords" content="writing activities for kids, fun writing activities for kids, Parents app, fun writing for kids" />
      </Helmet>
      <Hero />
      <Journey />
      <Interactive />
      {/* <Haunt /> */}
      
      <WritingEngagement />
      {/* <FamilyPlus/> */}
      <ParentCard />
      {/* <Awards parents /> */}



    </main>
  );
}

export default Parents;