import React, { useState } from 'react';
import styles from './Assignments.module.scss'
import science from '../../../Assets/Boomers/science_lab.png'
import { Slide, Slider, CarouselProvider } from 'pure-react-carousel';
import CarouselBtn from '../../Structural/CarouselBtn/CarouselBtn';
import Assignments1 from './../../../Assets/Tools/Assignments1.png'
import Assignments2 from './../../../Assets/Tools/Assignments2.png'
import {useReferralLink} from './../../../Hooks/useReferralLink'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Assignments = () => {
    const [slide, setSlide] = useState(true)
    return (
        <article className={styles.assignmentsContainer}>
            <div className={styles.assignmentTool}>
                <h2>The writing tool for every subject!</h2>
                <p>Create writing activities for any subject throughout the year.</p>
                <div className={styles.imgWrapper}>
                    <LazyLoadImage src={science} alt='boomer' effect="blur"/>
                </div>
                <a  href={useReferralLink()}>Start free trial</a>
            </div>

            <div className={styles.flexibleTool}>
                <h3>Flexible tool that facilitates the creation of a limitless number of writing activities in any subject</h3>
                <div className={styles.listCarousel}>

                    <div className={styles.list}>
                        {slide ?
                            (
                                <ul className={styles.longList}>
                                    <li>Teachers can monitor students’ writing progress in real-time </li>
                                    <li>Activities can have multiple sections that can be pre-scheduled and allow students to own pace</li>
                                    <li>Students can work Independently or collaboratively side-by-side (virtually!)</li>
                                    <li>Documents, links and images can be uploaded for students access </li>
                                    <li>Helpful word-bank tool to reinforce students’ vocabulary</li>

                                </ul>
                            ) : (
                                <ul className={styles.shortList}>
                                    <li>Suitable for any subject </li>
                                    <li>Schedule sections in advance</li>
                                </ul>
                            )
                        }

                        <a  href={useReferralLink()}>Start free trial</a>
                    </div>
                    <div className={styles.carouselProvider}>
                        <CarouselProvider
                            naturalSlideWidth={730}
                            naturalSlideHeight={420}
                            totalSlides={2}
                            infinite
                            dragEnabled={false}
                            touchEnabled={false}


                        >
                            <div className={styles.centerCarousel}>

                                <Slider className={styles.slider}>
                                    <Slide index={0}>
                                        <LazyLoadImage src={Assignments1} alt='' effect="blur"/>
                                    </Slide>

                                    <Slide index={1}>
                                        <LazyLoadImage src={Assignments2} alt='' effect="blur"/>
                                    </Slide>


                                </Slider>

                                <div className={styles.buttons}>

                                    <div className={styles.correctClick} onClick={() => setSlide(!slide)}>
                                        <CarouselBtn last white />
                                    </div>
                                    {slide ? <p>Multiple sections</p> : <p>Customizable</p>}
                                    <div className={styles.correctClick} onClick={() => setSlide(!slide)}>
                                        <CarouselBtn next />
                                    </div>
                                </div>
                            </div>


                        </CarouselProvider>
                    </div>
                </div>
            </div>

        </article>
    );
}

export default Assignments;