import React from 'react';
import learningPhoto from '../../../Assets/HomePage/learning.png'
import consistencyPhoto from '../../../Assets/HomePage/consistency.png'
import styles from './Consistency.module.scss'
import { useReferralLink } from '../../../Hooks/useReferralLink'
import LazyLoad from 'react-lazyload';

const Consistency = () => {
    return (
        <LazyLoad height={400}>
            <section className={styles.consistency}>
                <div>
                    <img src={learningPhoto} alt='' />
                    <img className={styles.mobilePhoto} src={consistencyPhoto} alt=''></img>
                </div>
                <div>
                    <div>
                        <h2>Consistency is the key to improvement</h2>
                        <p>With Escribli's personalized student writing improvement model, students are learning to write and writing to learn every day and in every subject.</p>
                        <a href={useReferralLink()} >Start free trial</a>
                    </div>
                    <div>
                        <img className={styles.consistencyPhoto} src={consistencyPhoto} alt=''></img>
                    </div>
                </div>
            </section>
        </LazyLoad>
    );
}

export default Consistency;