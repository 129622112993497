import React from 'react'

import styles from './WinnersHero.module.scss'
import winnerText from '../../../../Assets/BeeWinners/winner_main_text.png'
import winnerMainBlock from '../../../../Assets/BeeWinners/winner_main.png'
import theChampion from '../../../../Assets/BeeWinners/theChampion.png'
import boomerWinner from '../../../../Assets/BeeWinners/boomer_winner.png'
import boomerMiddle from '../../../../Assets/BeeWinners/boomer_middle.png'
import boomerFinal from '../../../../Assets/BeeWinners/boomer_final.png'


const WinnersHero = () => {

  return (
    <div className={styles.container}>

        <section className={styles.inner}>
            <div className={styles.left}>
                <img className={`${styles.mainText} ${styles.init}`} src={winnerText} alt="winnerText"/>
                <img className={`${styles.mainText} ${styles.res}`} src={winnerMainBlock} alt="winnerText"/>

                <img className={`${styles.boomerWinner} ${styles.res}`} src={theChampion} alt="winner" />
                <div className={styles.boomers}>
                    <p className={styles.winnersTextRes}>And congratulations to the Finals story chapter winners: </p>
                    <img className={styles.boomerChapterWinner} src={boomerMiddle} alt="middle chapter winner" />
                    <img className={styles.boomerChapterWinner} src={boomerFinal} alt="final chapter winner" />
                </div>
            </div>
            <div className={styles.right}>
                <img className={styles.boomerWinner} src={theChampion} alt="winner" />
            </div>
        </section>
    </div>
  )

}

export default WinnersHero;