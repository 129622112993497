import React, { useState } from 'react';
import styles from './HomePageNew.module.scss'
// COMPONENTS
import Hero from './Sections/Hero';
import Tools from './Sections/Tools';
import Pieces from './Sections/Pieces';
import Features from './Sections/Features';
import Teachers from './Sections/Teachers'
import Map from './Sections/Map'
import HeroMobile from './Sections/HeroMobile'
import VideoPlayerModal from '../Structural/Modals/VideoPlayerModal'

//SEO
import { Helmet } from "react-helmet";
import { useWindowSize } from '../../Hooks/UseWindowSize';
import Benefits from './Sections/Benefits';
import OurTools from './Sections/OurTools';
import Reasons from './Sections/Reasons';

const HomePage = ({  }) => {

    const size = useWindowSize()
    const [ videoOverlay, setVideoOverlay ] = useState({vis:false, url:''})
    const setShowVideo = (input) => {
        setVideoOverlay({...videoOverlay, vis:input})
    }

    return (
        <div className={styles.HomePageWrapper}>
            <Helmet>

                <title>Improve Student Writing | Teachers Parents | Escribli</title>
                <meta name="description" content="Escribli is an online writing platform that provides teachers with powerful tools to inspire and engage their students to become better writers." />
                <meta name="keywords" content="Escribli tools, writing tool, writing tools, distance learning, online writing tools" />

            </Helmet>
            <div className={styles.centerContainerNoAir}>
                {size.width >= 950 ? <Hero /> : <HeroMobile />}
                <div className={styles.HomePageContainer}>
                    <Benefits />
                    <OurTools />
                </div>
            </div>

            {size.width >= 750 ?
                // <div className={styles.blueWave}>
                    <div className={styles.centerContainer}>
                        <div className={styles.HomePageContainer}>
                            <Features />
                            <Teachers />
                        </div>
                    </div>
                // </div>
                :
                <div className={styles.centerContainer}>
                    <div className={styles.HomePageContainer}>
                        <Features mobile />
                        <Teachers />
                    </div>
                </div>
            }

            {size.width >= 950 ?
                <div className={styles.downGreyWave}>
                    <div className={styles.centerContainer}>
                        <div className={styles.HomePageContainer}>
                            {/* <Map /> */}
                            {/* <Reasons /> */}
                            {/* <Trust /> */}
                        </div>
                    </div>
                </div>
                :
                <div className={styles.centerContainer}>
                    <div className={styles.HomePageContainer}>
                        {/* <Map mobile /> */}
                            {size.width >= 780 && <Reasons />}
                        {/* <Trust mobile /> */}
                    </div>
                </div>
            }

        {videoOverlay.vis && <VideoPlayerModal 
                        setShowVideo={setShowVideo} 
                        url={videoOverlay.url} 
                        height='428px'
                        width='760px' />
        }
        </div>
    );
}

export default HomePage;

