
export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      '.__PrivateStripeElement': {
        border:'1px solid chartreuse !important'
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};


export const addAnimation = (ref, animation, timeout) => {

  ref.current.classList.add(animation);

  setTimeout(()=>{
    ref.current.classList.remove(animation)
  },timeout)
  
}


export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}


export const toFixedString = (num, fixed) => {
  const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  
  return num.toString().match(re) ? num.toString().match(re)[0] : 0;
}