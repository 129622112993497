import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import { Nav, HomePage, HomePageOld, Footer, CookieModal } from './Components'
import './shared.scss';
import IntercomScript from './Components/Schools/IntercomScript';
import loadable from '@loadable/component'
import queryString from 'query-string'


import ReactGA from 'react-ga';
import BeeBanner from './Components/Structural/Banners/BeeBanner/BeeBanner';

import Store from './Components/Bookstore/Bookstore'
import { useSelector } from 'react-redux';

const CookiePolicy = loadable(() => import('./Components/CookiePolicy/CookiePolicy'));
const PrivacyPolicy = loadable(() => import('./Components/PrivacyPolicy/PrivacyPolicy'));
const Pricing = loadable(() => import('./Components/Pricing/Pricing'))
const Tools = loadable(() => import('./Components/Tools/Tools'))
const Schools = loadable(() => import('./Components/Schools/Schools'))
const Terms = loadable(() => import('./Components/Terms/Terms'))
const About = loadable(() => import('./Components/AboutUs/About'))
const WritingBee = loadable(() => import('./Components/WritingBee2021/WritingBee2021'))
const StoriesLanding = loadable(() => import('./Components/LandingPages/StoriesLanding/StoriesLanding'))
const Parents = loadable(() => import('./Components/Parents/Parents'))
const ImpersonateStudent = loadable(() => import('./Components/Auth/Impersonate/ImpersonateStudent'))
const Impersonate = loadable(() => import('./Components/Auth/Impersonate/Impersonate'))
const ConfirmEmail = loadable(() => import('./Components/Auth/ConfirmEmail/ConfirmEmail'))
const ResetPassword = loadable(() => import('./Components/Auth/ResetPassword/ResetPassword'))
const Logout = loadable(() => import('./Components/Auth/Logouts/Logout'))
const StudentLogout = loadable(() => import('./Components/Auth/Logouts/StudentLogout'))
const Payment = loadable(() => import('./Components/Auth/Payment/Payment'))
const Auth = loadable(() => import('./Components/Auth/Auth'))
const LinkLogin = loadable(() => import('./Components/Auth/LinkLogin/LinkLogin'))
// const FaithJournals = loadable(() => import('./Components/FaithJournals/FaithJournals'))
// const Store = loadable(() => import('./Components/Bookstore/Bookstore'))


function App() {
  //GA conf
  const [initialLoad, setInitialLoad] = useState(true)
  const history = useHistory()
  const userDetails = useSelector(state => state.storeData.userDetails);

  // To assure quality of stats only hit when first loaded

  useEffect(() => {
    history.listen(location => ReactGA.pageview(location.pathname));
  }, [history])

  if (initialLoad) {
    ReactGA.pageview(history.location.pathname);
    setInitialLoad(false)
  }

  // session url params

  const location = useLocation()
  const session = window.sessionStorage;

  const {r,rc} = queryString.parse(location.search);

  if(r) session.setItem('r',r)
  if(rc) session.setItem('rc',rc)

  //Recover this saved data on auth service


  const renderBanner = () => {
    return (
      <Switch>  {/*Banners Switch*/}
        <Route exact path='/' component={() => <BeeBanner />} />
        <Route exact path='/faithjournals' component={null} />
        <Route path='/stories' component={null} />
        <Route path='/auth' component={null} />
        <Route path='/store' component={null} />
        <Route path='/thewritingbee' component={null} />
        <Route path='/:section' component={() => <BeeBanner />} />
      </Switch>
    )
  }


  return (
    <div className="App">
      <Switch> {/* Navbar Switch*/}
        <Route exact path='/' component={() => <Nav homepage />} />
        <Route path='/auth/payment' component={() => <Nav clear />} />
        <Route path='/stories' component={() => <Nav clear noLogin />} />
        <Route path='/auth' component={null} />
        <Route path='/thewritingbee' component={() => <Nav bee />} />
        <Route path='/families' component={() => <Nav families/>} />
        <Route exact path='/faithjournals' component={null} />
        <Route path='/store/:section?' component={()=> <Nav store clear={userDetails} storeLoggedIn={userDetails} />} />
        <Route path='/:section' component={() => <Nav />} />
      </Switch>

      {/* { renderBanner()} */}

      <div className="mainSwitchDiv" div  >
        <Switch> {/* Main Body Switch */}


          <Route exact path="/" component={() => <HomePage  />} />
          <Route exact path="/oldHome" component={() => <HomePageOld  />} />
          <Route path="/about" component={About} />
          <Route path="/pricing" component={() => <Pricing /> } />
          <Route exact path="/tools/stories" component={() => <Tools section='stories' />} />
          <Route exact path="/tools/assignments" component={() => <Tools section='assignments' />} />
          <Route exact path="/tools/journals" component={() => <Tools section='journals' />} />
          <Route path="/tools" component={Tools} />
          <Route path="/schools" component={Schools} />
          <Route path="/thewritingbee" component={WritingBee} />
          <Route path="/stories" component={StoriesLanding} />
          <Route path="/home/terms" component={Terms} />
          <Route path="/thewritingbee" component={WritingBee} />
          <Route path="/families" component={Parents} />
          <Route path="/home/privacy-policy" component={PrivacyPolicy} />
          <Route path="/home/cookie-policy" component={CookiePolicy} />
          <Route path="/store/:section?" component={() => <Store /> } />

          <Route path='/auth/payment' component={Payment} />
          <Route path='/auth/forgotPassword' component={() => <Auth forgot />} />
          <Route path="/auth/confirm_email" component={ConfirmEmail} />
          <Route path="/auth/reset_password" component={ResetPassword} />
          <Route path='/auth/logout' component={Logout} />
          <Route path='/auth/student/setPassword' component={() => <Auth setPassword />} />
          <Route path="/auth/student/go" component={LinkLogin} />
          <Route path='/auth/student/logout' component={StudentLogout} />
          <Route path="/auth/student/login_as" component={ImpersonateStudent} />
          <Route path="/auth/teacher/login_as" component={Impersonate} />
          <Route path="/auth/:account?/:authType?" component={Auth} />


          {/*// FAITH JOURNALS REDIRECT */}
          <Route exact path="/faithJournals" component={() => {
            window.location.href = "http://faith-journals.com/"; 
            return null;
          }} /> 

          {/* 301 Redirects || Order is important || old links that has been changed*/}
          <Redirect exact from='/journals' to='/tools/journals' />
          <Redirect exact from='/pricing-plus' to='/pricing' />
          <Redirect exact from='/parents' to='/families' />
          <Redirect exact from='/parents-app' to='/families' />

        </Switch>
      </div>

      {/* Always displayed*/}
      <IntercomScript /> {/* Doesnt work on local*/}
      <CookieModal />

      <Switch> {/* Footer switch*/}
        <Route exact path='/' component={() => <Footer />} />
        <Route path='/premium' component={() => null} />
        <Route path='/auth' component={null} />
        <Route path='/store' component={null} />
        <Route path='/thewritingbee' component={() => <Footer bee />} />
        <Route exact path='/faithjournals' component={() => <Footer faithJournals />} />
        <Route path='/:section' component={() => <Footer />} />
      </Switch>

    </div>
  );
}

export default App;
