import React from 'react';
import Helmet from 'react-helmet'
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
    return (
        <div class="containerNoFlex terms-privacy">
            <Helmet>
                <title>Privacy Policy | Escribli Classroom Writing Tools</title>
                <meta name="description" content="Escribli Privacy Policy helps you understand how we use your teacher and student data, how we protect it and outline your rights and responsibilities." />
                <meta name="keywords" content="Escribli terms, Escribli about us, about Escribli" />
            </Helmet>
            <h1 class="headingHero">Escribli Privacy &amp; Security Policies</h1>
            <h3 class="headingSmall bodyTextColour"><strong>Escribli is GDPR, COPPA &amp; FERPA compliant and follows a rigorous standard for security and privacy of our user’s data. The following is a quick summary of how we meet these privacy and security regulations.</strong></h3>
            <p><strong>v1<br />Effective August 1, 2021</strong></p>

            <p><span class="fontWeight-bold">Welcome to Escribli’s</span> Privacy and Security Policies. We have made a big effort to make this easy to read and understand, so it will be worth your time to review, to understand how we use your data, protect it, and your own rights and responsibilities.</p>
            <p>We are vocal advocates for safeguarding student data and privacy and want to make sure that parents, educators, and schools know this, which is why we took the Student Privacy Pledge, put forth by the Future of Privacy Forum (FPF) and the Software &amp; Information Industry Association (SIIA). We are also KidSafe Certified and follow the EU’s GDPR and CCPA..</p>

            <h2 class="heading">Data Controller</h2>
            <p>Escribli Inc., established at 170 Gore Street, #206, MA 02142 in Cambridge (United States), is the data controller as described in this privacy policy.</p>

            <h3 class="headingSmall bodyTextColour" style={{ textDecoration: 'underline' }}>Contact Details:</h3>
            <p>Address: 170 Gore Street, #206, Cambridge, MA 02142 (USA)</p>
            <p>E-mail: privacy@escribli.com</p>
            <p>Telephone: +1 (978) 578-0372</p>

            <h2 class="heading">Summary</h2>

            <ul class="PrivacyList">
                <p>If you are a user of Escribli, you fall under one of two user types.</p>
                <li><span class="fontWeight-bold">Educator Administrator</span> - An educator administrator is a teacher, school admin, district admin, organizational leader or parent responsible for supplying, creating and managing the student's information on the site.</li>
                <li><span class="fontWeight-bold">Student</span> - The student is the user who engages in the writing activity on the Escribli platform and is added to the platform by an appropriately responsible Educator Administrator.</li>
            </ul>
            <br />
            <p>For the purposes of this document, the term, ‘Users’ refers to both the Educator Administrator and Student roles.</p>

            <table class="privacyTable">
                <thead>
                    <tr>
                        <th>Summary</th>
                        <th>More Details</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td class="privacyTable-summary"><p>If you sign up to use this website’s services, we may retain personal information about you, such as your name, home address, email, and phone number.</p></td>
                        <td class="privacyTable-moreInfo">
                            <a class="scrollTo" href="#DataCollection" data-offset="100">What Data We Process and Why</a>
                        </td>
                    </tr>

                    <tr>
                        <td class="privacyTable-summary">
                            <p>Educator Administrators can opt into this website by signing up to use our product by giving us your personal information that we use to provide you with our personalized services online.</p>
                            <p>Students are opted-in via their Educator Administrator (parent, guardian or school) who acts as the data controller on their behalf.</p>
                        </td>
                        <td class="privacyTable-moreInfo">
                            <a class="scrollTo" href="#DataCollection" data-offset="100">What Data We Process and Why</a>
                        </td>
                    </tr>

                    <tr>
                        <td class="privacyTable-summary"><p>Aggregated data is analyzed to inform internal decision making. Personal information is used to deliver services. You opt-in to this on the sign-up form.</p></td>
                        <td class="privacyTable-moreInfo">
                            <a class="scrollTo" href="#DataUse" data-offset="100">How We Use the Data</a>
                        </td>
                    </tr>

                    <tr>
                        {/* @todo update_contact_mail */}
                        <td class="privacyTable-summary"><p>If you have any questions or comments about this privacy policy, or the data collected, or wish to modify or remove your data, email <a href="mailto:privacy@esctibli.com">privacy@esctibli.com</a></p></td>
                        <td class="privacyTable-moreInfo">
                            <ul class="">
                                <li><a class="scrollTo" href="#DataUse" data-offset="100">How We Use the Data</a></li>
                                <li><a class="scrollTo" href="#DataDuration" data-offset="100">How Long Do We Keep Data and Why</a></li>
                                <li><a class="scrollTo" href="#DataProtection" data-offset="100">How We Protect Your Data</a></li>
                                <li><a class="scrollTo" href="#AdminResponsability" data-offset="100">Your Responsibilities and Rights as an Educator Administrator</a></li>
                                <li><a class="scrollTo" href="#StudentResponsability" data-offset="100">Your Responsibilities and Rights as a Student</a></li>
                            </ul>
                        </td>
                    </tr>

                    <tr>
                        <td class="privacyTable-summary"><p>Your personal information is retained on our servers until such a time as you request it to be removed or we determine the account is inactive for a prolonged time, both follow the retention requirements that the law requires.</p></td>
                        <td class="privacyTable-moreInfo">
                            <a class="scrollTo" href="#DataUse" data-offset="100">How We Use the Data</a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <dl class="privacyDefinitionList">
                <dt>Email</dt>
                <dd class="no-line"><p>We use this data in order to create accounts and to communicate with you from time to time in order to carry our necessary application functions.</p></dd>

                <dt>Name</dt>
                <dd><p>We use this data in order to verify identity and to communicate with you from time to time in order to carry our necessary application functions.</p></dd>

                <dt>Mobile Phone Number</dt>
                <dd><p>As an Educator Administrator, you may optionally also supply us with your mobile phone number. This enables us to better identify who you are and to send you text messages which may include password resets codes.</p></dd>

                <dt>School</dt>
                <dd><p>We use this data in order to match you to the right school or organization in our platform, a necessary application function.</p></dd>

                <dt>School Details</dt>
                <dd><p>This includes school name, location, subjects taught and grades. We use this data in order to match our users with the right school, help give the user a more personalized experience in our platform and to better improve our platform according to our user demographics.</p></dd>

                <dt>Student Details</dt>
                <dd><p>Educator Administrator creates student accounts on Escribli so that the student may participate in writing activities. We receive this information so that Educator Administrators may properly identify which student is which for grading purposes. The Educator Administrator, in this case, is the ‘Data Controller’ of the Student’s data.</p></dd>

                <dt>Parent or Gaurdian Details</dt>
                <dd><p>Educator Administrators can add parent or guardian details to student accounts on Escribli so that the parent may co-manage the students account, retrieve passwords, and review student work. This user will also become a Educator Administrator of that student. This information is received from the originating Educator Administrator and is used to verify a certain party has been approved and deemed responsible for that student by that Educator Administrator.</p></dd>

                <dt>Browser Cookies, User Behavior Tracking, and Third-party tools.</dt>
                <dd>
                    <p>Cookies are used to securely identify you and maintain your logged-in session. We do not use those cookies for any other purpose or for tracking you outside of the Escribli website. </p>
                    <p>We also use third-party tools like Google Analytics, FullStory, AppCues, Facebook Pixel that utilize cookies and other tools that track your progress through the site as well as user behaviors. The ultimate goal of these tools is to supply us with information that helps us supply you, the user, with a better and ever-improving experience.</p>
                    <p>You can control whether or not cookies you accept cookies through your own browser settings. If you choose to disable cookies, it will limit the use of certain features or functions of our service and reduce the ability of us to deliver a great user experience.</p>
                </dd>

                <dt>Social Media Links and Widgets</dt>
                <dd><p>Our website includes social media features, such as the Facebook “Like” button, the “Share This” widget or interactive mini-programs that run on our site. These features may collect your IP address, a record of which pages you visit on our site, and set a cookie to enable the feature to function properly. These features and widgets are sometimes hosted by a third party. Your interactions with these features are governed by the Privacy Pledge of the company providing the specific feature.</p></dd>

                <dt>SignIn with Google</dt>
                <dd><p>When signing in with your Google account, we only save your email, first and last name as the basic information for your profile.</p></dd>

                <dt>Google Drive</dt>
                <dd><p>When creating new assignments for their students, Educators have the option to attach documents from their own Google Drive account. Those documents will only be visible to students who work on that specific assignment. Documents can also be removed at any time from an assignment, which will only remove our reference to that file from Google Drive and will not touch the file itself.</p></dd>
            </dl>

            <section id="DataCollection">
                <h2 class="heading">What Data We Proccess and Why</h2>
                <p>When an institution’s administrator or an instructor, parent or guardian (Educator Administrator) creates an account with us, we ask for their personal data such as name, email, phone, and job title, so that we can correctly administer their account and provide them with the best customer service.</p>
                <p>The type of information we request is connected to a specific purpose, namely to:</p>
                <ul class="privacyList">
                    <li>Verify your identity and connection with a specific institution or company</li>
                    <li>Download content from our website, such as demos, teacher resources, lesson plans whitepapers or webcasts</li>
                    <li>Send important messages about our service, such as any changes to functionality</li>
                    <li>Send system emails, including account setup and password retrieval information</li>
                    <li>Provide additional product and service support as needed.</li>
                </ul>
            </section>

            {/* @todo update_contact_mail */}
            <section id="DataUse">
                <h2 class="heading">How We Use the Data</h2>
                <p>We take every possible step to make sure that the personal data in our systems is relevant for its intended use, accurate, complete, and current. If you wish to change, modify or remove your data from our system, please email us at <a href="mailto:privacy@esctibli.com">privacy@esctibli.com</a> and request that we remove your account. Please specify whether or not you want your personal account removed, or your entire institution and all data with it if you are an Educator Administrator.</p>
                <p>Escribli informs you that the only non-educational purpose for processing your data would be the printing and publishing of all the contents you have created on a book on demand. You will retain 100% ownership of your intellectual property rights, i.e. the writings. Escribli does not ever assert or claim copyright ownership of any works submitted to or through our service. Your property is YOUR property. We do not, and will not, use your intellectual property for any purpose other than to deliver, support, and develop our services, which are designed to protect and strengthen your copyright. For those activities that involve publishing there is a shared copyright among the participants including Escribli. For these published writings you can request anonymization of students work but we are unable to delete the work as this would invalidate the writings of other students that have participated.</p>
                <p>Additionally, upon lawful request, Escribli will provide information about any personal data we process to governing authorities.</p>
                <p>Finally, if Escribli is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our website, of any change in ownership, uses of your personal data, and choices you may have regarding your personal data.</p>
            </section>

            {/* @todo update_contact_mail */}
            <section id="DataMinors">
                <h2 class="heading">Processing of data relating to minors</h2>
                <p>Our website and/or service has no intention of collecting information about website visitors under the age of 13, unless they have parental or guardian permission. However, we cannot verify whether a visitor is over 13 years of age. We encourage parents to be involved in their children’s online activities in order to prevent the processing of information about children without parental consent. If you believe that we have processed personal information about a minor without that consent, please contact us at privacy@esctibli.com and we will delete that information.</p>
            </section>

            <section id="ThirdData">
                <h2 class="heading">Processing of third party data</h2>
                <p>As a rule, we only process data provided by the data subject. If you provide us with the data of a third person, you must have informed him in advance and asked his consent. Otherwise, you relieve us of any responsibility for failure to comply with this obligation.</p>
            </section>

            <section id="DecisionMaking">
                <h2 class="heading">Automated decision-making</h2>
                <p>Escribli does not take decisions on the basis of automated processing operations on matters that could have (significant) consequences for individuals. These are decisions that are made by computer programs or systems, without a human being (e.g. an employee of Escribli) being involved.</p>
            </section>

            <section id="TransferPersonal">
                <h2 class="heading">Transfer Of Personal Data To Third Parties</h2>
                <p>Escribli will only share your personal data with third parties if this is necessary for the performance of the agreement and to comply with any legal obligation. We enter into a processing agreement with companies that process your data on our behalf in order to ensure the same level of security and confidentiality of your data. Escribli remains responsible for these processing activities.</p>
                <p>In the preceding twelve (12) months, Escribli has disclosed the following categories of personal data for a business purpose:</p>
                <ul class="privacyList">
                    <li>Identifiers</li>
                    <li>The categories of personal information listed in the California Customer Records Statute</li>
                    <li>Protected classification characteristics under California or federal law.</li>
                    <li>Commercial information.</li>
                    <li>Internet or other similar network activity.</li>
                    <li>Audio, electronic, visual, thermal, olfactory, or similar information.</li>
                    <li>Professional or employment-related information.</li>
                    <li>Non-public education information.</li>

                </ul>
                <p>We disclose your personal data for a business purpose to the following categories of third parties:</p>
                <ul class="privacyList">
                    <li>Our clients or their agents for which you have engaged in a business contract.</li>
                    <li>Service providers.</li>
                    <li>Third parties to whom you or your agents authorize us to disclose your personal information in connection with our Services.</li>
                </ul>
                <p><strong>In the preceding twelve (12) months, Escribli has not sold any personal information.</strong></p>
            </section>

            <section id="Cookies">
                <h2 class="heading">Do We Use Cookies</h2>
                <p>If we use other types of cookies than those that are necessary, you can consult the cookie policy in the appropriate link <a href="/home/cookie-policy">www.escribli.com/home/cookie-policy</a> available when you open our website.</p>
            </section>


            {/* @todo update_contact_mail */}
            <section id="DataDuration">
                <h2 class="heading">How Long Do We Keep Data and Why</h2>
                <p>Escribli will retain personal data we process on behalf of our Educator Administrators for as long as needed to provide services to our users. We retain your information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                <p>You may stop using Escribli at any time. You do not need to specifically inform Escribli when you stop using the services. However, at any time you may also delete your account. In order to do so, you must email us at <a href="mailto:privacy@esctibli.com">privacy@esctibli.com</a> and request that we remove your account. Please specify whether or not you want your personal account removed, or your entire institution and all data with it if you are an Educator Administrator.</p>
            </section>

            <section id="DataProtection">
                <h2 class="heading">How We Protect Your Data</h2>
                <p>We endeavor to keep your personal data safe and secure at all times. Our data is stored in the EU or USA with robust physical, digital, and procedural safeguards in place to protect your personal data, including the use of SSL encryption, redundant servers and data centers, and sophisticated perimeter security. We continuously audit for security vulnerabilities and make software patching a priority.</p>
                <p>Escribli is committed to handling your personal information with high standards of information security. In order to protect and safeguard the personal data provided to us, we have implemented and use appropriate business systems and procedures. For example, your personal information is transmitted to us through a secure server protocol, which encrypts all your personal information. The encryption method used is the industry standard "Transport Layer Security (TLS)" technology. However, due to submitting information over the Internet not being 100% secure, Escribli does not guaranteed 100% security of personal information submitted.</p>
                <p>Furthermore, we have implemented and use security procedures and technical and physical restrictions for accessing and using personal information. Only authorised employees are permitted to access personal information for performing their duties in respect of our services. Our server and network are protected by firewalls against unauthorised access and we have intrusion detection systems that monitor and detect unauthorised (attempts to) access to or misuse of our servers. Additional we undergo extensive penetration testing annually to continually improve the security on our systems.</p>
                <p>At Escribli we have also ensured that all employees have sufficient information security training and follow our strict media handling policy which helps maintain, enforce and communicate best practices surrounding media handling throughout the business.</p>
            </section>

            <section id="AdminResponsability">
                <h2 class="heading">Your Responsibilities and Rights as an Educator Administrator</h2>
                <h3 class="headingSmall bodyTextColour">Your Responsibilities</h3>
                <p>In terms of the GDPR regulations, Escribli is a ‘Data Processor’ and the Educator Administrators are the ‘Data Controllers’. In other words, Escribli takes the information teachers, schools, districts or even parents give us, and process that for them. In the case of Escribli, students are added to the platform via an Educator Administrator. This person is an adult representing that student and they attest they have the legal right to do so. Students cannot sign-up to Escribli without such a representing administrating adult. It is the responsibility of that representative to make certain they have received all appropriate authorization from the student’s guardians according to their local jurisdiction.</p>
                <p>In terms of security, you are also responsible for keeping your account secure. As an Educator Administrator, you have access to a lot of student data. We strongly recommend having a strong password and changing it often. Be careful about who you add, approve and make an Educator Administrator. We encourage you to verify all requests to join your school. We also recommend using VPN services when accessing our site in a cafe or unencrypted network. We’ll work hard to keep your data safe. However, you also need to keep it safe as well.</p>

                {/* @todo update_contact_mail */}
                <h3 class="headingSmall bodyTextColour">Your Rights</h3>
                <p>Escribli gives you the ability to opt out of email communication, with the exception of system emails (e.g. password resets). Additionally, an individual who seeks access or would like to correct, amend, rectify or erase data, should direct their query to Escribli and will have your request completed within 30 calendar days If you are an Educator Administrator who has questions or would like to make changes to your personal data, please contact us at <a href="mailto:privacy@esctibli.com">privacy@esctibli.com</a></p>
            </section>

            <section id="StudentResponsability">
                <h2 class="heading">Your Responsibilities and Rights as a Student</h2>
                <h3 class="headingSmall bodyTextColour">Your Responsibilities</h3>
                <ul class="privacyList">
                    <li>Having a really strange and good password. Don’t pick anything someone can guess.</li>
                    <li>Don’t write your password down, memorize it. Make it a game.</li>
                    <li>Don’t tell anyone, unless they are your parent or guardian.</li>
                    <li>Change your password often.</li>
                    <li>Keep your parents informed about what you’re doing on Escribli.</li>
                </ul>

                {/* @todo update_contact_mail */}
                <h3 class="headingSmall bodyTextColour">Your Rights</h3>
                <p>If you want to get access to your account, or would like to correct, add to, fix or erase information, please contact your Educator Administrator (that’s your teacher) or email us at <a href="mailto:privacy@esctibli.com">privacy@esctibli.com</a> and we’ll reach out to them for you.</p>
            </section>

            <section id="NonDiscrimination">
                <h2 class="heading">Non-Discrimination</h2>
                <p>We will not discriminate against you for exercising any of your CCPA or GDPR rights. Unless permitted by the CCPA or GDPR, we will not:</p>
                <ul class="privacyList">
                    <li>Deny you goods or services.</li>
                    <li>Charge you different prices or rates for goods or services, including through grantingdiscounts or other benefits, or imposing penalties.</li>
                    <li>Provide you a different level or quality of goods or services.</li>
                    <li>Suggest that you may receive a different price or rate for goods or services or adifferent level or quality of goods or services.</li>
                </ul>
            </section>

            <section id="PrivacyRegultaions">
                <h2 class="heading">Other Privacy Regultaions</h2>
                <p>Escribli requires users/educational institutions who are subject to FERPA law and who are using the Escribli software to comply with this regulation as is set forth in the Terms of Use.</p>
            </section>

            {/* @todo update_contact_mail */}
            <h2 class="heading">ADDITIONAL INFORMATION</h2>
            <p>Your trust is important to us. That is why we are always available to talk with you at any time and answer any questions concerning how your data is processed. If you have any questions that could not be answered by this privacy notice or if you wish to receive more in depth information about any topic within it, please contact us via email: <a href="mailto:privacy@escribli.com">privacy@escribli.com</a></p>

            <h2 class="heading">REVIEW OF THIS NOTICE</h2>
            <p>We keep this notice under regular review. This notice was last updated on 08/01/2021</p>

            <h2 class="heading">AMENDMENTS</h2>
            <p>We reserve the right to update this privacy notice at any time, and we will provide you with a new privacy notice when we make any substantial updates. We may also notify you in other ways from time to time about the processing of your personal information.</p>
        </div>
    );
}

export default PrivacyPolicy;