import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Spreadshirt.module.scss';

import spinner from '../../../../Assets/Icons/spinner-sml--fast.svg';

const Spreadshirt = ( ) => {

  
  let js = document.createElement("script")
  js.type= "text/javascript"
  js.src="https://shop.spreadshirt.com/shopfiles/shopclient/shopclient.nocache.js"
  document.body.appendChild(js)

  return (
    <div id="Shop-Component" className={styles.container}>
        <Link to={'/store'} className={styles.back}>{"< Back to search"}</Link>

      <div id="BWShop" className={styles.inner}>
        <div className={styles.loading}>
          <h4>
            Loading Escribli products...
          </h4>
          <img src={spinner} alt=""/>
        </div>
      </div>
    </div>
  )

}

export default Spreadshirt;