import React from 'react';
import styles from './Map.module.scss'
import World from "../../../Assets/HomePage/world.png";
import LazyLoad from 'react-lazyload';

const Map = ({ mobile }) => {
    if (!mobile) {
        return (
            <LazyLoad height={100}>
                <div className={styles.mapContainer}>
                    <h2>Escribli has a presence in over 100 countries!</h2>
                    <div>
                        <img src={World} alt='img of the world'></img>
                    </div>
                </div>
            </LazyLoad>
        );
    } else {
        return (
            <LazyLoad height={100}>
                <div className={styles.mobileMapContainer}>
                    <div className={styles.mobileBg}>
                        <h2>Escribli has a presence in <br /><span>+ 150 </span><br />countries!</h2>
                    </div>
                </div>
            </LazyLoad>
        )
    }
}

export default Map;