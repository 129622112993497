import React, { useState } from 'react';
import styles from './PieceMobileCard.module.scss'
const PieceMobileCard = ({ term, text, img }) => {
    const [show, setShow] = useState(false)

    return (


        <div className={show === true ? `${styles.piece} ${styles.active}` : styles.piece} onClick={() => setShow(!show)}>
            <h3>{term}</h3>
            <div className={show === true ? `${styles.plusToMinus} ${styles.minus}` : styles.plusToMinus} />
            <p>{text}</p>
            <div>
                <img src={img} alt='example' />
            </div>
        </div>
    );
}

export default PieceMobileCard;