import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';
import App from './App';
import { store } from './Redux/storeConfig';
import ScrollToTop from './Hooks/ScrollToTop';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-15285312-1')

const env = window.location.host === 'localhost:3011' ? 'dev' : window.location.host === 'sand.escribli.com' ? 'stage' : 'prod';
const stripeKey = (env === 'prod' ? 'pk_live_51JZGxAH8mRTYLOz5s91L7tW1B9NnidWHl3TnxFUcyByAVqlBu22e7IrMZvqxndtPUf4sRsTvaGA2MAwJJV29m9fr00NWljFwGC' : 'pk_test_51JZGxAH8mRTYLOz5uAiJwW9c24BFhL3RrZmfTzGvDMvQHcby1F9xNPr352ywyFBcTFIxO2S55ihHM1cCM1WpzbBn00ZyCCQrdb')
const stripePromise = loadStripe(stripeKey);

ReactDOM.render(
  <Provider store={store}> 
    <BrowserRouter>
    <ScrollToTop/>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </BrowserRouter>
  </Provider>
  , document.getElementById('root')
);

//Redux used for pricing/auth

// SitemapGenerator.js has to be ran everytime you add a Route