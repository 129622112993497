import React from 'react';

const storiesIcon = ({ color }) => {
    return (
        <svg width="25" height="21" viewBox="0 0 25 21" fill="red" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7013 17.9669V0L22.0236 0.057475C19.3059 0.284742 16.6403 0.941975 14.124 2.00548L12.9312 2.50652V20.4023L13.7998 20.037C16.4037 18.9411 19.1606 18.263 21.9712 18.027L22.7013 17.9669Z" fill={color} />
            <path d="M25.0001 2.58594H23.6461V18.1743C23.6469 18.4065 23.4664 18.6005 23.2317 18.6196L22.0758 18.7132C21.7422 18.7403 21.4094 18.774 21.0779 18.8143C20.9704 18.8271 20.8635 18.8441 20.7566 18.8589C20.5331 18.8882 20.3101 18.918 20.0877 18.9528C19.9582 18.9732 19.8295 18.9972 19.7003 19.0196C19.5013 19.0539 19.3019 19.0881 19.1033 19.1268C18.9677 19.1535 18.8324 19.1835 18.6943 19.2125C18.5052 19.252 18.3161 19.2925 18.1283 19.3366C17.9875 19.3697 17.8471 19.4047 17.7072 19.4399C17.5245 19.4845 17.3426 19.5326 17.161 19.5826C17.0198 19.6209 16.8785 19.6613 16.7377 19.7022C16.5572 19.7548 16.3793 19.8083 16.201 19.8644C16.0615 19.909 15.9218 19.9536 15.7828 19.998C15.6042 20.0573 15.4266 20.1187 15.2496 20.1819C15.1143 20.2304 14.9788 20.2792 14.8404 20.3303C14.7785 20.3535 14.717 20.3783 14.6553 20.402H25.0001V2.58594Z" fill={color} />
            <path d="M2.97647 0.057475L2.29883 0V17.9682L3.08706 18.036C5.89583 18.2735 8.6504 18.9558 11.2499 20.0577L12.0689 20.4023V2.50652L10.8774 2.00592C8.36091 0.942195 5.69482 0.284742 2.97647 0.057475Z" fill={color} />
            <path d="M0 2.58594V20.402H10.3448C10.2893 20.3805 10.2338 20.3574 10.1779 20.337C10.0488 20.2885 9.91868 20.2422 9.78875 20.1954C9.60552 20.1297 9.42251 20.0658 9.23796 20.0047C9.1045 19.9601 8.9706 19.9168 8.83648 19.8746C8.65347 19.8153 8.4698 19.7594 8.2859 19.7072C8.15046 19.668 8.01524 19.6287 7.88002 19.5913C7.69459 19.5406 7.50872 19.4925 7.3224 19.4453C7.18718 19.411 7.05174 19.3766 6.91344 19.3446C6.72448 19.3001 6.53442 19.2592 6.34458 19.2189C6.20936 19.19 6.07392 19.1606 5.9387 19.1339C5.74204 19.0956 5.54449 19.0613 5.34695 19.0271C5.21745 19.0047 5.08796 18.9825 4.95824 18.9597C4.74021 18.9255 4.52153 18.8966 4.30284 18.8675C4.19228 18.8529 4.08261 18.8355 3.97227 18.8231C3.64149 18.7835 3.31071 18.7498 2.97992 18.722L1.7658 18.6204C1.53169 18.6006 1.35221 18.4069 1.35309 18.175V2.58594H0Z" fill={color} />
        </svg>
    );
}

export default storiesIcon;


