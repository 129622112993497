import React from 'react';

import styles from './OurTools.module.scss';
import boomer_book from '../../../Assets/HomePage/boomer_book.svg'
import boomers_lab from '../../../Assets/HomePage/boomers_lab.svg'
import boomers_laptop from '../../../Assets/HomePage/boomers_laptop.svg'
import book_story from '../../../Assets/BookCovers/story.png'
import book_journal from '../../../Assets/BookCovers/journal.png'
import icon_journals from '../../../Assets/HomePage/icon_journals.svg'
import icon_stories from '../../../Assets/HomePage/icon_stories.svg'
import icon_assignments from '../../../Assets/HomePage/icon_assignments.svg'
import { Link } from 'react-router-dom';

const OurTools = () => {

  return (
    <div className={styles.container}>

      <h1>Our Escribli Tools</h1>

      <div className={styles.cardsContainer}>
        <div className={styles.cards}>
          
          <div className={`${styles.card} ${styles.green}`}>
            <div className={styles.header}>
              <img src={icon_stories} alt="Stories"/>
              <h5>Stories</h5>
            </div>
            <p >Read a sample of one of our many story starts that will surely get all of your students excited to write.</p>
            {/* <div className={styles.ctaDiv}>
              <Link to={'/tools/stories'}> <div className={styles.learnMoreBtn}><span className={styles.full}>Learn more about Stories</span><span className={styles.res}>Learn more </span></div></Link>
            </div>
            <img className={styles.boomer} src={boomer_book} alt="boomer"/> */}
          </div>
          
          <div className={`${styles.card} ${styles.blue}`}>
            <div className={styles.header}>
              <img src={icon_assignments} alt="Assignments"/>
              <h5>Assignments</h5>
            </div>
            <p>Students can work on short or long-term writing assignments in any genre or subject.</p>
            {/* <div className={styles.ctaDiv}>
              <Link to={'/tools/assignments'}> <div className={styles.learnMoreBtn}><span className={styles.full}>Learn more about Assignments</span><span className={styles.res}>Learn more</span></div></Link>
            </div>
            <img className={styles.boomer} src={boomers_lab} alt="boomer"/> */}
          </div>
          
          <div className={`${styles.card} ${styles.orange}`}>
            <div className={styles.header}>
              <img src={icon_journals} alt="Journals"/>
              <h5>Journals</h5>
            </div>
            <p>Designed to build daily writing habits, our journals provide a place for students to consistently write about anything. </p>
            {/* <div className={styles.ctaDiv}>
              <Link to={'/tools/journals'}> <div className={styles.learnMoreBtn}><span className={styles.full}>Learn more about Journals</span><span className={styles.res}>Learn more</span></div></Link>
            </div>
            <img className={styles.boomer} src={boomers_laptop} alt="boomer"/> */}
          </div>
          
        </div>

        <h3>Best of all: Your students Stories and Journals can be published as personalized, soft-cover books!</h3>

        <div className={styles.booksContainer}>
          <img className={styles.book} src={book_journal} alt="Journal"/>
          <img className={styles.book} src={book_story} alt="Story"/>
        </div>
      </div>

    </div>
  )

}

export default OurTools;