import React, { useState } from 'react';
import styles from "./Features.module.scss";
import FeatureCard from './Cards/FeatureCard';
import googleIcon from '../../../Assets/Icons/pinkIcons/pinkGoogleIcon.svg'
import pinkActivitiesIcon from '../../../Assets/Icons/pinkIcons/pinkActivitiesIcon.svg'
import pinkLibraryIcon from '../../../Assets/Icons/pinkIcons/pinkLibraryIcon.svg'
import pinkVocabularyIcon from '../../../Assets/Icons/pinkIcons/pinkVocabularyIcon.svg'
import blueBoxIcon from '../../../Assets/Icons/blueIcons/blueBoxIcon.svg'
import blueFeedbackIcon from '../../../Assets/Icons/blueIcons/blueFeedbackIcon.svg'
import blueNodesIcon from '../../../Assets/Icons/blueIcons/blueNodesIcon.svg'
import blueStatsIcon from '../../../Assets/Icons/blueIcons/blueStatsIcon.svg'
import blueReportIcon from '../../../Assets/Icons/blueIcons/blueReportIcon.svg'
import orangeAPlusIcon from '../../../Assets/Icons/orangeIcons/orangeAPlusIcon.svg'
import orangeGalleryIcon from '../../../Assets/Icons/orangeIcons/orangeGalleryIcon.svg'
import orangePdfIcon from '../../../Assets/Icons/orangeIcons/orangePdfIcon.svg'
import orangeChatIcon from '../../../Assets/Icons/orangeIcons/orangeChatIcon.svg'
import orangeActivitiesIcon from '../../../Assets/Icons/orangeIcons/orangeActivitiesIcon.svg'
import orangeConectionIcon from '../../../Assets/Icons/orangeIcons/orangeConectionIcon.svg'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import CarouselBtn from '../../Structural/CarouselBtn/CarouselBtn';
import 'pure-react-carousel/dist/react-carousel.es.css';
import LazyLoad from 'react-lazyload';

const Features = ({ mobile }) => {
    const [slide, setSlide] = useState(1)
    if (!mobile) {
        return (
            <LazyLoad height={700}>
                <section className={styles.featuresContainer}>
                    <h2> Key Features</h2>
                    <div className={styles.grid}>
                        <FeatureCard icon={googleIcon} text={'Google Integration'} tooltipText='Provides for easy creation of single sign-in student accounts, resource uploads and activity sharing via Google Classroom' />
                        <FeatureCard icon={pinkActivitiesIcon} text={'Unlimited writing activities'} tooltipText=' Conduct an unlimited number of writing activities using our Assignments, Stories and Student Journals tools' />
                        <FeatureCard icon={pinkLibraryIcon} text={'Access to library of story starts'} tooltipText=' Select a captivating story start from our library of leveled and categorized original first chapters' />
                        <FeatureCard icon={pinkVocabularyIcon} text={'Vocabulary tools'} tooltipText=' Request and monitor student vocabulary use' />

                        <FeatureCard icon={orangeConectionIcon} text={'Breakout writing rooms'} tooltipText='Students collaborate working in pairs while each of them writes their piece of work. The best way to keep them connected!' />
                        <FeatureCard icon={orangeAPlusIcon} text={'Rubrics'} tooltipText=' Standards-based grading system to measure and track student performance' />
                        <FeatureCard icon={orangeGalleryIcon} text={'Upload images'} tooltipText='Upload images to serve as prompts/support for student writing activities' />
                        <FeatureCard icon={orangePdfIcon} text={'PDF creation'} tooltipText=' PDF creation of student work for printing or digital sharing' />

                        <FeatureCard icon={orangeActivitiesIcon} text={'Duplicate activities'} tooltipText='Duplicate existing assignments to use with other classes or sections' />
                        <FeatureCard icon={orangeChatIcon} text={'In-app chat'} tooltipText=' Lets teachers communicate with their students in real-time!' />
                        <FeatureCard icon={blueFeedbackIcon} text={'Peer Feedback'} tooltipText=' Students review peer work and provided feedback as directed by the teacher' />
                        <FeatureCard icon={blueNodesIcon} text={'Teacher collaboration'} tooltipText=' Allows for multiple teacher collaboration and support ofactivities' />

                        <FeatureCard icon={blueStatsIcon} text={'Admin access & reports'} tooltipText='A centralized location for administrators to monitor their school’s writing activity by grade, tool, and subject' />
                        <FeatureCard icon={blueReportIcon} text={'Automated feedback & reports'} tooltipText='Automated writing feedback involving grammar and mechanics save teachers time while providing quick insights around student writing skills' />
                        <FeatureCard icon={blueBoxIcon} text={'Professional Development'} tooltipText='Engaging and effective professional development combined with tools’ ease-of-use ensures widespread deployment with optimal and immediate impact' />
                    </div>
                </section>
            </LazyLoad>
        );

    } else {
        return (
            <LazyLoad height={100}>

                <section className={styles.featuresContainer}>
                    <h2> Key Features</h2>

                    <div className={styles.carouselContainer}>

                        <CarouselProvider naturalSlideWidth={360}
                            naturalSlideHeight={360}
                            totalSlides={4}
                            currentSlide={slide}
                            touchEnabled={false}
                            infinite>

                            <div className={styles.sliderButtons}>

                                <Slider className={styles.slider}>
                                    <Slide index={0} >
                                        <div className={styles.slide}>

                                            <FeatureCard icon={googleIcon} text={'Google Integration'} />
                                            <FeatureCard icon={pinkActivitiesIcon} text={'Unlimited writing activities'} />
                                            <FeatureCard icon={pinkLibraryIcon} text={'Access to library of story starts'} />
                                            <FeatureCard icon={pinkVocabularyIcon} text={'Vocabulary tools'} />
                                        </div>
                                    </Slide>
                                    <Slide index={1}>
                                        <div className={styles.slide}>
                                            <FeatureCard icon={orangeConectionIcon} text={'Breakout writing rooms'} />
                                            <FeatureCard icon={orangeAPlusIcon} text={'Rubrics'} />
                                            <FeatureCard icon={orangeGalleryIcon} text={'Upload images'} />
                                            <FeatureCard icon={orangePdfIcon} text={'PDF creation'} />
                                        </div>
                                    </Slide>
                                    <Slide index={2}>
                                        <div className={styles.slide}>
                                            <FeatureCard icon={orangeActivitiesIcon} text={'Duplicate activities'} />
                                            <FeatureCard icon={orangeChatIcon} text={'In-app chat'} />
                                            <FeatureCard icon={blueFeedbackIcon} text={'Peer Feedback'} />
                                            <FeatureCard icon={blueNodesIcon} text={'Teacher collaboration'} />
                                        </div>

                                    </Slide>
                                    <Slide index={3}>
                                        <div className={styles.slide}>
                                            <FeatureCard icon={blueStatsIcon} text={'Admin access & reports'} />
                                            <FeatureCard icon={blueReportIcon} text={'Automated feedback & reports'} />
                                            <FeatureCard icon={blueBoxIcon} text={'Professional Development'} />
                                        </div>

                                    </Slide>

                                </Slider>



                                <div className={styles.dots}>
                                    <div className={slide === 0 ? ` ${styles.dot} ${styles.active}` : styles.dot} onClick={(e) => { e.stopPropagation(); setSlide(0) }}></div>
                                    <div className={slide === 1 ? ` ${styles.dot} ${styles.active}` : styles.dot} onClick={(e) => { e.stopPropagation(); setSlide(1) }}></div>
                                    <div className={slide === 2 ? ` ${styles.dot} ${styles.active}` : styles.dot} onClick={(e) => { e.stopPropagation(); setSlide(2) }}></div>
                                    <div className={slide === 3 ? ` ${styles.dot} ${styles.active}` : styles.dot} onClick={(e) => { e.stopPropagation(); setSlide(3) }}></div>
                                </div>

                                <div className={styles.buttons}>

                                    <div onClick={() => slide === 0 ? setSlide(2) : setSlide(slide - 1)} ><CarouselBtn last white /></div>
                                    <div onClick={() => slide === 3 ? setSlide(0) : setSlide(slide + 1)} ><CarouselBtn next /></div>
                                </div>

                            </div>

                        </CarouselProvider>
                    </div>

                </section>
            </LazyLoad>

        )
    }
}

export default Features;


