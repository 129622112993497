import React from 'react';

const assignmentsicon = ({ color }) => {
    return (
        <svg width="25" height="34" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.4027 27.7781H6.59718C5.2569 27.7781 4.16663 26.6878 4.16663 25.3475V19.0975C4.16663 17.7573 5.2569 16.667 6.59718 16.667H18.4027C19.743 16.667 20.8333 17.7573 20.8333 19.0975V25.3475C20.8333 26.6878 19.743 27.7781 18.4027 27.7781ZM6.59718 18.7503C6.40551 18.7503 6.24996 18.9059 6.24996 19.0975V25.3475C6.24996 25.5392 6.40551 25.6948 6.59718 25.6948H18.4027C18.5944 25.6948 18.75 25.5392 18.75 25.3475V19.0975C18.75 18.9059 18.5944 18.7503 18.4027 18.7503H6.59718Z" fill={color} />
            <path d="M15.625 18.7499C15.05 18.7499 14.5834 18.2832 14.5834 17.7082V15.6249C14.5834 15.4332 14.4278 15.2777 14.2362 15.2777H10.7639C10.5723 15.2777 10.4167 15.4332 10.4167 15.6249V17.7082C10.4167 18.2832 9.95004 18.7499 9.37504 18.7499C8.80004 18.7499 8.33337 18.2832 8.33337 17.7082V15.6249C8.33337 14.2846 9.42365 13.1943 10.7639 13.1943H14.2362C15.5764 13.1943 16.6667 14.2846 16.6667 15.6249V17.7082C16.6667 18.2832 16.2 18.7499 15.625 18.7499Z" fill={color} />
            <path d="M12.4999 23.6109C12.3471 23.6109 12.1943 23.5776 12.0527 23.5095L4.76099 20.0373C4.24154 19.7901 4.02071 19.1692 4.26793 18.6498C4.51515 18.1289 5.13737 17.9081 5.65682 18.1581L12.4999 21.4151L19.3443 18.1567C19.8652 17.9067 20.4846 18.1276 20.7332 18.6484C20.9804 19.1678 20.7596 19.7887 20.2402 20.0359L12.9485 23.5081C12.8054 23.5776 12.6527 23.6109 12.4999 23.6109Z" fill={color} />
            <path d="M21.1806 33.3337H3.81944C1.7125 33.3337 0 31.6212 0 29.5142V7.98644C0 5.87949 1.7125 4.16699 3.81944 4.16699H6.59722C7.17222 4.16699 7.63889 4.63366 7.63889 5.20866C7.63889 5.78366 7.17222 6.25033 6.59722 6.25033H3.81944C2.8625 6.25033 2.08333 7.02949 2.08333 7.98644V29.5142C2.08333 30.4712 2.8625 31.2503 3.81944 31.2503H21.1806C22.1375 31.2503 22.9167 30.4712 22.9167 29.5142V7.98644C22.9167 7.02949 22.1375 6.25033 21.1806 6.25033H18.4028C17.8278 6.25033 17.3611 5.78366 17.3611 5.20866C17.3611 4.63366 17.8278 4.16699 18.4028 4.16699H21.1806C23.2875 4.16699 25 5.87949 25 7.98644V29.5142C25 31.6212 23.2875 33.3337 21.1806 33.3337Z" fill={color} />
            <path d="M17.0139 8.33333H7.9861C6.64582 8.33333 5.55554 7.24306 5.55554 5.90278V3.81944C5.55554 3.24444 6.02221 2.77778 6.59721 2.77778H8.82499C9.27915 1.17639 10.7555 0 12.5 0C14.2444 0 15.7208 1.17639 16.175 2.77778H18.4028C18.9778 2.77778 19.4444 3.24444 19.4444 3.81944V5.90278C19.4444 7.24306 18.3542 8.33333 17.0139 8.33333ZM7.63888 4.86111V5.90278C7.63888 6.09444 7.79443 6.25 7.9861 6.25H17.0139C17.2055 6.25 17.3611 6.09444 17.3611 5.90278V4.86111H15.2778C14.7028 4.86111 14.2361 4.39444 14.2361 3.81944C14.2361 2.8625 13.4569 2.08333 12.5 2.08333C11.543 2.08333 10.7639 2.8625 10.7639 3.81944C10.7639 4.39444 10.2972 4.86111 9.72221 4.86111H7.63888Z" fill={color} />
        </svg>
    );
}

export default assignmentsicon;

