import React from 'react';
import styles from './Hero.module.scss'
import heroDecoration from './../../../../Assets/Parents/heroDecoration.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';

const Hero = () => {
  const screenWidth = window.innerWidth
  console.log(screenWidth)

  return (
    <section className={styles.hero}>
      <div className={styles.container}>

        <div className={styles.titleContainer}>
          <h1>Stay connected with <br /> your child's learning</h1>
          <p>Monitor your child's writing activities in real-time and follow their progress throughout the year!</p>
          <div className={styles.btnContainer}>
            <Link to="/auth/parent/signup" className={styles.blueBtn}>Sign up for our free Family app</Link>
            <a href="https://escribli.com/store" target="_blank" rel="noopener noreferrer" className={styles.whiteBorderBtn}>Bookstore</a>
          </div>
        </div>

        {screenWidth >= 1180 &&
          <div className={styles.sideContainer}>
            <LazyLoadImage src={heroDecoration} alt='' effect="blur" width={522} />
          </div>
        }

      </div>
    </section>
  );
}

export default Hero;