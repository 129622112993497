import React from 'react';
import styles from './Tooltip.module.scss'

const Tooltip = ({ title, tooltipText, show, btn }) => {

    if (btn) {
        return (
            <div className={show ? `${styles.tooltip} ${styles.show} ${styles.btn}` : `${styles.tooltip}  ${styles.btn}`}>
                {title ? <h3>{title}</h3> : <></>}
                <p className={styles.littleP}>{tooltipText}</p>
            </div>
        )
    } else {

        return (
            <div className={show ? `${styles.tooltip} ${styles.show}` : styles.tooltip}>
                {title ? <h3>{title}</h3> : <></>}
                <p className={styles.littleP}>{tooltipText}</p>
            </div>
        );
    }
}

export default Tooltip;