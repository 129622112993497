import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { addAnimation } from '../../../../Helpers/uiFunctions';

import styles from './checkoutStyles.module.scss';

const CreditsDetailsBlock = ({ copies, setSummaryDetails, activityType }) => {

  const userDetails = useSelector(state => state.storeData.userDetails)
  const summaryDetails = useSelector(state => state.storeData.summaryDetails)
  const credits = userDetails ? userDetails.points : 0;
  const [ availableCredits, setAvailableCredits ] = useState(credits)
  const maxDiscount = parseInt(availableCredits)/13 //maximum possible books with credits
  const maxRedeem = copies < maxDiscount ? copies : maxDiscount; //maximum possible copies to redeem
  const balanceRef = useRef()
  const quantRef = useRef()
  const [ quantity, setQuantity ] = useState(maxDiscount < 1 ? 0 : 1)
  const [ showError, setShowError ] = useState(false)

  const handleRedeem = () => {
    console.log('redeem')
    if(quantity>maxRedeem){
      setShowError(true)
      addAnimation(balanceRef, 'shakeSm', 500)

    } else {

      quantRef.current.value=0
      setAvailableCredits(availableCredits-(quantity*13))
      setQuantity(0)
      const unitPrice = summaryDetails.type==='standard'?15:16;
      const reduction = unitPrice*parseFloat(quantity)
      const newPrice = parseFloat(summaryDetails.books).toFixed(2) - reduction

      setSummaryDetails({...summaryDetails, books: newPrice, creditsRedeemed: summaryDetails.creditsRedeemed+parseInt(quantity), recallApi:true})


    }
  }

  useEffect(()=>{
    console.log(maxDiscount)
    console.log(maxRedeem)
  },[])


  return (
    <div className={styles.creditsDetailsBlock}>

      <div className={styles.colorTab}></div>
      <h3>Use Teacher Credits</h3>
      <p>Class {activityType==='story'?'Book':'Journal'} unit cost: 13 credits</p>

      <div className={styles.creditBalanceBlock}>
        <h6>Credits</h6>
        <h2>{Math.round((availableCredits + Number.EPSILON) * 100) / 100}</h2>
      </div>
      
      <h4> Summary:</h4>
      <div className={styles.quantityDiv}>
        <p>Quantity of {activityType==='story'?'books':'journals'}  to redeem</p>
        <input 
          ref={quantRef}
          className={quantity>maxRedeem ? styles.exceed : null} 
          onChange={(e)=>{setQuantity(e.target.value); setShowError(false)}} 
          type="number" 
          name="quantityInput" 
          min={0} 
          max={copies < maxDiscount ? copies : maxRedeem+1} 
          defaultValue={maxDiscount < 1 ? 0 : 1}
        />
      </div>

      {
        maxDiscount > 0 && quantity > 0 ?

          <p ref={balanceRef} className={styles.remainingBalance}>
            Balance after redeeming: 
            <span className={quantity>maxRedeem ? styles.exceed : null}>{ Math.round(((availableCredits-quantity*13) + Number.EPSILON) * 100) / 100 }
            {/* <span className={quantity>maxRedeem ? styles.exceed : null}>{ roundN(availableCredits-(quantity*13), 1)} */}
            { [1,0,-1].includes(parseInt(availableCredits)-(quantity*13)) ? ' credit' : ' credits' }
            </span>
         </p>

        : <p className={styles.remainingBalance}></p>
      }

      <div onClick={handleRedeem} className={`${styles.redeemBtn} ${maxDiscount < 1 ? styles.disable : null}`}>Redeem
        {showError && <div className={styles.error}>Insufficient credits</div>} 
      </div>
      
    </div>
  )

}

export default CreditsDetailsBlock;