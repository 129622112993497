import React, { useState } from 'react';
import styles from './Interactive.module.scss'
import connectImg from './../../../../Assets/Parents/connectImg.png'
import connectImgMobile from './../../../../Assets/Parents/connectImgMobile.png'
import planImg from './../../../../Assets/Parents/planImg.png'
import storeImg from './../../../../Assets/Parents/store.png'
import { useWindowSize } from '../../../../Hooks/UseWindowSize';
import PieceMobileCard from '../../../HomePage/Sections/Cards/PieceMobileCard';
import { Link } from 'react-router-dom';

const Interactive = () => {

  const [selectedTerm, setSelectedTerm] = useState('connect');
  const size = useWindowSize();

  const [term, setTerm] = useState();

  return (
    <section className={styles.interactive}>
      {size.width >= 1080 ?
        <div className={styles.container}>

          <div className={styles.cardContainer}>
            <div className={selectedTerm === 'connect' ? `${styles.optionCard} ${styles.active}` : styles.optionCard} onMouseEnter={() => setSelectedTerm('connect')}>
              <h3>Connect</h3>
              <p>Easily track & monitor your child’s activities</p>
            </div>
            <div className={selectedTerm === 'plan' ? `${styles.optionCard} ${styles.active}` : styles.optionCard} onMouseEnter={() => setSelectedTerm('plan')}>
              <h3>Plan ahead & organize</h3>
              <p>Our calendar view allows you to plan ahead and be informed of all your child’s writing activities.</p>
            </div>
            <div className={selectedTerm === 'store' ? `${styles.optionCard} ${styles.active}` : styles.optionCard} onMouseEnter={() => setSelectedTerm('store')}>
              <h3>Access our store</h3>
              <p>Visit our store for your child’s Escribli stories and gear</p>
            </div>
          </div>

          <div className={styles.imageShowcase}>
            <img className={selectedTerm === 'connect' ? `${styles.connectImg} ${styles.active} ${styles.img} ` : `${styles.connectImg} ${styles.img}`} src={connectImg} alt='' />
            <img className={selectedTerm === 'plan' ? `${styles.planImg} ${styles.active} ${styles.img}` : `${styles.planImg} ${styles.img}`} src={planImg} alt='' />
            <img className={selectedTerm === 'store' ? `${styles.storeImg} ${styles.active} ${styles.img}` : `${styles.storeImg} ${styles.img}`} src={storeImg} alt='' onClick={() => window.open('https://escribli.com/store')} />
          </div>

        </div>
        :
        <div className={styles.container}>

          <section className={styles.piecesMobileContainer}>

            <PieceMobileCard term='Connect' img={connectImgMobile} stateTerm={term} setTerm={setTerm} text='Easily integrates with all curriculum and can be customized into any type of assignment.' />
            <PieceMobileCard term='Plan ahead & organize' img={planImg} stateTerm={term} setTerm={setTerm} text='Differentiate instruction easily with groups of any size, age, grade, or skill level. Our tools are accessible through phones, tablets, laptops, or other devices.' />
            <PieceMobileCard term='Access our store' img={storeImg} stateTerm={term} setTerm={setTerm} text='Students model writing and learn from one another by engaging in side-by-side writing in our breakout writing rooms.' />

          </section>

        </div>
      }
      <Link to="/auth/parent/signup" className={styles.blueBtn}>Sign up for our free Family app</Link>

    </section>
  );
}

export default Interactive;