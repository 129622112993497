import React from 'react';
import styles from './ToolCard.module.scss'
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const handleGA = (title) => {

    title === 'Stories' && ReactGA.event({ category: 'Tools', action: 'Click on Stories card' })
    title === 'Assignments' && ReactGA.event({ category: 'Tools', action: 'Click on Assignment card' })
    title === 'Journals' && ReactGA.event({ category: 'Tools', action: 'Click on Journals card' })
}

const ToolCard = ({ title, text, icon, color, long, square }) => {

    if (!square) {
        return (
            <div className={styles.tool}>

                <div className={color === 'orange' ? `${styles.imgWrapper} ${styles.orange}` : color === 'blue' ? `${styles.imgWrapper} ${styles.blue}` : `${styles.imgWrapper} ${styles.orangeBright}`}>
                    <LazyLoadImage src={icon} alt='icon' effect="blur" wrapperClassName={styles.lazySpan} />
                </div>
                <div className={styles.toolBody}>
                    <h3>{title}</h3>
                    <p className={long && styles.long}>{text}</p>
                    <Link to={color === 'orange' ? '/tools/stories' : color === 'blue' ? '/tools/assignments' : '/tools/journals'}>See More</Link>
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.tool}>

                <div className={color === 'orange' ? `${styles.squareWrapper} ${styles.orange}` : color === 'blue' ? `${styles.squareWrapper} ${styles.blue}` : `${styles.squareWrapper} ${styles.orangeBright}`}>
                    <LazyLoadImage src={icon} alt='icon' effect="blur" wrapperClassName={styles.lazySpan}/></div>
                <div className={styles.toolBody}>
                    <h3>{title}</h3>
                    <p className={long && styles.long}>{text}</p>
                    <Link to={color === 'orange' ? '/tools/stories' : color === 'blue' ? '/tools/assignments' : '/tools/journals'} onClick={() => handleGA(title)}>See More</Link>
                </div>
            </div>
        );
    }

}

export default ToolCard;