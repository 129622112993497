import React from 'react';
import { openInNewTab } from '../../Helpers/uiFunctions';
import { LinkedInIcon } from './../../Assets/Icons'
import styles from './Person.module.scss';



const Person = ({ name, position, imgUrl, blue }) => {
    return (

        <div className={blue ? ` ${styles.person} ${styles.blueBg} ` : `${styles.person} ${styles.whiteBg} `}>
            <figure className={styles.imgWrapper}>
                <img src={imgUrl} alt='personal pic' />
            </figure>
            <div className={blue ? styles.textWhite : styles.textGray}>
                <h3>
                    {name}
                </h3>
                <p>{position}</p>
            </div>
        </div>
    );
}

export default Person;