import React from 'react';
import styles from './Stories.module.scss'
import Elements from '../../HomePage/Sections/Elements'
import storyBookBoomer from '../../../Assets/Boomers/storybook-boomer.png'
import bookPurple from '../../../Assets/Boomers/bookPurple.png'
import bookBlue from '../../../Assets/Boomers/bookBlue.png'
import chapter1 from '../../../Assets/Tools/chapter1.png'
import chapter2 from '../../../Assets/Tools/chapter2.png'
import chapter3 from '../../../Assets/Tools/chapter3.png'
import chapter4 from '../../../Assets/Tools/chapter4.png'
import class1 from '../../../Assets/Tools/class1.png'
import class2 from '../../../Assets/Tools/class2.png'


import {useReferralLink} from '../../../Hooks/useReferralLink'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import WritersCarousel from './WritersCarousel';


const Stories = () => {
    return (
        <article className={styles.storiesContainer}>

            <div className={styles.groupWriting}>
                <h2>Group Story-Writing that gets published!</h2>
                <p>Improve your students' story writing skills by collaboratively creating an original story with your class that can even be published as a real paperback book.</p>
                <div className={styles.imgWrapper}>
                    <LazyLoadImage src={storyBookBoomer} alt='boomer' effect="blur"/>
                </div>
                <a  href={useReferralLink()}> Start free trial</a>
            </div>

            {/* @todo remove_static_link */}
            <a href="https://static.boomwriter.com/home/resource/18" target="_blank" rel="noopener noreferrer" className={styles.storyStarts}>
                <p>Check out our StoryStarts</p>
                <div className={styles.imgWrapper}>
                    <LazyLoadImage src={bookPurple} alt='purple Book' effect="blur"/>
                </div>
                <p>The Case of the Missing Carraviglio</p>
            </a>


            <Elements />

            <a  href={useReferralLink()} className={styles.bigButton} > Start free trial</a>

            <div className={styles.whatStory}>
                <div className={styles.imgWrapper}>
                    <LazyLoadImage src={bookBlue} alt='boomer' effect="blur"/>
                </div>
                <div className={styles.bodyStory}>
                    <h3>What's in a Escribli Story?</h3>

                    <div className={styles.bookContainer}>
                        <div className={styles.chapter} >
                            <h4>Escribli story Start!</h4>
                            <div>
                                <LazyLoadImage src={chapter1} alt='book pic' effect="blur"/>
                            </div>
                        </div>
                        <div className={styles.chapter}>
                            <h4>Middle Chapter Winning Entry</h4>
                            <div>
                                <LazyLoadImage src={chapter2} alt='book pic' effect="blur"/>
                            </div>
                        </div>
                        <div className={styles.chapter}>
                            <h4>Final Chapter Winning Entry</h4>
                            <div>
                                <LazyLoadImage src={chapter3} alt='book pic' effect="blur"/>
                            </div>
                        </div>
                        <div className={styles.chapter} >
                            <h4>Bonus! Each Student's Final chapter</h4>
                            <div>
                                <LazyLoadImage src={chapter4} alt='book pic' effect="blur"/>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <WritersCarousel/>

            <div className={styles.letsTry}>
                <h3>Let’s get started with Escribli today!</h3>
                <div className={styles.imgContainer}>
                    <div className={`${styles.imgWrapper} ${styles.mobile} `}>
                        <LazyLoadImage src={class1} alt='students using Escribli' effect="blur"/>
                    </div>
                    <div className={styles.imgWrapper}>
                        <LazyLoadImage src={class2} alt='students using Escribli' effect="blur"/>
                    </div>
                </div>
                <a  href={useReferralLink()} className={styles.btn}>Start free trial</a>
            </div>
        </article>
    );
}

export default Stories;