import React from 'react';
import styles from './WritingEngagement.module.scss'
import Books from './../../../../Assets/Parents/Books.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const Writing = () => {
  
  return ( 
    <article className={`${styles.container} ${styles.writing}`}>
      <LazyLoadImage className={styles.bookImage} src={Books} alt='book image' effect="blur"/>
      <div className={styles.textContainer}>
        <h2>Capture every moment of your child’s writing</h2>
        <p>Keep your child’s writing in softcover, personalized books to be shared with family and friends.</p>
      </div>
    </article>
   );
}
 
export default Writing;