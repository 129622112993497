import React from 'react';
import CountriesIcon from "../../../Assets/HomePage/countries.svg";
import WordsIcon from "../../../Assets/HomePage/words.svg";
import WritersIcon from "../../../Assets/HomePage/writers.svg";
import styles from './RealData.module.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const RealData = () => {
    return (


        <section className={styles.realData}>
            <div className={styles.data}>
                <div><LazyLoadImage alt='icon' src={WritersIcon} effect="blur" /></div>
                <div><p>+ 365k</p><span>Writers</span></div>
            </div>
            <div className={styles.data}>
                <div><LazyLoadImage alt='icon' src={WordsIcon} effect="blur" /></div>
                <div><p>+ 89m</p><span>words written</span></div>
            </div>
            <div className={styles.data}>
                <div><LazyLoadImage alt='icon' src={CountriesIcon} effect="blur" /></div>
                <div><p>+ 150</p><span>Countries</span></div>
            </div>
        </section>


    );
}

export default RealData;