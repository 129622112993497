import { useLocation } from 'react-router-dom';
import queryString from 'query-string'


export function useReferralLink(givenUrl='https://escribli.com/auth/teacher/signup') {
    const location = useLocation()
     const parsed = queryString.parse(location.search);
    const signUpURL=givenUrl
    return queryString.stringifyUrl({url: signUpURL, query: parsed})

    
}