import React from 'react';
const journalIcon = ({ color }) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.1071 6.25001H19.6429V0.892874C19.6429 0.399745 19.2431 0 18.75 0H0.892874C0.399745 0 0 0.399745 0 0.892874V21.4286C0 23.401 1.59898 25 3.57144 25H21.4286C23.401 25 25 23.401 25 21.4286V7.14284C25 6.64976 24.6003 6.25001 24.1071 6.25001ZM7.14284 4.46427H12.5C12.9931 4.46427 13.3928 4.86401 13.3928 5.35714C13.3928 5.85027 12.9931 6.25001 12.5 6.25001H7.14284C6.64971 6.25001 6.24996 5.85027 6.24996 5.35714C6.24996 4.86401 6.64976 4.46427 7.14284 4.46427ZM15.1785 21.4286H4.46427C3.97114 21.4286 3.57139 21.0288 3.57139 20.5357C3.57139 20.0426 3.97114 19.6428 4.46427 19.6428H15.1785C15.6717 19.6428 16.0714 20.0426 16.0714 20.5357C16.0714 21.0288 15.6717 21.4286 15.1785 21.4286ZM15.1785 17.8571H4.46427C3.97114 17.8571 3.57139 17.4574 3.57139 16.9642C3.57139 16.4711 3.97114 16.0714 4.46427 16.0714H15.1785C15.6717 16.0714 16.0714 16.4711 16.0714 16.9642C16.0714 17.4574 15.6717 17.8571 15.1785 17.8571ZM15.1785 14.2857H4.46427C3.97114 14.2857 3.57139 13.886 3.57139 13.3928C3.57139 12.8997 3.97114 12.5 4.46427 12.5H15.1785C15.6717 12.5 16.0714 12.8997 16.0714 13.3928C16.0714 13.886 15.6717 14.2857 15.1785 14.2857ZM15.1785 10.7143H4.46427C3.97114 10.7143 3.57139 10.3145 3.57139 9.8214C3.57139 9.32828 3.97114 8.92853 4.46427 8.92853H15.1785C15.6717 8.92853 16.0714 9.32828 16.0714 9.8214C16.0714 10.3145 15.6717 10.7143 15.1785 10.7143ZM23.2143 21.4286C23.2143 22.4148 22.4148 23.2143 21.4286 23.2143C20.4424 23.2143 19.6429 22.4148 19.6429 21.4286V8.03571H23.2143V21.4286H23.2143Z" fill={color} />
        </svg>
    );
}

export default journalIcon;

