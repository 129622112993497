import React, { useEffect, useState } from 'react';

import styles from './checkoutStyles.module.scss';
import spinner from '../../../../Assets/Icons/spinner-sml--fast.svg';
import { getFromApi } from '../../../../API/generalCalls';
import { useSelector } from 'react-redux';

const ShippingOptionsBlock = ({countryName, summaryDetails, setSummaryDetails, shippingRef, showError, setShowError}) => {

  const lookup = require('country-code-lookup');

  const handleUpdate = (e) => {
    console.log(e.target)
    console.log(e.target.dataset)
    setShowError({...showError, vis:false})
    const { price, name, estimated, tracking } = e.target.dataset
    const newOption = { price:parseFloat(price), name, estimated, tracking};
    setSummaryDetails({...summaryDetails, shippingOption: newOption})
  }
  
  useEffect(()=>{
    if(summaryDetails.shippingOption){
      setShowError({...showError, vis:false})
    }

  },[summaryDetails])


  const [ apiRes, setApiRes ] = useState(null)
  const getOptions = async (countryCode) => {setApiRes( await getFromApi(`store/shipping?countryCode=${countryCode}&quantity=${summaryDetails.copies}`)) }

  useEffect(()=>{
    if(countryName){
      setApiRes(null)
      const countryCode = lookup.byCountry(countryName)
      if(countryCode&&countryCode.iso2){
        getOptions(countryCode.iso2)
      }
    }
  },[summaryDetails.address.country_name])

  useEffect(()=>{
    if(apiRes&&apiRes.length){
      const { price, name, estimated, tracking } = apiRes[0];
      const newOption = { price, name, estimated, tracking};
      setSummaryDetails({...summaryDetails, shippingOption: newOption})
    }

  },[apiRes])

  return (
    <div ref={shippingRef} className={styles.shippingOptionsBlock}>
  <div className={styles.colorTab}></div>
      <h3>Shipping options:</h3>
      <div className={`${styles.optionsDiv} ${!apiRes ? styles.loading : null}`}>
        {
          !apiRes ?
            <>
              <img src={spinner} alt=""/>
              <p>Loading shipping options</p>

            </>

          :
            
            apiRes.map((i, idx) => {
              return(
                <label onClick={handleUpdate} key={idx}>
                  <input 
                    type="radio" 
                    name="shippingOption" 
                    defaultChecked={idx===0} 
                    data-name={i.name} 
                    data-tracking={i.tracking} 
                    data-price={i.price} 
                    data-estimated={i.estimated}
                  />

                  <div className={styles.shippingOptionRow}>
                    <p className={styles.name}>{i.name} {i.tracking==='yes' && '(tracked)'}</p>
                    <p className={styles.price}>${i.price}</p>
                  </div>
                </label>
              )
            })

        }

      </div>

    </div>
  )

}

export default ShippingOptionsBlock;