import React, { useState } from 'react';
import styles from './Tools.module.scss'
import Stories from './Sections/Stories'
import Assignments from './Sections/Assignments'
import Journals from './Sections/Journals'
import JournalIcon from '../../Assets/Tools/journalIcon.jsx'
import AssignmentsIcon from '../../Assets/Tools/assignmentsIcon.jsx'
import StoriesIcon from '../../Assets/Tools/storiesIcon.jsx'
import ToolCard from '../HomePage/Sections/Cards/ToolCard';
import journalBoomer from './../../Assets/Boomers/journal-illustration.png'
import scienceBoomer from './../../Assets/Boomers/science_lab.png'
import storybookBoomer from './../../Assets/Boomers/storybook-boomer.png'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'

const Tools = ({ section }) => {
    const [term, setTerm] = useState(section)


    if (section) {

        return (
            <div className={styles.lightbluegreyBg}>
                <section className={styles.toolsContainer}>
                    <div className={styles.responsiveCenter}>
                        <h1>Teacher Toolbox</h1>
                        <div className={styles.selectorContainer}>
                            <Link to='/tools/journals'>
                                {term === 'journals' &&
                                    <Helmet>
                                        <title>Student Journal Writing | Escribli Software Tools</title>
                                        <meta name="description" content="Escribli Journals provides teachers to easily assign writing opportunities for students on daily basis to improve their writing and critical thinking.  " />
                                        <meta name="keywords" content="student journal writing, digital journals for students, online student journals, student writing journals, journal assignments, journal writing tool" />
                                    </Helmet>}

                                <div className={term === 'journals' ? ` ${styles.selectorBtn} ${styles.journals} ${styles.active}` : styles.selectorBtn} onClick={() => setTerm('journals')}>
                                    <JournalIcon color={term === 'journals' ? 'white' : '#858585'} />Journals
                                </div>
                            </Link>
                            <Link to='/tools/assignments'>
                                {term === 'assignments' &&
                                    <Helmet>
                                        <title>Online Writing Assignments for Students | Escribli </title>
                                        <meta name="description" content="Escribli Assignments allows teachers to easily create writing activities in any subject while students can work independently or collaboratively. " />
                                        <meta name="keywords" content="online writing assignments, online writing activities, student assignment writing, student assignments, student writing activities" />
                                    </Helmet>}

                                <div className={term === 'assignments' ? ` ${styles.selectorBtn} ${styles.assignments} ${styles.active}` : styles.selectorBtn} onClick={() => setTerm('assignments')}>
                                    <AssignmentsIcon color={term === 'assignments' ? 'white' : '#858585'} />Assignments
                                </div>
                            </Link>
                            <Link to='/tools/stories'>

                                {term === 'stories' && <Helmet>
                                    <title>Story Prompts for Classroom Writing | Escribli Tools</title>
                                    <meta name="description" content="Escribli Stories provide students the first chapter of a story that ends in a cliff-hanging moment, jumpstarting their imagination and writing skills.   " />
                                    <meta name="keywords" content="student writing tools, teach story writing, online story writing, story writing classroom" />
                                </Helmet>}

                                <div className={term === 'stories' ? ` ${styles.selectorBtn} ${styles.stories} ${styles.active}` : styles.selectorBtn} onClick={() => setTerm('stories')}>
                                    <StoriesIcon color={term === 'stories' ? 'white' : '#858585'} />Stories
                                </div>
                            </Link>
                        </div>
                    </div>

                    {term === 'journals' ? <Journals /> : term === 'assignments' ? <Assignments /> : <Stories />}

                </section>
            </div>

        );
    } else {
        return (
            <div>
                <div className={styles.title}>
                    <Helmet>
                        <title>Teacher Writing Tools for the Classroom | Escribli</title>
                        <meta name="description" content="Escribli Writing Tools makes it easy for teachers to manage writing assigments for their class, helping students become better writers and learners. " />
                        <meta name="keywords" content="student writing tool, writing tools, online writing for students, online writing resource, online classroom resource, online writing resource" />
                    </Helmet>
                    <h1>Teacher Toolbox</h1>
                    <h3>Create customized writing activities in every subject!</h3>
                </div>
                <div className={styles.body}>
                    <div className={styles.cardContainer}>
                        <ToolCard square color='orange' icon={storybookBoomer} title={'Stories'} text={'Students can work independently or as a group to complete an original story that can even be published!'} />
                        <ToolCard square color='blue' icon={scienceBoomer} title={'Assignments'} text={'Create assignments specific to any subject area with the ability to upload supporting visuals, links, and documents.'} />
                        <ToolCard square color='orangeBright' icon={journalBoomer} title={'Journals'} text={'Designed to encourage students to build daily writing habits. Journals provide students a place to consistently write about anything.'} />

                    </div>
                </div>
            </div>
        )
    }
}

export default Tools;