import React, { useEffect, useRef } from 'react';
import styles from './Reasons.module.scss'
import TrustCard from './Cards/TrustCard';
import { Slide, Slider, CarouselProvider } from 'pure-react-carousel';
import CarouselBtn from '../../Structural/CarouselBtn/CarouselBtn';
import {reasonObjects} from './reasonsObjects';
import LazyLoad from 'react-lazyload';

const Reasons = ({  }) => {

    useEffect(()=>{
      
      const int = setInterval(()=>{
        const nextBtn = document.getElementById('reasonNext');
        if(nextBtn){nextBtn.click()}
      },6000)

      return () => {
        clearInterval(int)
      }
    },[])

    return (
        <LazyLoad height={100}>
            <section className={styles.container}>
                <div className={styles.text}>
                    <h2>Top 10 Reasons Why Teachers Should Try Escribli</h2>
                </div>
                <div className={styles.carousel}>
                    <CarouselProvider
                        naturalSlideWidth={280}
                        naturalSlideHeight={480}
                        totalSlides={10}
                        infinite
                    >
                        <div className={styles.sliderButtons}>

                            <Slider className={styles.slider}>
                              {
                                reasonObjects.map((i, idx) => {
                                    return(
                        
                                      <Slide index={idx}>
                                        <div className={`${styles.card} ${i.classString}`}>

                                          <div className={styles.text}>
                                            <div className={styles.numberCircle}>{idx+1}</div>
                                            <h3>{i.text}</h3>
                                          </div>
                                            <img className={styles.cardImg} src={i.imgSrc} alt="icon"/>
                                        </div>
                                      </Slide>

                                    )
                                })
                              }
                            </Slider>

                            <div className={styles.buttons}>
                                <CarouselBtn last />
                                <CarouselBtn next />
                            </div>
                        </div>
                    </CarouselProvider>
                </div>
            </section>
        </LazyLoad>
    );
}

export default Reasons;