import React from 'react';
import styles from './Hero.module.scss'
import { useReferralLink } from '../../../Hooks/useReferralLink'
import ReactGA from 'react-ga';


const HeroMobile = () => {
    return (
        <section className={styles.hero}>
            <div className={styles.heroTitle}>
                <h1>Escribli</h1>
                <h2>Online writing tools for the classroom</h2>
                <a href={useReferralLink()} onClick={() => ReactGA.event({ category: 'Hero', action: 'Click on sign up hero btn' })}>Start free trial</a>
                {/* <p>recommended for grades 2-8</p> */}
            </div>
        </section>
    );
}

export default HeroMobile;