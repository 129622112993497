import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import styles from './Nav.module.scss';
import Footer from '../Footer/Footer'
import LoginModal from '../Modals/LoginModal';
import Dropdown from '../Dropdown/Dropdown';
import DropdownItem from '../Dropdown/DropDownItem';
import MobileDropdown from '../MobileDropdown/MobileDropdown';
import { useReferralLink } from '../../../Hooks/useReferralLink'
import useSticky from '../../../Hooks/useSticky';
import ReactGA from 'react-ga';
import whiteLogo from './../../../Assets/Nav/whiteLogoEscribli.svg'
import logo from './../../../Assets/Nav/navLogo.svg' 
import BookstoreLoginBtns from './BookstoreLoginBtns';
import { useWindowSize } from '../../../Hooks/UseWindowSize';
import { openInNewTab } from '../../../Helpers/uiFunctions'



const Nav = ({ homepage, clear, noLogin, store, storeLoggedIn, bee, families }) => {

  const { isSticky } = useSticky()
  const [showMenu, setClose] = useState(false)
  const [showModal, setModal] = useState(false)
  const [showSignupModal, setShowSignupModal] = useState(false)
  const referralLink = useReferralLink()

  const size = useWindowSize()
  const orange = bee&&size.width<=680 ? true : false;
  const white = families&&size.width<=620 ? true : false;

  const handleGA = (term) => {
    term === 'signup' && ReactGA.event({ category: 'Nav', action: 'Open SignUp modal' })
    term === 'login' && ReactGA.event({ category: 'Nav', action: 'Open Login modal' })
  }

  return (
    <>
      <div className={!homepage ? isSticky ? `${styles.navContainer} ${styles.sticky}` : `${styles.navContainer} ` : isSticky ? showMenu ? `${styles.navContainer} ${styles.sticky} ${styles.show}` : `${styles.navContainer} ${styles.sticky}  ${styles.home}` : homepage ? `${styles.navContainer} ${styles.home}` :  styles.navContainer} >

        <NavLink to="/">
          <img className={styles.logo} src={showMenu||(homepage&&!isSticky) ? whiteLogo : white||orange ? isSticky ? logo : whiteLogo : logo} alt="Escribli Logo" />
        </NavLink>

        {!clear &&
          <ul className={styles.navbarList}>
            {/* <li><Link to='/tools' >Our Tools</Link></li> */}
            {/* <li> <Link to='/families'>Families</Link> </li>
            <li><Link to='/schools'>Schools</Link></li> */}
            <li><Link to='/pricing'>Pricing</Link></li>
            {/* <li><Link to='/store'>Bookstore</Link></li> */}
            <li><Link to='/about'>Company</Link></li>
          </ul>
        }

        <div className={styles.navBtns}>
          {
            !store  ?
                <>
                  {!noLogin && <div onClick={() => { setModal(true); handleGA('login') }} className={homepage ? styles.login : clear ? styles.loginClear : styles.loginGeneral}>Log in</div>}
                  {(!clear&&!bee) && <div onClick={() => { setShowSignupModal(true); handleGA('signup') }} className={styles.signUp} >Start free trial</div>}
                </>

            :

                <BookstoreLoginBtns loggedIn={storeLoggedIn} userDetails={clear} />

          }
        </div>

        <div className={styles.responsiveMenu}>
          <div className={orange ? `${styles.burgerWrapper} ${styles.orange} ` : showMenu ? `${styles.burgerWrapper} ${styles.orange} ` : `${styles.burgerWrapper} `} onClick={() => setClose(!showMenu)}>
            <div className={showMenu ? `${styles.hamburgerToClose} ${styles.close}` : styles.hamburgerToClose}>
              <div className={styles.hamburgerMiddle}></div>
            </div>
          </div>
        </div>

      </div>
      <div className={showMenu ? `${styles.modalMenu} ${styles.show}` : styles.modalMenu}>

        <div className={styles.animationWrapper}>

          <div className={showMenu ? `${styles.linkContainer} ${styles.show}` : styles.linkContainer}>

            {
              !store &&
                <>
                  <div className={styles.normalA} onClick={() => setModal(!showModal)}>Log in</div>
                  {!bee && <a className={styles.normalA} href={referralLink}>Start free trial</a>}
                </>

            }

            {/* <Link className={styles.normalA} to='/tools'>Teachers</Link>
            <Link className={styles.normalA} to='/families'>Families</Link>
            <Link className={styles.normalA} to='/schools'>Schools</Link> */}
            <Link className={styles.normalA} to='/pricing'>Pricing</Link>
            <Link className={styles.normalA} to='/store'>Bookstore</Link>
            <Link className={styles.normalA} to='/about'>Company</Link>
          </div>

          <div>
          </div>

          <Footer mini />
        </div>

      </div>

      <LoginModal show={showModal} setModal={setModal} />
      <LoginModal show={showSignupModal} setModal={setShowSignupModal} signup />
    </>
  )
}

export default Nav;