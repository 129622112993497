import boomer_book from '../../../Assets/HomePage/boomer_book.png'
import boomer_laptop from '../../../Assets/HomePage/boomer_laptop.png'
import boomer_girl from '../../../Assets/HomePage/boomer_girl1.png'
import boomer_book_girl from '../../../Assets/HomePage/boomer_book_girl.png'
import boomers_boy_girl from '../../../Assets/HomePage/boomers_boy_girl.png'
import boomers_books from '../../../Assets/HomePage/boomers_books.png'
import book_covers from '../../../Assets/HomePage/books.png'
import boomers_cpu from '../../../Assets/HomePage/boomers_cpu.png'
import book_journal from '../../../Assets/HomePage/journal.png'
import devices from '../../../Assets/HomePage/devices.png'

export const reasonObjects = [
  {
    classString:'darkBlueGradient textS',
    text:'Escribli is the best writing tool for teachers to connect and engage with their class.',
    imgSrc:boomer_book
  }, 
  {
    classString:'pinkGradient textLg',
    text:'Students become better writers!',
    imgSrc:boomer_laptop
  },
  {
    classString:'lightBlueGradient textLg',
    text:'Students become better readers!',
    imgSrc:boomer_book_girl
  },
  {
    classString:'orangeGradient textLg',
    text:'Students become better learners! ',
    imgSrc:boomer_girl
  },
  {
    classString:'darkBlueGradient textS',
    text:'Easily offer collaborative writing assignments to your students ',
    imgSrc:boomers_boy_girl
  }, 
  {
    classString:'pinkGradient textLg',
    text:'Teachers save time!',
    imgSrc:boomers_books
  },
  {
    classString:'lightBlueGradient textM',
    text:'Stories: Our story start prompts get your students excited to write!',
    imgSrc:book_covers
  },
  {
    classString:'orangeGradient textS',
    text:'Assignments: our Assignments tool makes it easy to manage all assignments from ELA essays to History papers',
    imgSrc:boomers_cpu
  },
  {
    classString:'darkBlueGradient textS',
    text:'Journals: Our student journal tool entices your students to write every day. We promise!',
    imgSrc:book_journal
  }, 
  {
    classString:'pinkGradient textXs',
    text:'Escribli enables teachers to connect with students via the classroom, the laptop, the tablet, the phone, and with their imaginations!',
    imgSrc:devices
  }
]