import React from 'react';
import styles from './Footer.module.scss'
import linkedIn from './../../../Assets/Icons/linkedIn.svg'
import twitter from './../../../Assets/Icons/twitter.svg'
import facebook from './../../../Assets/Icons/facebook.svg'
import yt from './../../../Assets/Icons/yt.svg'
import instagram from './../../../Assets/Icons/instagram.svg'
import whiteLogo from './../../../Assets/Icons/whiteLogo.svg'
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import poweredBy from '../../../Assets/Icons/powered-by-bw.svg';

const Footer = ({ mini, bee, faithJournals }) => {
    if (mini) {

        return (
            <LazyLoad height={120}>
                <div className={styles.bgColorModal}>
                    <div className={`${styles.footerContainer} ${styles.mini}`}>

                        <div className={styles.mainFooter}>
                            <div className={styles.socialContainer}>
                                <div className={styles.logoWrapper}>
                                    {/* <img src={whiteLogo} alt='' /> */}
                                </div>
                                {/* <div className={styles.socialMedia}>

                                    <a className={styles.socialLink} href='https://www.linkedin.com/company/boomwriter' target="_blank" rel="noopener noreferrer"><img src={linkedIn} alt={'socialmedia icon'} /></a>


                                    <a className={styles.socialLink} href='https://twitter.com/boomwriter_' target="_blank" rel="noopener noreferrer"><img src={twitter} alt={'socialmedia icon'} /></a>


                                    <a className={styles.socialLink} href='https://www.facebook.com/Boomwriter/?d=%7B%22u%22%3A100003601383614%2C%22f%22%3A207799259245384%2C%22t%22%3A1597042223%2C%22ed%22%3A[]%7D&s=AWV_22o3AI0JTJAL' target="_blank" rel="noopener noreferrer"><img src={facebook} alt={'socialmedia icon'} /></a>


                                    <a className={styles.socialLink} href='https://www.instagram.com/boomwriter/' target="_blank" rel="noopener noreferrer"><img src={instagram} alt={'socialmedia icon'} /></a>

                                    <a className={styles.socialLink} href='https://www.youtube.com/c/Boomwriter' target="_blank" rel="noopener noreferrer"><img src={yt} alt={'socialmedia icon'} /></a>

                                </div> */}
                                <p className={styles.direction}>Cambridge, MA  02142<br />USA</p>
                            </div>
                        </div>

                        <div className={`${styles.infoText} ${styles.mini}`}>
                            <p>Escribli - info@escribli.com  | Website and contents © 2021 All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </LazyLoad>
        )

    } else {
        return (
            <LazyLoad height={300}>
                <div className={bee ? `${styles.bee} ${styles.bgColor}` : faithJournals ? `${styles.faithJournals} ${styles.bgColor}` : styles.bgColor}>

                    
                    <div className={styles.footerContainer}>

                        {
                            !faithJournals ? 

                                <div className={styles.mainFooter}>
                                    <div className={styles.socialContainer}>
                                        <div className={styles.logoWrapper}>
                                            <img src={whiteLogo} alt='' />
                                        </div>
                                        {/* <div className={styles.socialMedia}>
                                            <a className={styles.socialLink} href='https://www.linkedin.com/company/boomwriter' target="_blank" rel="noopener noreferrer"><img src={linkedIn} alt={'socialmedia icon'} /></a>
                                            <a className={styles.socialLink} href='https://twitter.com/boomwriter_' target="_blank" rel="noopener noreferrer"><img src={twitter} alt={'socialmedia icon'} /></a>
                                            <a className={styles.socialLink} href='https://www.facebook.com/Boomwriter/?d=%7B%22u%22%3A100003601383614%2C%22f%22%3A207799259245384%2C%22t%22%3A1597042223%2C%22ed%22%3A[]%7D&s=AWV_22o3AI0JTJAL' target="_blank" rel="noopener noreferrer"><img src={facebook} alt={'socialmedia icon'} /></a>
                                            <a className={styles.socialLink} href='https://www.instagram.com/boomwriter/' target="_blank" rel="noopener noreferrer"><img src={instagram} alt={'socialmedia icon'} /></a>
                                            <a className={styles.socialLink} href='https://www.youtube.com/c/Boomwriter' target="_blank" rel="noopener noreferrer"><img src={yt} alt={'socialmedia icon'} /></a>
                                        </div> */}
                                        <p className={styles.direction}>Cambridge, MA  02142<br />USA</p>

                                    </div>
                                    <div className={styles.linksContainer}>
                                        {/* <div className={styles.linkGroup}>
                                            <Link to='/tools' className={styles.title}> Teachers</Link>
                                            <Link to='/tools' > Tools</Link>
                                            <a href='https://static.boomwriter.com/home/frequently-asked-questions' > FAQs</a>
                                            <a href='https://static.boomwriter.com/resources/writing-tools-tutorials' > Tutorials</a>
                                        </div> 
                                        <div className={styles.linkGroup}>
                                            <Link to='/families' className={styles.title}> Families</Link>
                                            <Link to='/families' > Family app Details</Link>
                                            <Link to='/store' > Bookstore</Link>
                                        </div>  */}
                                        {/* <div className={styles.linkGroup}>
                                            <Link to='/tools' className={styles.title}> More</Link>
                                            <Link to='/tools/stories' > Stories</Link>
                                            <Link to='/tools/assignments' > Assignments</Link>
                                            <Link to='/tools/journals' > Journals</Link>
                                        </div> */}
                                        {/* <div className={styles.linkGroup}>
                                            <Link to='/schools' className={styles.title}> Schools</Link>

                                        </div> */}
                                        <div className={styles.linkGroup}>
                                            <Link to='/pricing' className={styles.title}> Pricing Page</Link>

                                        </div>
                                        <div className={styles.linkGroup}>
                                            <Link to='/about' className={styles.title}> Company</Link>
                                            <Link to='/about' > About Us</Link>
                                            {/* <a href='https://static.boomwriter.com/blog' > Blog</a>
                                            <a href='https://static.boomwriter.com/resources/misc' > Case Studies</a> */}
                                            <Link to='/home/privacy-policy' > Privacy Policy</Link>
                                            <Link to='/home/terms' > Terms</Link>
                                            <Link to='/home/cookie-policy' > Cookie Policy</Link>

                                        </div>
                                    </div>
                                </div>
                            :

                                <div className={styles.faithJournalsDiv}>
                                    <img src={poweredBy} alt="Powered by Boomwriter"/>
                                    <div className={styles.contactUs}>Contact Us</div>
                                </div>

                        }
                        <div className={styles.infoText}>
                            <p>Escribli - info@escribli.com  | Website and contents © 2021 All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </LazyLoad>
        );
    }

}

export default Footer;