import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFromApi } from '../../../../API/generalCalls';
import { removeChild } from '../../../../Redux/Actions';

import styles from './checkoutStyles.module.scss';

const FindChild = ({ activityType, setSummaryDetails, searchRef, bookCode, recalculate, setShowError }) => {

  const [ searchResults, setSearchResults ] = useState(null)
  const surnameRef = useRef()
  const summaryDetails = useSelector(state => state.storeData.summaryDetails)
  const dispatch = useDispatch()
  
  const handleQuery = (e) => {
    setShowError(false)
    const queryDb = async (str) => { setSearchResults(await getFromApi(`store/students_search?code=${bookCode}&q=${str}`)) }
    if(e.target.value.length>2){
      queryDb(e.target.value)
    }
  }

  const handleSelection = (e) => {
    const { studentname, studentid } = e.target.dataset
    const newItem = { studentName:studentname, studentId:studentid, quantity:1};
    setSummaryDetails({...summaryDetails, copies:summaryDetails.copies+1, items:[...summaryDetails.items, newItem]})
    setSearchResults(null)
    surnameRef.current.value=''

  }




  return (
    <div ref={searchRef} className={styles.findChild}>
      <h3>Find and purchase your child's writing</h3>
      {
        summaryDetails.type==='journal' || summaryDetails.type==='premium' ?

          activityType === 'story' ? <p>This book includes your child's name on the cover and an altarnate ending Final Chapter</p> :  <p>This journal includes your child's name on the cover</p>

        : null
      }

      <div className={styles.surnameInputDiv}>
        <input 
          placeholder={summaryDetails.items.length?'Search for another child':'Search for your child'}
          ref={surnameRef} 
          autoComplete={"off"} 
          onChange={(e)=>handleQuery(e)} 
          type="text" 
          name="surname" 
          id="surnameInput"/>
        {/* <div className={styles.surnameSearchBtn}>Search</div> */}
          { 
          
          searchResults &&
            <div className={styles.resultsDropdown}>
              {
                Array.isArray(searchResults)&&searchResults.length ? 
        
                  searchResults.map((i, idx) => { 
                    if(!summaryDetails.items.some(item => parseInt(item.studentId)===parseInt(i.studentId))){
                      
                      return <div 
                                onClick={(e)=>handleSelection(e)}
                                key={idx} 
                                data-key={"copyInput"}
                                data-studentid={i.studentId} 
                                data-studentname={i.studentName} 
                                className={styles.dropdownRow}> {i.studentName} </div> 
                    
                    } else { return null }
                  })
                    

                :

                  <div className={`${styles.dropdownRow} ${styles.disabled}`}> No matches found </div>

              }
            </div>
          }
      </div>



      {
        (summaryDetails.items && summaryDetails.items.length)  ?
            <>
              <h4>Personalized copies by:</h4>
                { 
                  summaryDetails.items.map((i, idx) => {

                    return  <div key={idx} className={styles.resultsRow}>

                              <p>{i.studentName}</p>
                                <input onChange={recalculate} type="number" min={0} data-key={"copyInput"} data-studentid={i.studentId} data-studentName={i.studentName} value={summaryDetails.items[idx].quantity}/>
                                <div onClick={()=>  dispatch(removeChild(i.studentId))} className={styles.deleteCircle} />
                            </div>
                  })
                }
            </>
      : null }

    </div>
  )

}

export default FindChild;