export const pricingReducer = (state = { paymentTerm: 'monthly', months: 12,plan:'complete', price:undefined}, action) => {
  switch (action.type) {

    case 'SET_PRICING_TERM':
      return { ...state, paymentTerm: action.payload }
    case 'SET_MONTHS':
      return { ...state, months: action.payload }
      case 'SET_PLAN':
      return { ...state, plan: action.payload }
      case 'SET_PRICE':
      return { ...state, price: action.payload }
    default:
      return state
  }
}