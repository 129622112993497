import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import loader from '../../../../Assets/Icons/Spinner.svg'
import spinner from '../../../../Assets/Icons/spinner-sml--fast.svg';

import styles from './checkoutStyles.module.scss';
import Steps from './Steps';
import OrderSummaryBlock from './OrderSummaryBlock';
import CheckoutStepOne from './CheckoutStepOne';
import CheckoutStepTwo from './CheckoutStepTwo';
import CheckoutStepThree from './CheckoutStepThree';

const Checkout = ({ code, bookDetails, setStage, setSummaryDetails }) => {

  const stage = useSelector(state => state.storeData.stage);
  const [ showLoader, setShowLoader ] = useState(false)
  const summaryDetails = useSelector(state => state.storeData.summaryDetails)
  const { type, copies, creditsRedeemed } = summaryDetails
  const isOpenClassOrder = bookDetails.openClassOrder

  useEffect(()=>{
    
    if(stage===1||stage===3){
      const newValue = (input) => { return type === 'standard' ? 15*parseInt(input) : 16*parseInt(input) }
      setSummaryDetails({...summaryDetails, address:summaryDetails.address, books:newValue(copies)-newValue(creditsRedeemed), creditDiscount:newValue(creditsRedeemed)})
  
    }

  },[copies, type, creditsRedeemed])

  

  if(!bookDetails.bookName) return <Redirect to='/store' />

  return (
    <div className={styles.container}>

      <Link to={'/store'} className={styles.back}>{"< Back to search"}</Link>

      <OrderSummaryBlock 
        summaryDetails={summaryDetails} 
        bookDetails={bookDetails} 
        isOpenClassOrder={isOpenClassOrder}
        activityType={bookDetails.type} />

      <div className={styles.mainArea}>

          
      { showLoader &&

          <div className={styles.loadingOverlay}>
            <img src={loader} alt="loader" />
            <p>Processing payment...</p>
          </div>

        } 
       {stage ? <Steps stage={stage} /> : null}

        {
          stage === 1 ?

              <CheckoutStepOne 
                summaryDetails={summaryDetails}
                setSummaryDetails={setSummaryDetails}
                bookDetails={bookDetails}
                isOpenClassOrder={isOpenClassOrder}
                activityType={bookDetails.type}
                setStage={setStage}/>

          :

            stage === 2 ? 

              <CheckoutStepTwo 
                setSummaryDetails={setSummaryDetails}
                isOpenClassOrder={isOpenClassOrder}
                summaryDetails={summaryDetails}
                setStage={setStage} />
              
         : 

         
          [3,4].includes(stage) ? 

              <CheckoutStepThree
                stage={stage}
                isOpenClassOrder={isOpenClassOrder}
                activityType={bookDetails.type}
                setShowLoader={setShowLoader}
                bookCode={bookDetails.bookCode}
                setSummaryDetails={setSummaryDetails}
                summaryDetails={summaryDetails}
                setStage={setStage} />


          : 
          
            
          <div className={styles.loadingOverlay}>
            <img className={styles.sml} src={spinner} alt="loader" />
            <p>Loading Checkout</p>
          </div>
        }


      </div>
    </div>
  )

}

export default Checkout;