import React, { useState } from 'react';
import styles from './Schools.module.scss'
import cycle from './../../Assets/HomePage/learning.png'
// import days from './../../Assets/School/days.png'
import girl from './../../Assets/School/girlWithLaptop.png'
import triangle from './../../Assets/School/triangle.png'
import repeat from './../../Assets/School/repeat.png'
import science from './../../Assets/Boomers/science_lab.png'
import stories from './../../Assets/Boomers/storybook-boomer.png'
import journal from './../../Assets/Boomers/journal-illustration.png'

import test from './../../Assets/School/test.jpg'
import red2 from './../../Assets/School/red2.png'
import red3 from './../../Assets/School/red3.png'
import red4 from './../../Assets/School/red4.png'
import red5 from './../../Assets/School/red5.png'
import red6 from './../../Assets/School/red6.png'
import y1 from './../../Assets/School/test2.png'
import y2 from './../../Assets/School/y2.png'
import y3 from './../../Assets/School/y3.png'
import y4 from './../../Assets/School/y4.png'
import y5 from './../../Assets/School/y5.png'
import y6 from './../../Assets/School/y6.png'
import green1 from '../../Assets/School/green1.png'
import green2 from '../../Assets/School/green2.png'
import green3 from '../../Assets/School/green3.png'
import green4 from '../../Assets/School/green4.png'
import green5 from '../../Assets/School/green5.png'
import green6 from '../../Assets/School/green6.png'

// import class1 from './../../Assets/School/class.png'
// import class2 from './../../Assets/School/schools.png'
// import example from './../../Assets/School/example.png'
// import example2 from './../../Assets/School/example2.png'

import lense from './../../Assets/School/lense.png'
import laptopBoomer from './../../Assets/School/laptopBoomer.png'
import Calendar from './../../Assets/School/Calendar.png'

import privacy from './../../Assets/School/privacy.png'

// import spps from './../../Assets/certifications/Student-Privacy-Pledge-Sig_logo.png'
import google from './../../Assets/certifications/Google-for-Education-Partner-badge-TRANSPARENT-1.png'

import FeatureSection from './../HomePage/Sections/Features'
import { useWindowSize } from './../../Hooks/UseWindowSize'

import Bullet from './Bullet';
import { Helmet } from 'react-helmet'
// import Tools from '../HomePage/Sections/Tools';

//SEO
import ReactGA from 'react-ga';

const handleGA = () => {
    ReactGA.event({category: 'Schools',action: 'Click on hero btn "Request More Information" '})
}

const Schools = () => {
    const [term, setTerm] = useState('schools');
    const size = useWindowSize();
    // const contactForm = useRef(null)
    // const scrollToBottom = () => {
    //     contactForm.current.scrollIntoView({ behavior: "smooth" });
    // }

    return (
        <section>

            <Helmet>
                <title>Online Resource for Schools | Escribli Helps Schools</title>
                <meta name="description" content="Escribli keeps your teachers and students engaged and writing every day from anywhere! Contact us to see if Escribli is right for your school. " />
                <meta name="keywords" content="educational resources online, technology in classrooms, online school resources, resources for schools, educational resources online" />
            </Helmet>

            {/* @todo update_contact_mail */}
            <article className={styles.hero}>
                <div className={styles.heroText}>
                    <h1> Supporting teachers, engaging families,<br /> & improving school performance! </h1>
                    <h2> A proven unique approach to conduct year-round writing activities in any subject (Grades 2-8) </h2>
                    {/* <button onClick={() => scrollToBottom()}>Contact us</button> */}
                    <a href='mailto:teamplan@boomwriter.com' onClick={()=>handleGA()}>Contact us</a>
                    <div className={styles.screensDecoration}></div>
                </div>
            </article>

            <article className={` ${styles.consistency}  ${styles.lightbluegreyBg}`}>
                <div className={styles.teamTeaching}>
                    <div>
                        <h2>The perfect team teaching solution</h2>
                        <p>All your team needs is in one place to <b>plan, organize </b>and <b>implement activities</b></p>
                    </div>
                    <div className={styles.teamTeachingCardsContainer}>
                        <div className={styles.teamTeachingCard}>
                            <img src={Calendar} alt='card decoration' />
                            <h4>Team interaction and planning</h4>
                            <ul>
                                <li><b>Collaborate</b> and <b>communicate</b> with our <b>in-app chat</b>  </li>
                                <li>Focus and <b>strategize</b>  on the skills needed for all <b> students’ improvement</b></li>
                                <li> Maximize <b>planning</b>  time with the <b>team-view calendar</b> </li>
                            </ul>
                        </div>
                        <div className={styles.teamTeachingCard}>
                            <img src={laptopBoomer} alt='card decoration' />
                            <h4>Team writing activities</h4>
                            <ul>
                                <li>Ability to <b>customize activities</b>  to personalized teaching style  </li>
                                <li>Save activities into a team <b>database/lesson bank</b>  </li>
                                <li> Conduct <b>cross-curricular</b>  individual or group writing activities</li>
                            </ul>

                        </div>
                        <div className={styles.teamTeachingCard}>
                            <img className={styles.rotate} src={lense} alt='card decoration' />
                            <h4>Tracking and data</h4>
                            <ul>
                                <li> <b>Monitor</b>  students’ progress and performance <b>in real time</b>  </li>
                                <li> <b>Evaluate data & reports </b> to develop student <b>action plans</b>  </li>
                                <li>  Assess <b>student growth</b>  with our detailed <b>grammar reports</b>  </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={`${styles.container} ${styles.cycle}`}>

                    <div className={styles.half}>
                        <div className={styles.imgWrapper}>
                            <img src={cycle} alt='' />
                        </div>

                    </div>
                    <div className={styles.text}>
                        <h3>Consistency is key to improvement</h3>
                        <p>Escribli personalized student writing improvement model will have students learning to write and writing to learn each and every day!</p>
                        {/* @todo update_contact_mail */}
                        <a href='mailto:teamplan@boomwriter.com'>Contact us</a>
                    </div>
                </div>
            </article>
            <article className={` ${styles.whiteBg} ${styles.consistency} `}>
                <div className={`${styles.container} ${styles.cycle}`}>

                    <div className={styles.text}>
                        <h3>Seamless integration with existing curriculum</h3>
                        <p>In minutes, teachers can create and share standalone or integrated writing activities within the classroom, remotely or as an integral part of any hybrid teaching and learning model.</p>
                        {/* <button onClick={() => scrollToBottom()}>Contact Us</button> */}
                        <a href='mailto:teamplan@boomwriter.com'>Contact us</a>
                    </div>
                    <div className={styles.half}>
                        <div className={styles.imgWrapper}>
                            <img src={girl} alt='' />
                        </div>
                    </div>
                </div>
            </article>
            <article className={` ${styles.blueBg} ${styles.consistency} `}>
                <div className={` ${styles.blueContainer}`}>

                    <div className={`${styles.container} ${styles.cycle}`}>

                        <div className={`${styles.text}`}>
                            <h3 className={styles.textWhite}>Supporting All School Stakeholders</h3>
                            <p className={styles.textWhite}>Escribli Learning addresses an abundance of needs for all school community stakeholders.</p>
                            {/* <button onClick={() => scrollToBottom()}>Contact Us</button> */}
                            <a className={styles.orange} href='mailto:teamplan@boomwriter.com'>Contact sales team</a>
                        </div>
                        <div className={styles.half}>

                            <div className={styles.imgWrapper}>
                                <img src={triangle} alt='' />
                            </div>
                        </div>
                    </div>

                    <div className={styles.bubbles}>
                        <div className={term === 'schools' ? `${styles.bubble} ${styles.schools} ${styles.active}` : `${styles.bubble} ${styles.schools}`} onClick={() => term !== 'schools' ? setTerm('schools') : setTerm('close')}></div>
                        <div className={term === 'students' ? `${styles.bubble} ${styles.students} ${styles.active}` : `${styles.bubble} ${styles.students}`} onClick={() => setTerm('students')}></div>
                        <div className={term === 'families' ? `${styles.bubble} ${styles.families} ${styles.active}` : `${styles.bubble} ${styles.families}`} onClick={() => setTerm('families')}></div>
                    </div>

                    <div className={styles.bubblesMobile}>
                        <div className={term === 'schools' ? `${styles.bubbleMobile} ${styles.schools} ${styles.active}` : `${styles.bubbleMobile} ${styles.schools}`} onClick={() => term !== 'schools' ? setTerm('schools') : setTerm('close')}></div>
                        <div className={term === 'schools' ? `${styles.bulletDisplay} ${styles.container} ${styles.responsive} ${styles.active}` : `${styles.bulletDisplay} ${styles.container} ${styles.responsive}`}>
                            <>
                                <Bullet icon={test} text={' Establish a consistent interdisciplinary writing platform with individualized teacher-student communication features for in-class and distance learning'} />
                                <Bullet icon={red2} text={' Develop new school-to-home communication channels for increased and improved levels of family engagement and support'} />
                                <Bullet icon={red3} text={' Easily create, share, and oversee customized academic and social-emotional writing activities for individual students or groups of any size and ability'} />
                                <Bullet icon={red4} text={' Facilitate collaboration amongst co-teachers and specialists for improved delivery and shared support of student writing activities'} />
                                <Bullet icon={red5} text={' Benefit using a scalable centralized location for managing all student and teacher accounts'} />
                                <Bullet icon={red6} text={'Gain real-time insights of school-based writing activity at the school, cohort and individual student level '} />
                            </>
                        </div>
                        <div className={term === 'students' ? `${styles.bubbleMobile} ${styles.students} ${styles.active}` : `${styles.bubbleMobile} ${styles.students}`} onClick={() => term !== 'students' ? setTerm('students') : setTerm('close')}></div>
                        <div className={term === 'students' ? `${styles.bulletDisplay} ${styles.container} ${styles.active} ${styles.responsive}` : `${styles.bulletDisplay} ${styles.container} ${styles.responsive}`}>

                            <>
                                <Bullet icon={green1} text={' Easily access, manage, and complete writing activities in one convenient place. '} />
                                <Bullet icon={green2} text={'  Practice self-monitoring skills by evaluating their own progress via easily accessible student dashboard features'} />
                                <Bullet icon={green3} text={'  Fully engage themselves in the writing process while improving skills and increasing their knowledge of teacher-guided vocabulary'} />
                                <Bullet icon={green4} text={' Experience increased motivation and fulfillment via engaging gamification elements '} />
                                <Bullet icon={green5} text={' Consistently receive impactful personalized feedback from teachers and opportunities to revise and edit work in order to reach their full writing potential '} />
                                <Bullet icon={green6} text={' Develop content assessment skills and receive additional perspectives through teacher-guided peer-to-peer feedback '} />

                            </>
                        </div>
                        <div className={term === 'families' ? `${styles.bubbleMobile} ${styles.families} ${styles.active}` : `${styles.bubbleMobile} ${styles.families}`} onClick={() => term !== 'families' ? setTerm('families') : setTerm('close')}></div>
                        <div className={term === 'families' ? `${styles.bulletDisplay} ${styles.container} ${styles.active} ${styles.responsive}` : `${styles.bulletDisplay} ${styles.container} ${styles.responsive}`}>
                            <>
                                <Bullet icon={y1} text={' Gain a valuable new home-school communication channel'} />
                                <Bullet icon={y2} text={' Experience reduced pressure due to the asynchronous nature of most writing activities'} />
                                <Bullet icon={y3} text={' Receive insight into their child’s day-to-day writing activity'} />
                                <Bullet icon={y4} text={' Can access to their child’s self-selected and teacher-selected student portfolio work'} />
                                <Bullet icon={y5} text={' Easily monitor their child’s writing assignment due dates via their BW parent dashboard'} />
                                <Bullet icon={y6} text={' Have the ability to provide additional writing opportunities via home-based Escribli tools and features'} />

                            </>
                        </div>
                    </div>

                    <div className={` ${styles.bulletDisplay} ${styles.container} ${styles.desktop}`}>
                        {term === 'schools' ? (
                            <>
                                <Bullet icon={test} text={' Establish a consistent interdisciplinary writing platform with individualized teacher-student communication features for in-class and distance learning'} />
                                <Bullet icon={red2} text={' Develop new school-to-home communication channels for increased and improved levels of family engagement and support'} />
                                <Bullet icon={red3} text={' Easily create, share, and oversee customized academic and social-emotional writing activities for individual students or groups of any size and ability'} />
                                <Bullet icon={red4} text={' Facilitate collaboration amongst co-teachers and specialists for improved delivery and shared support of student writing activities'} />
                                <Bullet icon={red5} text={' Benefit using a scalable centralized location for managing all student and teacher accounts'} />
                                <Bullet icon={red6} text={'Gain real-time insights of school-based writing activity at the school, cohort and individual student level '} />
                            </>
                        ) : (
                                term === 'students' ? (

                                    <>
                                        <Bullet icon={green1} text={' Easily access, manage, and complete writing activities in one convenient place. '} />
                                        <Bullet icon={green2} text={'  Practice self-monitoring skills by evaluating their own progress via easily accessible student dashboard features'} />
                                        <Bullet icon={green3} text={'  Fully engage themselves in the writing process while improving skills and increasing their knowledge of teacher-guided vocabulary'} />
                                        <Bullet icon={green4} text={' Experience increased motivation and fulfillment via engaging gamification elements '} />
                                        <Bullet icon={green5} text={' Consistently receive impactful personalized feedback from teachers and opportunities to revise and edit work in order to reach their full writing potential '} />
                                        <Bullet icon={green6} text={' Develop content assessment skills and receive additional perspectives through teacher-guided peer-to-peer feedback '} />

                                    </>
                                ) : (
                                        <>
                                            <Bullet icon={y1} text={' Gain a valuable new home-school communication channel'} />
                                            <Bullet icon={y2} text={' Experience reduced pressure due to the asynchronous nature of most writing activities'} />
                                            <Bullet icon={y3} text={' Receive insight into their child’s day-to-day writing activity'} />
                                            <Bullet icon={y4} text={' Can access to their child’s self-selected and teacher-selected student portfolio work'} />
                                            <Bullet icon={y5} text={' Easily monitor their child’s writing assignment due dates via their BW parent dashboard'} />
                                            <Bullet icon={y6} text={' Have the ability to provide additional writing opportunities via home-based Escribli tools and features'} />

                                        </>
                                    )

                            )
                        }
                    </div>

                </div>
            </article>
            <article className={`${styles.consistency} ${styles.whiteBg}`}>
                <div className={`${styles.container} ${styles.ourTools}`}>

                    <div className={`${styles.toolsHeader} ${styles.cycle}`}>
                        <div className={styles.imgWrapper}>
                            <img src={repeat} alt='' />
                        </div>
                        <div className={`${styles.text} ${styles.toolText}`}>
                            <h3>Our Teacher's Toolbox</h3>
                            <p>Escribli's three customizable tools provide endless writing possibilities each and every day while adhering to our consistent student writing improvement model:</p>
                        </div>
                    </div>
                        
                        {/* <Tools/> */}
                    <div className={`${styles.cardContainer} ${styles.cycle}`}>

                        <div className={`${styles.tool} ${styles.assignments}`}>
                            <div className={styles.imgWrapper}>
                                <img src={science} alt='card decoration' />
                            </div>
                            <h4>Assignments</h4>
                            <p>From crafting arguments and composing informational pieces to developing poetry techniques or explaining mathematical thinking, our Assignments tool facilitates the creation of a limitless number of writing activities in any genre or subject.</p>
                        </div>


                        <div className={`${styles.tool} ${styles.journals}`}>
                            <div className={styles.imgWrapper}>
                                <img className={styles.adjust} src={journal} alt='card decoration' />
                            </div>
                            <h4>Journals</h4>
                            <p>Our digital Journals provide students an invaluable and easily accessible place to write consistently. Be it reading responses, SEL, or teacher-selected daily/weekly prompts, journals help students build confidence and fluency in writing. </p>
                        </div>


                        <div className={`${styles.tool} ${styles.stories}`}>
                            <div className={styles.imgWrapper}>
                                <img src={stories} alt='card decoration' />
                            </div>
                            <h4>Stories</h4>
                            <p>Students love writing stories, and our Stories tool lets teachers conduct interactive group or individual story writing activities that improve students’ narrative writing abilities and understanding of key fictional elements.</p>
                        </div>

                    </div>
                </div>
            </article>
            <article className={styles.lightbluegreyBg}>

                <div className={styles.featureContainer}>
                    {size.width >= 750 ? <FeatureSection /> : <FeatureSection mobile />}

                </div>
            </article>


            <article className={`${styles.consistency}`}>
                <div className={`${styles.container} ${styles.proDev} ${styles.text}`}>
                    <h3>Professional Development & <br />Ongoing Support</h3>
                    <p>Escribli provides school, cluster, and team level web-based professional development for teachers to ensure high degrees of confidence and proficiency using our tools that is then supported on an ongoing basis via online chat, email and phone access.</p>
                    <p>School administrators are invited to participate in all teacher trainings but also receive administrator-specific Escribli professional development.</p>
                    <p>Parents and families within your school community will also have access to online and downloadable training and support to optimize their Escribli experience.</p>
                    {/* @todo update_contact_mail */}
                    <a className={styles.orange} href='mailto:teamplan@boomwriter.com'>Contact us to learn more</a>
                </div>
            </article>
            <article className={` ${styles.consistency} `}>
                <div className={`${styles.container} ${styles.priSafSec}`}>
                    <div className={`${styles.textContainer} ${styles.cycle}`}>
                        <div className={`${styles.text}`}>
                            <h3>Privacy, Safety, and Security</h3>
                            <p>At Escribli, protecting the privacy of the school communities we partner with is of the utmost importance and why we will always maintain compliance and operate with transparency. We promise to deliver impactful teaching and learning opportunities while adhering to the strictest and best privacy practices to earn and maintain trust and confidence.</p>
                            
                            {/* @todo update_kidsafe_data */}
                            <a className={styles.orange} href='mailto:teamplan@boomwriter.com'>Contact us to learn more</a>
                            <div className={`${styles.logoContainer} ${styles.cycle}`}>
                                <a href="https://www.kidsafeseal.com/certifiedproducts/boomwriter.html" target="_blank" rel="noopener noreferrer">
                                    <img border="0" alt="Boomwriter.com is certified by the kidSAFE Seal Program." src="https://www.kidsafeseal.com/sealimage/124387489510662211/boomwriter_large_darktm.png" />
                                </a>
                                <img className={styles.logo} src={google} alt='' />
                                {/* <img className={styles.logo} src={spps} alt='' /> */}
                            </div>
                        </div>
                        <div className={styles.imgWrapper}>
                            <img src={privacy} alt='' />
                            <p>Escribli is an award-winning writing app with a commitment to maintaining the highest levels of compliance (GDPR, COPPA, FERPA) involving school and student privacy and safety.</p>
                        </div>
                    </div>

                </div>
            </article>


        </section>
    );
}

export default Schools;


